import React, { useState, useContext } from 'react'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { cancelWithdrawals } from '../../../adapters/payments'
import { AuthContext } from '../../../context/authProvider'
import { Button } from '../../atoms/button'
import { FormatAmount } from '../../atoms/formatAmount'
import { Grid } from '../../atoms/grid'
import { If } from '../../atoms/if'
import { ResponsiveContainer } from '../../atoms/responsiveContainer'
import { toast } from '../../atoms/toast'
import { useTranslation } from '../../../context/translationProvider'
import { formatCurrency } from '../../../utils/currencyFormatting'
import {
  ButtonsAndGridOuterWrapper,
  ButtonsWrapper,
  Message,
  MobileDetails,
  ProceedToDeposit,
  Wrapper,
} from './styles'

import { themeStyles, themeSizes } from '../../../styles/theme'

dayjs.extend(utc)

export const PendingWithdrawal = ({
  user,
  data,
  totals,
  depositClose,
  proceedToDeposit,
}) => {
  const { translate } = useTranslation()
  const { getUserWallet } = useContext(AuthContext)

  const [step, setStep] = useState(0)

  const columns = [
    {
      columnName: 'time',
      displayName: translate('account.transactions.date'),
      size: 3,
    },
    {
      columnName: 'name',
      displayName: translate('account.transactions.method'),
      size: 3,
    },
    {
      columnName: 'amount',
      displayName: translate('account.transactions.amount'),
      size: 3,
    },
  ]

  const onCancelWithdrawal = () => {
    setStep(1)

    if (step === 1) {
      cancelWithdrawals(data.map((row) => parseInt(row.id)))
        .then((data) => {
          getUserWallet()
          depositClose()

          if (!data?.ok) {
            return toast.error(translate('common.unexpectedError'))
          }

          toast.success(translate('account.transactions.transactionCancelled'))
        })
        .catch((_) => toast.error(translate('common.unexpectedError')))
    }
  }

  const formatTransactionsData = (data) => {
    if (!data) {
      return []
    }

    const formattedData = []

    data.forEach((row) => {
      const gridRow = {}

      gridRow['transactionID'] = { value: row.id }
      gridRow['time'] = {
        value: dayjs.utc(row.created_at).format('DD.MM.YYYY HH:mm:ss'),
      }
      gridRow['name'] = {
        value: row.payment_method ? row.payment_method.name : '-',
      }

      gridRow['amount'] = {
        value: (
          <FormatAmount
            amount={row.amount}
            shortCode={user.wallet.currency.short_code}
            currencySymbol={user?.wallet?.currency?.symbol}
          />
        ),
      }

      let color = ''
      const { status } = row
      switch (status.toLowerCase()) {
        case 'canceled_by_user':
          color = themeStyles.colors.dominantGrey.main
          break
        case 'created':
        case 'pending':
          color = themeStyles.colors.info.main
          break
        case 'declined':
        case 'not_ok':
        case 'declined_on_platform':
          color = themeStyles.colors.error.main
          break
        case 'ok':
        case 'accepted_on_platform':
        default:
          color = themeStyles.colors.accentColors.primary.main
          break
      }

      gridRow['status'] = {
        value: translate(`account.transactions.types.${status}`),
        color,
      }

      formattedData.push(gridRow)
    })

    return formattedData.sort((a, b) => {
      if (b.transactionID.value < a.transactionID.value) {
        return -1
      }
      if (b.transactionID.value > a.transactionID.value) {
        return 1
      }
      return 0
    })
  }

  const createMobileGrid = () => {
    const formatedData = formatTransactionsData(data)

    return formatedData.map((row, i) => (
      <MobileDetails key={i}>
        {columns.map((column, i) => (
          <div key={i}>
            <span>{column.displayName}</span>
            <span>{row[column.columnName].value}</span>
          </div>
        ))}
      </MobileDetails>
    ))
  }

  return (
    <Wrapper>
      <If
        condition={step === 0}
        render={() => (
          <>
            <Message
              className="mobile-align-left"
              dangerouslySetInnerHTML={{
                __html: translate('cancelWithdraw.totalPendingAmount')?.replace(
                  '{0}',
                  formatCurrency(totals?.total_withdrawal_amount, {
                    shortCode: user?.wallet?.currency?.short_code,
                    currencySymbol: user?.wallet?.currency?.symbol,
                  })
                ),
              }}
            />
            <Message
              className="mobile-align-left"
              dangerouslySetInnerHTML={{
                __html: translate('cancelWithdraw.doYouWantToCancelWithdraw'),
              }}
            />
            <ButtonsAndGridOuterWrapper>
              <ResponsiveContainer
                sizeNumber={themeSizes.tablet}
                render={createMobileGrid}
                renderElse={() => (
                  <Grid columns={columns} data={formatTransactionsData(data)} />
                )}
              />
              <ButtonsWrapper>
                <Button
                  expand
                  bordered
                  dark
                  onClick={onCancelWithdrawal}
                  id="btnCancelWithdrawal"
                >
                  {translate('cancelWithdraw.cancelWithdraw')}
                </Button>
                <Button expand onClick={proceedToDeposit} id="btnGotoDeposit">
                  {translate('cancelWithdraw.continueToDeposit')}
                </Button>
              </ButtonsWrapper>
            </ButtonsAndGridOuterWrapper>
          </>
        )}
      />

      <If
        condition={step === 1}
        render={() => (
          <>
            <Message
              dangerouslySetInnerHTML={{
                __html: translate('cancelWithdraw.confirmToCancelWithdrawals'),
              }}
            />

            <ButtonsWrapper className="with-spacing">
              <Button onClick={onCancelWithdrawal}>
                {translate('cancelWithdraw.confirm')}
              </Button>
            </ButtonsWrapper>

            <ProceedToDeposit
              onClick={proceedToDeposit}
              dangerouslySetInnerHTML={{
                __html: translate('cancelWithdraw.noProceedToDeposit'),
              }}
            />
          </>
        )}
      />
    </Wrapper>
  )
}
