import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledDescription } from './styles'

const FieldDescription = (props) => {
  const { children, error, theme } = props

  if (!children && !error) return <></>
  return (
    <StyledDescription error={!!error} theme={theme}>
      {error || children}
    </StyledDescription>
  )
}

FieldDescription.defaultProps = { error: null, children: null, theme: null }
FieldDescription.propTypes = {
  error: PropTypes.string,
  children: PropTypes.any,
  theme: PropTypes.string,
}

export { FieldDescription }
