import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

const BaseStyle = css`
  line-height: 1em;
  margin: 0;

  ${(props) =>
    props.white &&
    css`
      color: white;
    `}
`

export const CssH1 = css`
  ${BaseStyle}
  font-weight: ${brandConfig.fonts.text.h1.weight};
  font-style: ${brandConfig.fonts.text.h1.style};
  font-size: ${brandConfig.fonts.text.h1.size.mobile};
  font-family: ${brandConfig.fonts.text.h1.family};
  text-transform: uppercase;

  ${(props) =>
    !props.keepMobileSize &&
    themeMedia.desktop` 
    font-size: ${brandConfig.fonts.text.h1.size.desktop};
  `}
`

export const CssH2 = css`
  ${BaseStyle}
  font-weight: ${brandConfig.fonts.text.h2.weight};
  font-size: ${brandConfig.fonts.text.h2.size.mobile};
  font-family: ${brandConfig.fonts.text.h2.family};

  ${(props) =>
    !props.keepMobileSize &&
    themeMedia.desktop`
    font-size: ${brandConfig.fonts.text.h2.size.desktop};
  `}
`

export const CssH3 = css`
  ${BaseStyle}
  font-weight: ${brandConfig.fonts.text.h3.weight};
  font-size: ${brandConfig.fonts.text.h3.size.mobile};
  font-family: ${brandConfig.fonts.text.h3.family};
`

export const CssParagraph = css`
  ${BaseStyle}
  font-weight: ${brandConfig.fonts.text.paragraph.weight};
  font-size: ${brandConfig.fonts.text.paragraph.size.mobile};
  font-family: ${brandConfig.fonts.text.paragraph.family};
  line-height: 1.45em;
  margin-top: 0;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.big &&
    css`
      font-size: 1.2em;
      margin-bottom: 1rem;
    `}

  ${(props) =>
    props.extraBig &&
    css`
      font-size: 1.7em;
      margin-bottom: 1rem;

      ${themeMedia.desktop`
        font-size: 2.3em;
      `}
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 11px;
      margin-bottom: 0.2rem;
    `}

  ${(props) =>
    props.smaller &&
    css`
      font-size: 0.75em;
      margin-bottom: 0.2rem;
    `}

  ${({ theme }) =>
    theme === 'light' &&
    css`
      color: ${themeStyles.colors.lightGreys[4].main};
    `}

  ${({ theme }) =>
    theme === 'success' &&
    css`
      color: ${themeStyles.colors.accentColors.primary.main};
    `}

  ${({ theme }) =>
    theme === 'info' &&
    css`
      color: ${themeStyles.colors.accentColors.secondary.main};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ black }) =>
    black &&
    css`
      font-weight: 900;
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0 !important;
      margin-bottom: 0 !important;
    `}

  ${({ verticalPadding }) =>
    verticalPadding &&
    css`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ truncate, truncateWidth }) =>
    truncate &&
    truncateWidth &&
    css`
      max-width: ${truncateWidth} !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

const getCssByLevel = (props) => {
  switch (props.level) {
    case 1:
      return CssH1
    default:
    case 2:
      return CssH2
    case 3:
      return CssH3
  }
}

export const StyledH1 = styled.h1`
  ${getCssByLevel}
`

export const StyledH2 = styled.h2`
  ${getCssByLevel}
`

export const StyledH3 = styled.h3`
  ${getCssByLevel}
`

export const StyledSpan = styled.span`
  ${getCssByLevel}
`

export const StyledParagraph = styled.p`
  ${CssParagraph}
`
