import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const Container = styled.div`
  aspect-ratio: 327 / 189;
  background: ${themeStyles.colors.lightGreys[1].main};
  color: ${themeStyles.colors.black.main};
  padding: 1em;
  min-width: 300px;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: cover;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: 100%;
      aspect-ratio: 390 / 107;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}


  ${themeMedia.tablet`
    min-width: 350px;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;

  ${({ iconHeader }) =>
    iconHeader &&
    css`
      margin-bottom: 1.5em;
    `}
`

export const Content = styled.div`
  > p:first-child {
    margin-bottom: 0.5em;
  }
`

export const StyledTimerContainer = styled.div`
  position: absolute;
  bottom: 0.5em;

  ${themeMedia.desktop`
    bottom: 0;
  `}

  ${({ $paddingBottom }) =>
    $paddingBottom &&
    css`
      padding-bottom: 0.5em;
    `}
`
