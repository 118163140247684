import React from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from '../fieldWrapper'
import { StyledSelect } from './styles'
import { If } from '../../atoms/if'

const FieldDropdown = (props) => {
  const {
    label,
    emptyValue,
    defaultValue,
    fieldValue,
    description,
    disabled,
    showErrorMsg,
    autoCompleteOff,
    errors,
    formProperties,
    hasBeenSubmitted,
    onChange,
    onClick,
    renderError,
    values,
    removeFirstOption,
    disableFirstOption,
    noBottomMargin,
    onBlur,
    onFocus,
  } = props

  function handleAutoComplete() {
    if (autoCompleteOff == null) return null
    return autoCompleteOff ? 'off' : 'on'
  }

  if (!formProperties) return <></>
  const autoComplete = handleAutoComplete()

  return (
    <FieldWrapper
      label={label}
      fieldValue={fieldValue}
      description={description}
      showErrorMsg={showErrorMsg}
      errors={errors}
      formProperties={formProperties}
      hasBeenSubmitted={hasBeenSubmitted}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      renderError={renderError}
      key={`select-${formProperties.name}`}
      noBottomMargin={noBottomMargin}
      renderInputField={({
        onChangeCombined,
        onBlurCombined,
        onKeyDownCombined,
        onFocusCombined,
        invalid,
        valid,
        data,
      }) => (
        <StyledSelect
          {...formProperties}
          id={formProperties.name}
          autoComplete={autoComplete}
          defaultValue={data == null ? defaultValue : undefined}
          disabled={disabled}
          onBlur={onBlurCombined}
          onFocus={onFocusCombined}
          onKeyDown={onKeyDownCombined}
          onChange={(e) => {
            onChangeCombined(e)
            formProperties.onChange(e)
          }}
          onClick={onClick}
          valid={valid && data != ''}
          invalid={invalid}
          isValuePicked={data != ''}
          value={data}
          disableFirstOption={disableFirstOption}
        >
          <If
            condition={!removeFirstOption}
            render={() => (
              <option disabled={disableFirstOption} selected value="">
                {defaultValue ? '' : emptyValue}
              </option>
            )}
          />
          {values}
        </StyledSelect>
      )}
    />
  )
}

FieldDropdown.defaultProps = {
  label: null,
  emptyValue: '--',
  defaultValue: '--',
  description: null,
  disabled: null,
  showErrorMsg: null,
  // NOTE: unlike with most components, for dropdowns
  //"hasBeenSubmitted" is on by default
  hasBeenSubmitted: true,
  autoCompleteOff: null,
  removeFirstOption: false,
  disableFirstOption: false,
  noBottomMargin: false,
  errors: {},
  formProperties: {},
  onChange: () => {},
  onClick: () => {},
  renderError: null,
  values: <></>,
  onBlur: () => {},
  onFocus: () => {},
}

FieldDropdown.propTypes = {
  label: PropTypes.string,
  emptyValue: PropTypes.string,
  defaultValue: PropTypes.string,
  description: PropTypes.string,
  fieldValue: PropTypes.string,
  disabled: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  hasBeenSubmitted: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
  removeFirstOption: PropTypes.bool,
  disableFirstOption: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  errors: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  renderError: PropTypes.func,
  values: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export { FieldDropdown }
