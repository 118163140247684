export const CPF_MASK = '999.999.999-99'

export const INITIAL_MOBILE_MASK = '99999999999999999999999999999999'
export const BR_MOBILE_MASK = '(99) 99999-9999'
export const BR_PHONE_MASK = '(99) 9999-9999'
export const BR_RESET_MASK = '(99) 9999-99999'
export const CEP_MASK = '99999-999'

export const cpfMask = (value) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const cepMask = (value) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export const removeNationalIdMask = (value) => {
  return removeCpfMask(value)
}

export const removeCpfMask = (value) => {
  return value?.replaceAll('.', '')?.replaceAll('-', '')?.replaceAll('/', '')
}

export const removePhoneMask = (value) => {
  return value
    ?.slice(0, 32)
    ?.replaceAll('(', '')
    ?.replaceAll(')', '')
    ?.replaceAll(' ', '')
    ?.replaceAll('-', '')
}

export const removeCepMask = (value) => {
  return value?.replaceAll('-', '')
}
