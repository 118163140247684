import { coreApi } from './base'

export const getCountries = async () => {
  try {
    const { data } = await coreApi.get('/country')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getCurrencies = async () => {
  try {
    const { data } = await coreApi.get('/currency')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getLanguages = async () => {
  try {
    const { data } = await coreApi.get('/language')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getRegisterCountries = async () => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/country/register')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getBrazilianBanks = async () => {
  try {
    const { data } = await coreApi.get('/banks')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getRegistrationDropdownCountries = async () => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/country/registration-dropdown')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}

export const getCountryRegions = async ({ countryId }) => {
  try {
    const { data } = await coreApi.get(`/country/${countryId}/regions`)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response?.data }
  }
}
