import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import {
  getRegisterCountries,
  getRegistrationDropdownCountries,
} from '../adapters/common'
import { captureException } from '@sentry/browser'

const TranslationContext = createContext()

const loadJson = () => {
  return new Promise((res) => {
    import('../intl/lang.json').then((data) => {
      res(data?.default)
    })
  })
}

export const TranslationProvider = (props) => {
  const [lang, setLang] = useState()
  const [Countries, setCountries] = useState({})
  const [countriesArray, setCountriesArray] = useState([])

  const setRegisterCountries = async () => {
    const { ok, data } = await getRegisterCountries()
    if (ok) {
      const countriesObj = {}
      data?.forEach(({ id, name }) => {
        countriesObj[name?.toUpperCase()] = id
      })
      setCountries(countriesObj)
    }
  }

  const setRegisterDropdownCountries = async () => {
    const { ok, data } = await getRegistrationDropdownCountries()
    if (ok) {
      setCountriesArray(data)
    }
  }

  const formatKey = (key) => {
    return key?.replaceAll('.', '/')
  }

  const translate = (key, defaultValue) => {
    if (!lang || !key) return

    const translation = key.split('.').reduce((previousKey, currentKey) => {
      if (previousKey?.[currentKey]) return previousKey[currentKey]

      captureException(
        new Error(
          `Missing key: ${formatKey(key)} for locale: ${
            process.env.GATSBY_INTL_LOCALE
          }`
        )
      )
      return defaultValue ? defaultValue : formatKey(key)
    }, lang)

    return translation
  }

  useEffect(() => {
    const loadTranslations = async () => {
      const messages = await loadJson()
      setLang(messages)
    }
    setRegisterCountries()
    setRegisterDropdownCountries()
    loadTranslations()
  }, [])

  const value = useMemo(
    () => ({ lang, translate, Countries, countriesArray }),
    [lang, translate, Countries, countriesArray]
  )

  return <TranslationContext.Provider value={value} {...props} />
}

export const useTranslation = () => {
  const context = useContext(TranslationContext)

  if (!context) return {}

  const { translate, Countries, countriesArray, lang } = context

  return { translate, Countries, countriesArray, lang }
}
