import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledUspsBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7em;
  min-height: 70px;
  max-height: 70px;
  padding: 0 19px;
  border-radius: 8px;
  background-color: ${themeStyles.colors.accentColors.secondary.main};

  ${({ hasAction }) =>
    hasAction &&
    css`
      cursor: pointer;
    `}

  ${({ isReducedSize }) =>
    isReducedSize &&
    css`
      gap: 0.8em;
    `}

  ${themeMedia.maxMobile`
    min-height: 55px;
    max-height: 55px;
    padding: 0 11px;
    gap: .6em;
  `}
`
