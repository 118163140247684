import React from 'react'
import { useTranslation } from '../../../context/translationProvider'
import {
  StyledCell,
  StyledGridWrapper,
  StyledHeader,
  StyledNoData,
  StyledRow,
} from './styles'

const Grid = (props) => {
  const { translate } = useTranslation()
  return (
    <StyledGridWrapper>
      <StyledHeader>
        <StyledRow>
          {props?.columns?.map((column, index) => {
            return (
              <StyledCell key={index} size={column.size}>
                {column.displayName}
              </StyledCell>
            )
          })}
        </StyledRow>
      </StyledHeader>
      {props?.data?.length > 0 && (
        <>
          {props?.data?.map((row, index) => {
            return (
              <StyledRow key={`row-${index}`}>
                {props?.columns?.map((column, columnIndex) => {
                  const cell = row[column.columnName]
                  if (cell && typeof cell.value === 'string') {
                    return (
                      <StyledCell
                        key={columnIndex}
                        size={column.size}
                        color={cell?.color}
                        dangerouslySetInnerHTML={{
                          __html: cell.value,
                        }}
                      />
                    )
                  } else {
                    return (
                      <StyledCell
                        key={columnIndex}
                        size={column.size}
                        color={cell?.color}
                      >
                        {cell?.value}
                      </StyledCell>
                    )
                  }
                })}
              </StyledRow>
            )
          })}
        </>
      )}
      {props?.data?.length === 0 && (
        <StyledNoData>
          <span>{translate('grid.noDataAvailable')}</span>
        </StyledNoData>
      )}
    </StyledGridWrapper>
  )
}

export { Grid }
