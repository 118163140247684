import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledCircleFallback,
  StyledRectangleFallback,
  StyledH1Fallback,
  StyledH2Fallback,
  StyledH3Fallback,
  StyledParagraphFallback,
  StyledGameTileFallback,
} from './styles'

const AnimatedTextFallback = (props) => {
  const { block, theme } = props
  switch (props.level) {
    case 4:
      return <StyledParagraphFallback theme={theme} block={block} />
    case 3:
      return <StyledH3Fallback />
    case 2:
    default:
      return <StyledH2Fallback />
    case 1:
      return <StyledH1Fallback />
  }
}

const AnimatedCircleFallback = (props) => {
  return <StyledCircleFallback $size={props.size} />
}

const AnimatedRectangleFallback = (props) => {
  return (
    <StyledRectangleFallback $height={props.height} $width={props.width}>
      {props.children}
    </StyledRectangleFallback>
  )
}

const AnimatedGameTileFallback = () => {
  return <StyledGameTileFallback />
}

AnimatedTextFallback.defaultProps = {
  level: 3,
  block: false,
  theme: 'dark',
}
AnimatedTextFallback.propTypes = {
  level: PropTypes.number,
  block: PropTypes.bool,
  theme: PropTypes.string,
}

AnimatedCircleFallback.defaultProps = { size: '2em' }
AnimatedCircleFallback.propTypes = { size: PropTypes.string }

AnimatedRectangleFallback.defaultProps = {
  width: '100%',
  height: '100%',
  children: <></>,
}
AnimatedRectangleFallback.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.any,
}

AnimatedGameTileFallback.defaultProps = {}
AnimatedGameTileFallback.propTypes = {}

export {
  AnimatedTextFallback,
  AnimatedCircleFallback,
  AnimatedRectangleFallback,
  AnimatedGameTileFallback,
}
