import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { themeStyles, themeMedia } from '../../styles/theme'
import { AuthContext } from '../../context/authProvider'

import { Container } from '../../atomic-components/atoms/container'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'
import { getLinkRel } from '../../utils/getLinkRel'

const MenuWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 1rem 0 1.5rem 0;
`

const Desktop = styled.div`
  display: none;

  ${themeMedia.tablet`
    display: block;
  `};
`

const Mobile = styled.div`
  ${themeMedia.tablet`
    display: none;
  `};
`

const MenuSection = styled.div`
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0 1rem;
  padding-right: 1rem;
  box-sizing: border-box;

  ${themeMedia.tablet`
    text-align: left;
    display: initial;
    flex: 0 0 20%;
    width: 20%;
    margin: 0;
  `};
`

const MenuSectionTitle = styled.h2`
  font-size: 0.9em;
  margin-bottom: 0.25rem;
  font-weight: 600;
`

const MenuSectionUl = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  line-height: 25px;
`

const MenuSectionLi = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  > a {
    color: ${themeStyles.colors.white.main};
    font-weight: 400;
    text-decoration: none;
    line-height: 1;
    padding: 8px 0;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  ${themeMedia.tablet`
    > a {
      line-height: initial;
      padding: initial;
      display: initial;
    }
  `};
`

const FooterMenu = () => {
  const { isLoggedIn } = useContext(AuthContext)
  const { desktopMenu, mobileMenu } = useFooterApiData()

  const renderMenuItem = (menu, index, external) => {
    if (external)
      return (
        <MenuSectionLi key={index}>
          <a
            aria-current="page"
            itemProp="url"
            rel={getLinkRel(menu.external_link.target)}
            target={menu.external_link.target}
            title={menu.menu_item_name}
            href={`${
              menu?.external_link?.url?.includes('&amp;')
                ? menu?.external_link?.url?.replaceAll('&amp;', '&')
                : menu?.external_link?.url
            }`}
          >
            {menu.menu_item_name}
          </a>
        </MenuSectionLi>
      )

    return (
      <MenuSectionLi key={index}>
        <Link
          aria-current="page"
          itemProp="url"
          title={menu.menu_item_name}
          to={`${
            menu?.external_link?.url?.includes('&amp;')
              ? menu?.external_link?.url?.replaceAll('&amp;', '&')
              : menu?.external_link?.url
          }`}
        >
          {menu?.menu_item_name}
        </Link>
      </MenuSectionLi>
    )
  }

  const filterLoggedInMenuItems = (menu) => {
    if (!isLoggedIn) {
      return menu.filter((m) => m?.show_in_logged_in === false)
    }

    return menu
  }

  const renderMenu = (menu) => {
    return menu.map((menu, i) => {
      return (
        <MenuSection key={i}>
          <MenuSectionTitle>{menu?.parent_menu_name}</MenuSectionTitle>
          <MenuSectionUl
            itemScope
            itemType="http://schema.org/SiteNavigationElement"
          >
            {filterLoggedInMenuItems(menu.sub_items).map((subItem, a) => {
              if (subItem?.external_link?.target === '_blank') {
                return renderMenuItem(subItem, a, true)
              } else {
                return renderMenuItem(subItem, a, false)
              }
            })}
          </MenuSectionUl>
        </MenuSection>
      )
    })
  }

  return (
    <div>
      <Container>
        <Desktop>
          <MenuWrapper>{renderMenu(desktopMenu)}</MenuWrapper>
        </Desktop>
        <Mobile>
          <MenuWrapper>{renderMenu(mobileMenu)}</MenuWrapper>
        </Mobile>
      </Container>
    </div>
  )
}

export default FooterMenu
