import { useStaticQuery, graphql } from 'gatsby'
import dayjs from 'dayjs'

export const useMaintenanceApiData = () => {
  const data = graphql`
    {
      maintenanceApi {
        values {
          lang
          notices {
            message
            title
            type
            from
            to
          }
        }
      }
    }
  `
  const response = useStaticQuery(data)
  const {
    maintenanceApi: { values },
  } = response

  let notices = values?.[0]?.notices || []

  const now = dayjs()
  notices = notices.filter(
    (b) => now <= dayjs.unix(b.to) && now >= dayjs.unix(b.from)
  )

  return { notices }
}
