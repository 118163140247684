import storageService from '../../services/storageService'

export const pushDataLayer = ({ eventName = '', data = {} }) => {
  const user = storageService.getUser()

  window.dataLayer.push({
    event: eventName,
    user_id: user?.id || null,
    ...data,
  })
}

export const normalizeDataLayerText = (text) =>
  text.toLowerCase().replaceAll(' ', '_')
