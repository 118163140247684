import React from 'react'
import { Field } from '../../molecules/field'
import { FieldPassword } from '../../molecules/fieldPassword'
import { useTranslation } from '../../../context/translationProvider'

const InovapayWallet = ({ register, errors }) => {
  const { translate } = useTranslation()
  return (
    <>
      <Field
        noBottomMargin
        label={translate('payments.userLogin')}
        type="email"
        errors={errors}
        formProperties={register('userLogin', {
          required: true,
        })}
      />

      <FieldPassword
        noBottomMargin
        label={translate('payments.userSecureId')}
        errors={errors}
        formProperties={register('userSecureId', {
          required: true,
        })}
      />
    </>
  )
}

export default InovapayWallet
