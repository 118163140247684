import { useEffect, useRef, useState } from 'react'

function useCarouselSteps(length, enableAutoSlide = true) {
  const [index, setIndex] = useState(0)
  const [timeoutId, setTimeoutId] = useState(0)
  const timeoutIdRef = useRef(timeoutId)
  timeoutIdRef.current = timeoutId

  const safeSetIndex = (newIndex, timeoutTime = 5000) => {
    setIndex(newIndex)
    if (!enableAutoSlide) return
    clearTimeout(timeoutIdRef.current)
    const newTimeoutId = setTimeout(() => goToNext(newIndex), timeoutTime)
    setTimeoutId(newTimeoutId)
  }

  const onNext = () => {
    const newIndex = index + 1
    if (newIndex < length) safeSetIndex(newIndex)
    else safeSetIndex(0)
  }

  const onPrevious = () => {
    const newIndex = index - 1
    if (newIndex >= 0) safeSetIndex(newIndex)
    else safeSetIndex(length - 1)
  }

  const goToNext = (currentIndex) => {
    const newIndex = currentIndex + 1
    if (newIndex < length) safeSetIndex(newIndex)
    else safeSetIndex(0)
  }

  useEffect(() => {
    if (!enableAutoSlide || length <= 1) return
    safeSetIndex(0, 10000)
    return () => clearTimeout(timeoutId.id)
  }, [])

  return { index, onNext, onPrevious, safeSetIndex }
}

export default useCarouselSteps
