import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { If } from '../../atoms/if'
import { PaginationDots } from '../../atoms/paginationDots'
import { ResponsiveImage } from '../../atoms/responsiveImage'
import { Text, Title } from '../../atoms/text'
import { SwipeDetector } from '../../atoms/swipeDetector'
import { FieldCpfSubmit } from '../fieldCpfSubmit'
import {
  StyledMobileCarouselWrapper,
  StyledNextButton,
  StyledSlide,
  StyledSlidesWrapper,
  StyledWaveContainer,
  StyledBottomRow,
  StyledImageWrapper,
  StyledTextCpfWrapper,
} from './styles'
import { sendRegistrationEvent } from '../../../utils/commonDataLayer'
import { AuthContext } from '../../../context/authProvider'
import useSlugs from '../../../hooks/useSlugs'
import { Wave } from '../../atoms/wave'
import { useGatsbyImageData } from '../../../hooks/graphqlStaticQuery/useGatsbyImageData'

const MobileCarousel = (props) => {
  const [registerSlug] = useSlugs(['register'])
  const { isLoggedIn } = useContext(AuthContext)
  const { findImage } = useGatsbyImageData()

  const {
    slides,
    index,
    onPrevious,
    onNext,
    hideMobileNextButton,
    bannerHeight,
    useGatsbyImage,
    onSlideFocus = () => {},
    onSlideBlur = () => {},
    onBannerClick,
  } = props

  const { length } = slides

  const onClick = (link) => {
    if (link?.includes(`/${registerSlug}/`)) {
      sendRegistrationEvent('mobile-carousel')
    }
  }

  const getImage = (s) => {
    if (s.background_image_mobile?.sizes) {
      return s.background_image_mobile
    } else {
      return s.background_image
    }
  }

  const registerWithCPF = (cpf) => {
    if (!sendRegistrationEvent('navRegister')) {
      sendRegistrationEvent('mobile-carousel-cpf')
      navigate(`/${registerSlug}/?nationalId=${cpf}`)
    }
  }

  const isCpfInputEnabled = useCallback(
    (slide) => {
      return !isLoggedIn && slide.add_cpf_field
    },
    [isLoggedIn]
  )

  const handleClick = ({ position, ...slide }) => {
    const link = isLoggedIn
      ? slide.cta_logged_in[0]?.link
      : slide.cta_logged_out[0]?.link

    isCpfInputEnabled(slide) ? () => onClick(link) : null

    if (onBannerClick) {
      onBannerClick({
        ...slide,
        position,
      })
    }
  }

  const createWaveArea = (slide, index) => {
    if (!slide.sub_text) {
      return (
        <StyledWaveContainer>
          <Wave color={slide.background_color} direction="horizontal" />
          <StyledTextCpfWrapper $backgroundColor={slide.background_color}>
            <StyledBottomRow>
              <Title
                tagType={slide.header_tag}
                style={{ color: slide.text_color }}
              >
                {slide.header_text}
              </Title>
              <If
                condition={!hideMobileNextButton && !isCpfInputEnabled(slide)}
                render={() => <StyledNextButton />}
              />
            </StyledBottomRow>
          </StyledTextCpfWrapper>
        </StyledWaveContainer>
      )
    }

    return (
      <StyledWaveContainer>
        <Wave color={slide.background_color} direction="horizontal" />
        <StyledTextCpfWrapper $backgroundColor={slide.background_color}>
          <Title
            tagType={slide.header_tag}
            style={{ color: slide.text_color, marginTop: '0' }}
          >
            {slide.header_text}
          </Title>
          <StyledBottomRow>
            <Text
              small
              style={{
                color: slide.text_color,
                fontSize: slide.add_cpf_field ? 11 : 14,
              }}
              smaller={isCpfInputEnabled(slide)}
            >
              {slide.sub_text}
            </Text>
            <If
              condition={!hideMobileNextButton && !slide.add_cpf_field}
              render={() => <StyledNextButton />}
            />
          </StyledBottomRow>
          <If
            condition={isCpfInputEnabled(slide)}
            render={() => (
              <FieldCpfSubmit
                key={`cpf-input-button-${index}`}
                onSubmit={registerWithCPF}
                onFocus={onSlideFocus}
                onBlur={onSlideBlur}
                buttonBackgroundColor={
                  slide.cta_logged_out[0]?.background_color || '#000'
                }
                buttonTextColor={slide.cta_logged_out[0]?.text_color || '#fff'}
              />
            )}
          />
        </StyledTextCpfWrapper>
      </StyledWaveContainer>
    )
  }

  return (
    <StyledMobileCarouselWrapper>
      <SwipeDetector onSwipeRight={onPrevious} onSwipeLeft={onNext}>
        <StyledSlidesWrapper length={length} index={index}>
          {slides.map((slide, banner_i) => {
            const link = isLoggedIn
              ? slide.cta_logged_in[0]?.link
              : slide.cta_logged_out[0]?.link

            return (
              <StyledSlide
                $backgroundColor={slide.background_color}
                length={length}
                key={banner_i}
                onClick={() => handleClick({ ...slide, position: banner_i })}
                to={isCpfInputEnabled(slide) ? null : link}
              >
                <StyledImageWrapper
                  hasTooMuchText={slide.sub_text.length > 80}
                  bannerHeight={bannerHeight}
                >
                  <ResponsiveImage
                    image={getImage(slide)}
                    loading={banner_i === 0 ? 'eager' : 'lazy'}
                    preload
                    big
                    useGatsbyImage={useGatsbyImage}
                    gatsbyImage={findImage(
                      slide?.background_image_mobile?.url ||
                        slide?.background_image?.url
                    )}
                  />
                </StyledImageWrapper>
                {createWaveArea(slide)}
              </StyledSlide>
            )
          })}
        </StyledSlidesWrapper>
      </SwipeDetector>
      <PaginationDots length={length} index={index} />
    </StyledMobileCarouselWrapper>
  )
}

MobileCarousel.defaultProps = {
  slides: [],
  index: 0,
  onPrevious: () => {},
  onNext: () => {},
  onSlideFocus: () => {},
  onSlideBlur: () => {},
  isLoggedIn: false,
  hideMobileNextButton: false,
  bannerHeight: null,
}

MobileCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      background_color: PropTypes.string,
      header_tag: PropTypes.string,
      header_text: PropTypes.string,
      sub_text: PropTypes.string,
      text_color: PropTypes.string,
      cta_logged_out: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      cta_logged_in: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      background_image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSlideFocus: PropTypes.func,
  onSlideBlur: PropTypes.func,
  hideMobileNextButton: PropTypes.bool,
  bannerHeight: PropTypes.number,
  useGatsbyImage: PropTypes.bool,
  onBannerClick: PropTypes.func,
}

export { MobileCarousel }
