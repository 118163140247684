import React from 'react'

import { Container } from '../container'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { StyledLicenseLogo, StyledLicenseWrapper } from './styles'

const FooterLicense = () => {
  const { footer_misc } = useFooterV2ApiData()
  const licensesFooterMisc = footer_misc.find(
    (item) => item.acf_fc_layout === 'licenses'
  )
  // const [tag, setTag] = useState('')

  // useEffect(() => {
  //   const script = licensesFooterMisc?.scripts
  //   const tagsRegex = /<div(.*?)<\/div>/g
  //   const slashRegex = /\\[a-z]/g
  //   const cleanedUpScript = script
  //     .replaceAll(tagsRegex, '')
  //     .replaceAll(slashRegex, '')
  //   const tagFromScript = script.match(tagsRegex)
  //   setTag(tagFromScript.join(''))
  //   eval(cleanedUpScript)
  // }, [])

  const parseHtmlData = (htmlData) => {
    return htmlData.replaceAll('\\"', '"')
  }

  if (!licensesFooterMisc) return

  return (
    <div>
      <Container>
        <StyledLicenseWrapper>
          <StyledLicenseLogo
            dangerouslySetInnerHTML={{
              __html: parseHtmlData(licensesFooterMisc.scripts),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: parseHtmlData(licensesFooterMisc?.description_html),
            }}
          />
        </StyledLicenseWrapper>
      </Container>
    </div>
  )
}

export { FooterLicense }
