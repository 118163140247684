import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledToast = styled.div`
  box-shadow: none;
  opacity: 1;
  display: flex;
  padding: 0 1rem;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
`

export const StyledMessage = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px 40px;
  ${themeMedia.tablet`
    padding: 7.5px 15px;  
  `}
`
