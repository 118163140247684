import { verifyPromoCode } from '../adapters/promoCodes'

export const isCouponValid = async (coupon) => {
  const { error } = await verifyPromoCode(coupon)

  if (error) {
    return {
      messageCode: error,
    }
  }

  return {
    messageCode: null,
  }
}

export const couponValidator = (event) => {
  if (!event?.key.match(/[a-zA-Z0-9]/)) {
    event?.preventDefault()
  }
}
