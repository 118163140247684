import React from 'react'
import PropTypes from 'prop-types'

export const RightArrow = ({ color }) => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17163 6.41421L0.171631 1.41421L1.58584 0L6.58584 5L8.00006 6.41421L6.58584 7.82843L1.58584 12.8284L0.171631 11.4142L5.17163 6.41421Z"
        fill={color}
      />
    </svg>
  )
}

RightArrow.defaultProps = {
  color: '#fff',
}

RightArrow.propTypes = {
  color: PropTypes.string,
}
