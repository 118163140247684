import { apiGET } from '../utils/adapterFunctions'

const locale = process.env.GATSBY_INTL_LOCALE
const languages = {
  en: 'en',
  in: 'en',
  ca: 'en-ca',
  pe: 'es-pe',
  cl: 'es-cl',
  pt: 'pt-br',
}
const language = languages[locale]

export const getNotificationsBage = async () => 
  await apiGET('/notifications/badge')

export const getNotificationsList = async ({
  expired = false,
  pageSize = 10,
  pageNumber = 0,
}) => {
  const status = expired ?
    'expired' :
    'active/?sort=clickedOn,desc&sort=endDate,asc'

  return await apiGET(`/notifications/${status}`, {
    params: {
      pageSize,
      pageNumber,
      language,
    },
  })
}

export const getNotificationRedirect = async ({
  playerNotificationId,
  startDate,
}) => {
  return await apiGET(
    `/notifications/${playerNotificationId}/${startDate}/redirect-url`,
    { params: { language } }
  )
}