import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import {
  StyledCashback,
  StyledHref,
  StyledRow,
  StyledNotification,
  ArrowDownImage,
  NotificationImage,
  StyledContactUs,
} from './styles'
import { Image } from '../../atoms/image'
import { If } from '../../atoms/if'

import { AuthContext } from '../../../context/authProvider'
import { Button } from '../../atoms/button'

import ArrowDown from '../../../images/arrow_standard_icon.svg'
import NotificationIcon from '../../../images/icon_notification.svg'
import storageService from '../../../services/storageService'
import { removeLocaleFromURL } from '../../../utils/generic'
import { useModalContext } from '../../../context/modalProvider'
import { ModalTypes } from '../../../utils/constants'
import { sendMobileAppEvent } from '../../../utils/mobileAppFunctionality'
import { sendRegistrationEvent } from '../../../utils/commonDataLayer'
import useSlugs from '../../../hooks/useSlugs'

const NavLink = (props) => {
  const { menuItem, expand, idSuffix, action } = props
  const { className, link } = menuItem ?? {}

  const { open } = useModalContext()
  const { isLoggedIn } = useContext(AuthContext)
  const location = useLocation()

  // ? Function is bigger than it should be so that its best on performance
  const getIsActive = (url) => {
    const pathname = removeLocaleFromURL(location?.pathname)
    const isGameLink = pathname.split('/')[2] === 'game'

    if (isGameLink) {
      const gameLobby = removeLocaleFromURL(
        storageService.getValue('gameLobby')
      )

      if (gameLobby.length > 1) return gameLobby === url
    }
    return pathname.startsWith(url)
  }

  const getUrl = (menu) => {
    if (isLoggedIn && menu?.link_logged_in?.url !== '') {
      return menu?.link_logged_in?.url
    }
    return menu?.link?.url
  }

  const [isLinkActive, setIsLinkActive] = useState(getIsActive(link?.url))

  useEffect(() => {
    //? We need to set isLinkActive *again*, gatsby doesnt have storageService
    setIsLinkActive(getIsActive(link?.url))
  }, [menuItem])

  const createCashbackLink = () => {
    return (
      <StyledCashback
        img={menuItem?.image?.url}
        title={`${link?.title}`}
        className={`${menuItem?.className} ${menuItem?.show_icon}`}
        state={{ prevPath: location?.href }}
        onClick={action}
        $idSuffix={idSuffix}
      >
        {menuItem.text}
      </StyledCashback>
    )
  }

  const createNotification = () => {
    return (
      <StyledNotification
        to={getUrl(menuItem)}
        img={menuItem?.image?.url}
        title={menuItem?.title}
        className={`${menuItem?.className} ${menuItem?.show_icon}`}
        state={{ prevPath: location?.href }}
        onAnimationEnd={menuItem?.onAnimationEnd}
        onClick={menuItem?.onClick}
        hasNotification={menuItem?.text > 0}
      >
        <NotificationImage
          src={NotificationIcon}
          className={`${menuItem?.animate ? 'animate' : ''}`}
        />
        <If
          condition={menuItem?.text > 0}
          render={() => <>{menuItem.text}</>}
        />

        <If
          condition={!!menuItem?.showArrow}
          render={() => (
            <ArrowDownImage
              src={ArrowDown}
              hasNotification={menuItem?.text > 0}
            />
          )}
        />
      </StyledNotification>
    )
  }

  const createCTA = () => {
    const isRegister = menuItem?.className?.includes('primary')

    const onRegister = () => {
      if (sendMobileAppEvent('navRegister')) return
      if (location?.pathname?.includes(getUrl(menuItem))) return
      window?.dataLayer?.push({ event: 'register_click' })
      sendRegistrationEvent(`nav-button${idSuffix}`)

      open({ type: ModalTypes.REGISTER })
    }

    const onLogin = (e) => {
      if (sendMobileAppEvent('navLogin')) e.preventDefault()
    }

    const functionality = isRegister
      ? { onClick: onRegister, id: `registerBtn${idSuffix}` }
      : {
          onClick: onLogin,
          id: `loginBtn${idSuffix}`,
          href: getUrl(menuItem),
          bordered: true,
        }

    return (
      <Button
        {...functionality}
        className={`${menuItem?.className} ${menuItem?.show_icon}`}
        expand={expand}
        nav
      >
        {menuItem.text}
      </Button>
    )
  }

  const createIntercomButton = () => {
    const toggleContactUs = () => {
      if (window.isIntercomOpen) window.Intercom('hide')
      else window.Intercom('show')
    }
    return (
      <StyledContactUs
        img={menuItem?.image?.url}
        title={`${menuItem?.name}`}
        className={`${menuItem?.className} ${menuItem?.show_icon}`}
        $idSuffix={idSuffix}
        onClick={toggleContactUs}
      >
        {menuItem.text}
      </StyledContactUs>
    )
  }

  if (className?.includes('cashback')) {
    return createCashbackLink()
  } else if (className?.includes('notification')) {
    return createNotification()
  } else if (className?.includes('contact-us')) {
    return createIntercomButton()
  } else if (className?.includes('cta')) {
    return createCTA()
  }

  const [liveSportsSlug, sportsSlug, totoSlug, virtualSportsSlug] = useSlugs([
    'live-sports',
    'sports',
    'toto',
    'virtual-sports',
  ])

  const redirectSportsbookToHome = () => {
    let page = 'overview'
    switch (getUrl(menuItem)) {
      case `/${liveSportsSlug}/`:
        page = 'live'
        break
      case `/${virtualSportsSlug}/`:
        page = 'virtual'
        break
      case `/${totoSlug}/`:
        page = 'toto'
        break

      default:
      case `/${sportsSlug}/`:
        page = 'overview'
        break
    }

    window?.altenarWSDK?.set({
      page,
    })
  }

  const attributes =
    link?.target === '_blank' ? { target: link?.target, rel: 'external' } : null

  return (
    <StyledRow className="nav-link-row">
      <If
        condition={!!menuItem?.image?.url}
        render={() => (
          <Image menuItem={menuItem} className={menuItem?.show_icon} />
        )}
      />
      <StyledHref
        {...attributes}
        to={getUrl(menuItem)}
        title={link?.title}
        $active={isLinkActive}
        className={menuItem?.className}
        id={`desktopNavItem-${menuItem?.text}`}
        $idSuffix={idSuffix}
        onClick={redirectSportsbookToHome}
      >
        {menuItem?.text}
      </StyledHref>
    </StyledRow>
  )
}

NavLink.defaultProps = {
  menuItem: {},
  locale: 'en',
  expand: false,
  idSuffix: '',
  action: () => {},
}

NavLink.propTypes = {
  menuItem: PropTypes.object,
  locale: PropTypes.string,
  expand: PropTypes.bool,
  idSuffix: PropTypes.string,
  action: PropTypes.func,
}

export { NavLink }
