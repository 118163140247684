import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

export const useGatsbyImageData = () => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        nodes {
          childImageSharp {
            gatsbyImageData(
              width: 2048
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  const findImage = (url) => {
    const image = data?.allFile?.nodes?.find((item) =>
      item?.childImageSharp?.gatsbyImageData?.images?.fallback?.src?.includes(
        url?.split('/')?.pop()?.replace('.jpeg', '.jpg')
      )
    )

    return getImage(image)
  }

  return {
    findImage,
  }
}
