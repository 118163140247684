import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { CssH1, CssH2, CssH3, CssParagraph } from '../../atoms/text/styles'

export const CssSeoText = css`
  b {
    font-weight: 600;
  }

  a {
    color: ${themeStyles.colors.white.main};
  }

  h1 {
    ${CssH1}
    margin: 30px 0 20px;
    line-height: 1.2em;
  }

  h2 {
    ${CssH2}
    margin: 25px 0 15px;
    line-height: 1.2em;

    ${themeMedia.maxTablet`
        font-size: 1.33em;
      `}
  }

  h3 {
    ${CssH3}
    margin: 20px 0 10px;
    line-height: 1.2em;

    ${themeMedia.maxTablet`
        font-size: 1.12em;
      `}
  }

  p {
    ${CssParagraph}/* font-size: 0.95em; */
  }
`

export const StyledContainer = styled.section`
  background-color: ${themeStyles.colors.darkGreys[4].main};
  border-radius: 8px;
  color: ${themeStyles.colors.white.main};
  padding: 1em;
  line-height: 1.4;
  position: relative;
  margin-top: 0.75em;

  ${CssSeoText}

  ${themeMedia.tablet`
    padding: 1.5rem;
  `}

  > div > *:first-child {
    margin-top: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  div.collapsed {
    height: 55px;
    overflow: hidden;
    padding: 1rem 0;
    ${themeMedia.tablet`
      height: 25px;
      padding: 1.75rem 0 0 0;
    `}
  }

  div.expanded {
    padding: 1rem 0;
    ${themeMedia.tablet`
      padding: 1.75rem 0 0 0;
    `}
  }
`

export const SeoContentCollapseExpand = styled.img`
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  align-self: start;
  padding-left: 1em;
`

export const SeoContentHeader = styled.h1`
  margin: 0 !important;
  line-height: 1 !important;
  overflow: hidden;
  text-wrap: wrap;
}
`
export const SeoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
