import styled, { keyframes } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Spinner = styled.div`
  border: 3px solid ${themeStyles.colors.lightGreys[3].main};
  border-top: 3px solid ${themeStyles.colors.lightGreys[1].main};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: ${spin} 2s linear infinite;
`
