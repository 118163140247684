import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import error_icon from '../../../images/error_icon.svg'
import success_icon from '../../../images/success_icon.svg'
import { brandConfig } from '../../../config/brandConfig'

export const inputBorderSize = '2px'

const InputBase = css`
  height: 48px;
  font-size: ${brandConfig.fonts.text.input.size.desktop};
  font-family: ${brandConfig.fonts.text.input.family};

  box-sizing: border-box;
  padding: 0.55em;
  width: 100%;
  min-width: 100%;
  line-height: initial;

  background-color: ${themeStyles.colors.lightGreys[1].main};
  color: ${themeStyles.colors.black.main};
  border: ${inputBorderSize} solid ${themeStyles.colors.lightGreys[1].main};
  border-radius: 6px;
`

const InputDisabled = css`
  background-color: ${themeStyles.colors.lightGreys[2].main} !important;
  border: 0;

  /* Override iOS disabled css */
  -webkit-text-fill-color: ${themeStyles.colors.lightGreys[4].main};
  -webkit-opacity: 1;
  opacity: 1;
  color: ${themeStyles.colors.lightGreys[4].main};
`

const InputInvalid = css`
  background-color: ${themeStyles.colors.white.main};
  border-color: ${themeStyles.colors.error.main} !important;

  ${(props) =>
    !props.hideSymbols &&
    !props.hasSuffix &&
    css`
      background-image: url('${error_icon}');
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 20px;
      padding-right: 2.5em;
    `}
`

const InputValid = css`
  &:focus {
    background-color: ${themeStyles.colors.white.main};
    border-color: ${themeStyles.colors.accentColors.primary.main};
  }

  ${(props) =>
    !props.hideSymbols &&
    !props.hasSuffix &&
    !props.disabled &&
    css`
      background-image: url('${success_icon}');
      background-repeat: no-repeat;
      background-position: right 0.5em center;
      background-size: 1.2em;
      padding-right: 2.5em;
    `}
`

const InputFocus = css`
  &:focus {
    background-color: ${themeStyles.colors.white.main};
    border-color: ${themeStyles.colors.products.sports.main};
  }
`

export const InputCSS = css`
  ${InputBase}

  ${(props) =>
    props.hasSuffix ?
      'padding-right: 3em;' :
      props.hideSymbols && 'padding-right: 2.5em;'}
  ${(props) => !props.valid && !props.invalid && !props.disabled && InputFocus}
  ${(props) => props.valid && InputValid}
  ${(props) => props.invalid && InputInvalid}

  &:focus-visible {
    outline: none;
  }

  &:not(select):not(:placeholder-shown):not([type='date']),
  &:not(select):not(:disabled):not([value='']) {
    background-color: ${themeStyles.colors.white.main};
  }

  ${(props) =>
    props.isValuePicked &&
    css`
      background-color: ${themeStyles.colors.white.main};
    `}

  &:disabled {
    ${InputDisabled}
  }

  &::placeholder {
    color: ${themeStyles.colors.lightGreys[3].main};
  }
`

export const StyledInput = styled.input`
  ${InputCSS}
`

export const StyledInputWrapper = styled.div`
  position: relative;

  input {
    ${InputCSS}
  }
`

export const StyledSuffixWrapper = styled.div`
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: ${(props) => props.suffixPointerEvents};
`
