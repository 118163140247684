import { useContext, useEffect } from 'react'
import { getCountryRegions, getLanguages } from '../adapters/common'
import { useTranslation } from '../context/translationProvider'
import { AuthContext } from '../context/authProvider'

const emptyFunction = () => {}

export const useRegisterData = (props) => {
  const { accessCountry } = useContext(AuthContext)
  const {
    isMgaLicense,
    country: previousCountry,
    setValue = emptyFunction,
    setLanguages = emptyFunction,
    setPhoneCodes = emptyFunction,
    setMobilePrefix = emptyFunction,
    setCountry = emptyFunction,
    setRegions = emptyFunction,
    setWhitelistedCountries = emptyFunction,
  } = props

  const { translate, countriesArray: countries } = useTranslation()

  useEffect(() => {
    loadData(getLanguages, setLanguages)
  }, [])

  useEffect(() => {
    if (isMgaLicense || countries.length == 0 || !accessCountry) return

    safeSetCountry(accessCountry)
  }, [countries, accessCountry])

  const findCountry = (idOrName) => {
    return countries?.find(
      (country) =>
        Number(country?.id) === Number(idOrName) ||
        country?.name?.toUpperCase() === idOrName?.toUpperCase()
    )
  }

  useEffect(() => {
    if (!countries?.length) return
    const codes = []

    countries.forEach((c) => {
      if (c.phone_prefix !== '') {
        codes.push(`+${c.phone_prefix}`)
      }
      c.translated_name = translate(`country.${c.name?.toLowerCase()}`, c.name)
    })
    codes.sort()
    const uniqueCodes = [...new Set(codes)]
    setPhoneCodes(uniqueCodes)
  }, [countries])

  async function loadData(endpoint, setter) {
    const response = await endpoint()
    if (response.ok) setter(response.data.data)
  }

  const getRegions = async (countryId) => {
    const response = await getCountryRegions({ countryId })
    if (response.ok) return response.data.data
  }

  const safeSetCountry = async (accessCountry) => {
    const country = findCountry(accessCountry)

    if (!country || country.code === previousCountry?.code) return
    setValue('countryCode', country.code)

    setValue('country', country.name)
    setCountry(country)
    setWhitelistedCountries(`country:${country.code}`)

    setValue('currency', country.default_country_currency)
    if (country.phone_prefix) {
      setValue('mobilePrefix', `+${country.phone_prefix}`)
      setMobilePrefix(`+${country.phone_prefix}`)
    }
    setValue('region', '')
    const regions = await getRegions(country.id)
    setRegions(regions)
  }

  return { safeSetCountry, countries }
}
