import * as Sentry from '@sentry/browser'

import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import PropTypes from 'prop-types'

import storageService from '../services/storageService'

import {
  doLogin,
  getCurrentUser,
  countryCheckTranslations,
  doLogout,
  getWallet,
} from '../adapters/auth'
import { usePusher } from '../hooks/usePusher'
import { useVault } from '../hooks/useVault'
import { isBrowser } from '../utils/generic'
import {
  isMobileApp,
  sendMobileAppEvent,
} from '../utils/mobileAppFunctionality'
import { FIFTEEN_SECONDS } from '../utils/constants'
import {
  setPushNotificationToken,
  smarticoLogout,
  smarticoLogin,
} from '../utils/updateSmarticoVars'
import useSlugs from '../hooks/useSlugs'
import useFeatures from '../hooks/useFeatures'
import { getCookie } from '../utils/cookies'
import { getUserIntercomToken, getUserRequiredData } from '../adapters/user'

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  requiredData: {},
  countryBlocked: false,
  loginBlocked: false,
  registrationBlocked: false,
  blokedPageTransations: [],
  isPusherAvailable: false,
  enableWalletSocketFallback: false,
  isPusherEnabled: false,
  walletSocketFallback: () => {},
  getUserWallet: () => {},
  updateRequiredData: () => {},
  loginUser: () => {},
  logoutUser: () => {},
  updateUser: () => {},
  safeSetUser: async () => {},
  getVaultData: () => {},
})

const AuthProvider = ({ children }) => {
  const fetchUserIntervalRef = useRef()
  const walletIntervalRef = useRef()
  const [user, setUser] = useState(null)
  const [requiredData, setRequiredData] = useState({})
  const [isLoggedIn, setIsLoggedIn] = useState(null)
  const [countryBlocked, setCountryBlocked] = useState(false)
  const [loginBlocked, setLoginBlocked] = useState(false)
  const [registrationBlocked, setRegistrationBlocked] = useState(false)
  const [blockedPageTranslations, setBlockedPageTranslations] = useState([])
  const isCasinoGameWalletPullingEnabled = useFeatures(
    'casinoGameWalletPulling'
  )

  const [casinoSlug, liveCasinoSlug] = useSlugs(['casino', 'live-casino'])

  const [enableWalletSocketFallback, setEnableWalletSocketFallback] =
    useState(false)

  const [accessCountry, setAccessCountry] = useState()

  const {
    vaultAmount,
    activeCards,
    claimedCards,
    loadingVaultData,
    hasMoreClaimedCards,
    shorterDeadline,
    loadingCashout,
    getVaultData,
    updateActiveVaultCards,
    getClaimedData,
    cashout,
  } = useVault({ isLoggedIn })

  const handleGetInitialData = async () => {
    try {
      const { data } = await countryCheckTranslations()
      if (!data) return

      setCountryBlocked(!data?.active)
      setLoginBlocked(data?.login_blocked)
      setRegistrationBlocked(data?.registration_blocked)
      setBlockedPageTranslations(data?.translations)
      setAccessCountry(data?.name)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    sendMobileAppEvent('authState', { token: storageService.getAccessToken() })
  }, [isLoggedIn])

  useEffect(() => {
    handleGetInitialData()

    if (isLoggedIn) {
      setRequiredData(storageService.getRequiredData())
    } else {
      setRequiredData({})
    }
  }, [isLoggedIn])

  const clearSession = useCallback((dontSendMobileEvent) => {
    if (!dontSendMobileEvent) sendMobileAppEvent('logout')
    setUser(false)
    smarticoLogout()
    storageService.clearTokens()
    storageService.clearIntercomData()
  }, [])

  const safeSetUser = async () => {
    try {
      const user = await getCurrentUser()
      try {
        const userIntercomToken = await getUserIntercomToken()
        user.data.intercomToken = userIntercomToken.data
      } catch (error) {
        Sentry.captureException('[IntercomToken]: ' + error)
      }
      setUser((user) => ({ ...user, ...user.data }))
      storageService.setUser(JSON.stringify(user.data))
      return true
    } catch (_) {
      clearSession()
      return false
    }
  }

  const updateRequiredData = async (hardcodedData) => {
    if (hardcodedData) {
      setRequiredData(hardcodedData)
      storageService.setRequiredData(JSON.stringify(hardcodedData))
      return
    }

    const { ok, data } = await getUserRequiredData()

    if (ok) {
      setRequiredData(data)
      storageService.setRequiredData(JSON.stringify(data))
    }
  }

  const fetchUser = useCallback(() => {
    const fetchUserInterval = setInterval(() => {
      if (document.hidden) return
      safeSetUser()
    }, 300000) // every 5 minutes call getCurrentUser

    fetchUserIntervalRef.current = fetchUserInterval
  }, [fetchUserIntervalRef, clearSession])

  const onMobileAppTokenReceived = async (e) => {
    if (!e?.detail?.access_token) {
      clearSession()
      return
    }

    const previousAccessToken = storageService.getAccessToken()
    if (previousAccessToken) {
      storageService.setTokens(e.detail)
      return
    }

    clearSession(true)

    storageService.setTokens(e.detail)
    const isValid = await safeSetUser()
    updateRequiredData()

    handleSmartico()

    sendMobileAppEvent('tokenVerification', { isValid })
  }

  useEffect(() => {
    window.addEventListener('MOBILE_APP_TOKEN', onMobileAppTokenReceived)
    window.addEventListener(
      'PUSH_NOTIFICATIONS_TOKEN',
      setPushNotificationToken
    )
    sendMobileAppEvent('readyForToken', {
      token: storageService.getAccessToken(),
    })
  }, [])

  useEffect(() => {
    const storedAccessToken = storageService.getAccessToken()
    const storedUser = storageService.getUser()
    const isKTOApp = !!window.KTOMobileApp
    if (storedAccessToken && storedUser) {
      setUser(storedUser)
      fetchUser()
    } else if (!isKTOApp || (isKTOApp && !storedAccessToken)) {
      clearSession()
    }

    return () => {
      clearInterval(fetchUserIntervalRef.current)
    }
  }, [clearSession, fetchUser, fetchUserIntervalRef])

  const loginUser = async ({ username, password, loginData, token }) => {
    const GAToken = getCookie('_ga')

    const response = loginData
      ? loginData
      : await doLogin(username, password, token, GAToken)

    if (!response.ok) return response

    storageService.removeValue('notification')
    storageService.setValue('checkNotifications', true)

    // This is temporary solution so the users stuck in the waiting step
    // of the withdrawal doesnt need to login two times
    storageService.clearIsWaitingVerification()

    storageService.setTokens(response.data)

    const user = await getCurrentUser()
    if (user.ok) {
      storageService.setValue('realityCheckStartTime', new Date().getTime())

      try {
        const userIntercomToken = await getUserIntercomToken()
        user.data.intercomToken = userIntercomToken.data
        storageService.clearIntercomData()
      } catch (error) {
        Sentry.captureException('[IntercomToken]: ' + error)
      }
      setUser(user.data)
      storageService.setUser(JSON.stringify(user.data))
      fetchUser()

      handleSmartico()
      updateRequiredData()

      try {
        /* eslint-disable no-undef */
        Sentry.configureScope((scope) => {
          scope.setUser({ email: user.data.email, id: user.data.id })
        })

        if (isBrowser()) {
          window.dataLayer?.push({
            event: 'logged_in',
            user_id: user.data.id,
          })
        }
      } catch (ex) {
        console.error(ex.message)
      }
    }

    return response
  }

  const handleSmartico = () => {
    const onSmarticoLoaded = () => {
      window.removeEventListener('SMARTICO_LOADED', onSmarticoLoaded)
      smarticoLogin()
    }

    if (window?._smartico?.checkInit()) {
      smarticoLogin()
    } else {
      window.addEventListener('SMARTICO_LOADED', onSmarticoLoaded)
    }
  }

  const updateUserWallet = ({ wallet, amount, vault_balance }) => {
    const storedUser = storageService.getUser()

    if (wallet) {
      storedUser.wallet = wallet
    }

    if (amount) {
      storedUser.wallet = {
        ...storedUser.wallet,
        amount,
      }
    }

    if (vault_balance) {
      storedUser.wallet = {
        ...storedUser.wallet,
        vault_balance,
      }
    }

    setUser(storedUser)
    storageService.setUser(JSON.stringify(storedUser))
    sendMobileAppEvent('walletStatus', { amount: storedUser?.wallet?.amount })
  }

  const { isPusherEnabled, isPusherAvailable } = usePusher({
    isLoggedIn,
    user,
    updateUserWallet,
    updateActiveVaultCards,
  })

  const getUserWallet = useCallback(
    async (force = false) => {
      if (isPusherAvailable && !force) return

      const { data } = await getWallet()
      updateUserWallet({ wallet: data })
    },
    [isPusherAvailable]
  )

  useEffect(() => {
    const isGamePage =
      window.location.pathname?.includes(`${casinoSlug}/game`) ||
      window.location.pathname?.includes(`${liveCasinoSlug}/game`)

    if (
      !enableWalletSocketFallback ||
      isPusherEnabled ||
      (isGamePage && isCasinoGameWalletPullingEnabled)
    )
      return

    const walletInterval = setInterval(() => {
      getUserWallet()
      getVaultData({ load: false })
    }, FIFTEEN_SECONDS)

    walletIntervalRef.current = walletInterval

    return function () {
      clearInterval(walletInterval)
    }
  }, [
    getUserWallet,
    enableWalletSocketFallback,
    isPusherEnabled,
    walletIntervalRef,
    casinoSlug,
    liveCasinoSlug,
    isCasinoGameWalletPullingEnabled,
  ])

  const walletSocketFallback = useCallback(() => {
    setEnableWalletSocketFallback(!isPusherAvailable)
  }, [isPusherAvailable])

  const logoutUser = () => {
    doLogout()
      .then(() => {
        clearSession()
      })
      .catch((err) => {
        if (err.response.status === 401) {
          clearSession()
        }
      })
  }

  useEffect(() => {
    setIsLoggedIn(user ? !!user : user)
  }, [user])

  useEffect(() => {
    if (!isLoggedIn || !isMobileApp()) return
    walletSocketFallback()
  }, [isLoggedIn, walletSocketFallback, isPusherAvailable])

  const updateUser = (updatedData) => {
    const storedUser = storageService.getUser()
    setUser({ ...storedUser, ...updatedData })
    storageService.setUser(JSON.stringify({ ...storedUser, ...updatedData }))
  }

  const value = {
    loginUser,
    logoutUser,
    walletSocketFallback,
    getUserWallet,
    updateRequiredData,
    updateUser,
    getClaimedData,
    cashout,
    safeSetUser,
    getVaultData,
    isLoggedIn,
    user,
    requiredData,
    countryBlocked,
    loginBlocked,
    registrationBlocked,
    blockedPageTranslations,
    isPusherAvailable,
    vaultAmount,
    activeCards,
    loadingVaultData,
    claimedCards,
    hasMoreClaimedCards,
    shorterDeadline,
    loadingCashout,
    accessCountry,
    enableWalletSocketFallback,
    isPusherEnabled,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}

// eslint-disable-next-line
// export default ({ element }) => <AuthProvider>{element}</AuthProvider>
export default AuthProvider
