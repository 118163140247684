import { graphql, useStaticQuery } from 'gatsby'

const locale = process.env.GATSBY_INTL_LOCALE

export const useFooterApiData = () => {
  const data = useStaticQuery(graphql`
    {
      footerApi {
        values {
          lang
          footerData {
            partners_sponsors {
              partnerText
              sponsorText
              images {
                description
                type_of_record
                link
                image {
                  alt
                  url
                }
              }
            }
            responsible_gaming {
              text
              url
              images {
                image {
                  alt
                  url
                  height
                  width
                }
                link {
                  title
                  url
                  target
                }
              }
            }
            footer_logo_area {
              description
              image {
                alt
                url
                width
                height
              }
              link {
                target
                url
                title
              }
            }
            license {
              script
              description
            }
            cookieSettings {
              text
              cookieSettingsEnabled
            }
          }
          footerMobileMenu {
            parent_menu_name
            slug
            sub_items {
              external_link {
                url
              }
              show_in_logged_in
              menu_item_name
              slug
            }
          }
          footerDesktopMenu {
            parent_menu_name
            slug
            sub_items {
              external_link {
                url
                target
              }
              left_side_menu_items {
                item_name
                slug_url
              }
              show_in_logged_in
              menu_item_name
              slug
            }
          }
          socialMediaLinksData {
            linkDescription
            orderPosition
            destinationUrl
            iconLocation
            width
            height
          }
        }
      }
    }
  `)

  const {
    footerApi: { values },
  } = data

  const footerValues = values?.[0]
  const {
    footerData,
    footerDesktopMenu: desktopMenu = null,
    footerMobileMenu: mobileMenu = null,
    socialMediaLinksData = [],
  } = footerValues

  const {
    partners_sponsors: partnersSponsors = null,
    responsible_gaming: responsibleGaming = null,
    footer_logo_area: logoArea = null,
    license = null,
    cookieSettings = null,
  } = footerData

  const socialMediaLinks = socialMediaLinksData?.filter(
    (link) => link?.destinationUrl
  )

  const termsLink = values.some((slug) => slug.lang === locale) ?
    values
        .filter((slug) => slug?.lang === locale)[0]
        .footerDesktopMenu[1].sub_items.filter(
          (item) => item.slug === 'terms_and_conditions'
        )[0].external_link.url :
    null

  return {
    partnersSponsors,
    responsibleGaming,
    logoArea,
    license,
    cookieSettings,
    desktopMenu,
    mobileMenu,
    termsLink,
    socialMediaLinks,
  }
}
