import React from 'react'
import PropTypes from 'prop-types'
import { StyledSlidesAndArrowsWrapper } from './styles'
import { If } from '../if'

const CarouselButtons = (props) => {
  const { showButtons, onNext, onPrevious, children } = props

  return (
    <StyledSlidesAndArrowsWrapper>
      {children}
      <If
        condition={showButtons}
        render={() => (
          <>
            <button
              role="button"
              className="pmCarousel-previous-button"
              onClick={onPrevious}
              aria-label="Previous item"
            />
            <button
              role="button"
              className="pmCarousel-next-button"
              onClick={onNext}
              aria-label="Next item"
            />
          </>
        )}
      />
    </StyledSlidesAndArrowsWrapper>
  )
}

CarouselButtons.defaultProps = {
  showButtons: true,
  onNext: () => {},
  onPrevious: () => {},
  children: <></>,
}

CarouselButtons.propTypes = {
  showButtons: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  children: PropTypes.any,
}

export { CarouselButtons }
