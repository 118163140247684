import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import TimesBlackIcon from '../../../images/icon_times_black.svg'
import TimesWhiteIcon from '../../../images/icon_times_white.svg'
import { brandConfig } from '../../../config/brandConfig'

export const StyledDrawerWrapper = styled.aside`
  background: ${themeStyles.colors.white.main};
  width: ${({ customWidth }) => (customWidth ? customWidth : '400px')};
  height: calc(100vh - ${({ navbarHeight }) => `${navbarHeight}px`});
  top: ${({ navbarHeight }) => `${navbarHeight}px`};
  right: 0;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  overflow: auto;
  z-index: 499;

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 0;
      height: 100%;
      width: 100%;
      overflow: scroll;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0%);
    `}

  ${({ theme }) =>
    theme === 'dark' &&
    css`
      background: ${themeStyles.colors.darkGreys[5].main};
    `}
`

export const StyledDrawerOverlay = styled.div`
  position: fixed;
  background: ${themeStyles.colors.black.main};
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: calc(100vh - 50px);
  top: ${({ navbarHeight }) => `${navbarHeight}px`};
  left: 0;
  opacity: 0;
  transition: 0.5s;
  z-index: -10;
  display: none;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100vh;
      top: 0;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 498;
      opacity: 1;
      display: block;
    `}
`

export const StyledDrawerHeader = styled.div`
  text-align: center;
  color: ${themeStyles.colors.black.main};
  border-bottom: ${({ title }) =>
    title ? `1px solid ${themeStyles.colors.darkGreys[3].main}` : 'none'};
  padding: ${({ title }) => (title ? '1rem 0.5rem' : '0')};
  position: relative;
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};

  ${({ customHeaderStyles }) => customHeaderStyles}

  ${({ theme }) =>
    theme === 'dark' &&
    css`
      color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.darkGreys[3].main};
    `}
`

export const StyledDrawerCloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${TimesBlackIcon}');
  width: 15px;
  height: 15px;
  position: absolute;
  right: ${({ title }) => (title ? '10px' : '26px')};
  top: ${({ title }) => (title ? '50%' : '26px')};
  transform: translateY(-50%);

  ${({ theme }) =>
    theme === 'dark' &&
    css`
      background-image: url('${TimesWhiteIcon}');
    `}
`
export const StyledDrawerBody = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;

  ${({ theme }) =>
    theme === 'dark' &&
    css`
      color: ${themeStyles.colors.white.main};
    `}
`
