import React from 'react'
import PropTypes from 'prop-types'
import { StyledLi, StyledPasswordRequirements } from './styles'
import { useTranslation } from '../../../context/translationProvider'

const PasswordRequirements = (props) => {
  const { password } = props
  const { translate } = useTranslation()

  const requirements = [
    {
      text: translate('register.passwordRequirements.length'),
      regex: /(.{8,})/,
    },
    {
      text: translate('register.passwordRequirements.uppercase'),
      regex: /(?=.*[A-Z])/,
    },
    {
      text: translate('register.passwordRequirements.lowercase'),
      regex: /(?=.*[a-z])/,
    },
    {
      text: translate('register.passwordRequirements.number'),
      regex: /(?=.*[0-9])/,
    },
    {
      text: translate('register.passwordRequirements.specialCharacter'),
      regex: /(?=.*[-><;:'"+?/\\{}|[\]!@#$%^&*.])/,
    },
  ]

  return (
    <StyledPasswordRequirements>
      <span>{translate('register.passwordRequirements.title')}:</span>
      <ul>
        {requirements.map(({ text, regex }, index) => (
          <StyledLi
            key={`requirement-${index}`}
            $isActive={regex.test(password)}
          >
            {text}
          </StyledLi>
        ))}
      </ul>
    </StyledPasswordRequirements>
  )
}

PasswordRequirements.propTypes = {
  password: PropTypes.string,
}

export { PasswordRequirements }
