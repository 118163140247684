import { apiDELETE, apiGET, apiPOST, apiPUT } from '../utils/adapterFunctions'

export const getDepositPaymentProviders = async () => {
  return await apiGET('/payment-providers/deposit')
}

export const getPay4FunDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/pay4fun/url', model)
}

export const getPay4FunGoDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/pay4fungo/url', model)
}

export const getPaylivreDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/paylivre/url', model)
}

export const getDirectaDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/directa/url', model)
}

export const getInovapayWalletDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/inovapay/wallet', model)
}

export const getInovapayGatewayDepositUrl = async (model) => {
  return await apiPOST('/payments/deposit/inovapay/gateway/url', model)
}

export const getGigadatDepositUrl = async (model) => {
  return await apiPOST('/gigadat/deposit/request', model)
}

export const requestDirectaWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/directa/request', model)
}

export const prevalidateWithdrawal = async () => {
  return await apiGET('/payments/withdrawal/prevalidate')
}

export const requestPay4FunWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/pay4fun/request', model)
}

export const requestGigadatWithdrawal = async (model) => {
  return await apiPOST('/gigadat/withdrawal/request', model)
}

export const requestInovapayGatewayWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/inovapay/gateway/request', model)
}

export const requestInovapayWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/inovapay/wallet/request', model)
}

export const getDirectaBanks = async () => {
  return await apiGET('/payments/directa/banks')
}

export const getPendingWithdrawals = async () => {
  const params = { pageNumber: 0, pageSize: 100 }
  return await apiGET('/payments/pending-withdrawals', { params })
}

export const cancelWithdrawals = async (ids) => {
  return await apiDELETE('/payments/pending-withdrawals', {
    data: { transaction_ids: ids },
  })
}

export const requestPay4FunGoPixWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/pay4fungo/url', model)
}

export const requestPagseguroWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/pagseguro/pix', model)
}

export const getInovapayDirectPixData = async (model) => {
  return await apiPOST('/payments/deposit/inovapay/direct/pix', model)
}

export const getPagSeguroData = async (model) => {
  return await apiPOST('/payments/deposit/pagseguro/pix', model)
}

export const getCoinDirectDepositUrl = async (model) => {
  return await apiPOST('/coindirect/deposit/request', model)
}

export const requestPaylivreWithdrawal = async (model) => {
  return await apiPOST('/payments/withdrawal/paylivre/url', model)
}

export const getCryptoCoins = async (model) => {
  return await apiGET('/coindirect/cryptocurrencies', model)
}

export const getPaymentIQData = async (transactionType = 'deposit') => {
  return await apiPOST(`/piq/ui/${transactionType}/request`)
}

export const getDepositCapabilites = async () => {
  return await apiGET('/capabilities/deposit')
}

export const getDirectPixProgress = async (transactionReference) => {
  const params = { reference: transactionReference }
  return await apiGET('/payments/deposit/inovapay/direct/pix', { params })
}

export const getPagSeguroProgress = async (transactionReference) => {
  const params = { reference: transactionReference }
  return await apiGET('/payments/deposit/pagseguro/pix', { params })
}

export const getPaymentDepositLobby = async () => {
  return await apiGET('/lobbies/deposit')
}

export const getPaymentWithdrawalLobby = async () => {
  return await apiGET('/lobbies/withdrawal')
}

export const createBankAccount = async (model) => {
  return await apiPOST('/player/bank-accounts', model)
}

export const getLivenessBankAccount = async (bankAccountId) => {
  const body = { bankAccountId }
  return await apiPOST('/player/bank-accounts/liveness', body)
}

export const getLivenessStatus = async (onboardingId) => {
  return await apiGET(`/liveness/${onboardingId}`)
}

export const getPlayerBankAccountStatus = async () => {
  return await apiGET('/player/bank-accounts', { hasNestedData: false })
}

export const deleteBankAccount = async (id) => {
  return await apiDELETE(`/player/bank-accounts/${id}`)
}

export const updateDefaultBankAccount = async (id) => {
  return await apiPUT(`/player/bank-accounts/${id}/default`)
}

export const requestBankAccountWithdraw = async (body) => {
  return await apiPOST('/payments/v2/withdrawal/pix', body)
}

export const requestDepositV2 = async ({ endpoint, body }) => {
  return await apiPOST(endpoint, body)
}

export const requestDepositStatusV2 = async ({
  endpoint,
  transactionReference,
}) => {
  const params = { reference: transactionReference }
  return await apiGET(endpoint, { params })
}
