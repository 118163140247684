import axios from 'axios'

import { EnvConfig } from '../../config/EnvConfig'

const http = axios.create({
  baseURL: EnvConfig.TEMPORARY_CMS_API_URL,
  timeout: 30000,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

http.interceptors.request.use(
  async (req) => {
    return Promise.resolve(req)
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default http
