export const stringNormalize = (string) => {
  return string
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    .toUpperCase()
    .replace(' ', '')
}

export const nameNormalize = (string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
