import React, { useContext } from 'react'
import { AuthContext } from '../../../context/authProvider'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { FooterLinksDetails, MenuSectionTitle, MenuSectionUl } from './styles'
import { filterVisibleFooterItems } from '../../../dataManipulation/cmsItems'
import { FooterLink } from '../../atoms/footerLink'
import { getLinkRel } from '../../../utils/getLinkRel'
import { useSEOContentVisibility } from '../../../hooks/useSEOContentVisibility'

const FooterMenuMobile = () => {
  const { isLoggedIn } = useContext(AuthContext)
  const { text_links } = useFooterV2ApiData()
  const { filterSEOMenu } = useSEOContentVisibility()

  const filteredSections = text_links
    ?.map((section) => {
      return {
        links: filterVisibleFooterItems(section?.links, isLoggedIn),
        title: section?.title,
      }
    })
    ?.filter((section) => section?.links?.length > 0)

  return (
    <FooterLinksDetails>
      {filteredSections?.map((section, i) => {
        const open = i === 0
        return (
          <details key={`footer-link-section-${i}`} open={open}>
            <summary>
              <MenuSectionTitle>{section?.title}</MenuSectionTitle>
            </summary>
            <MenuSectionUl>
              {filterSEOMenu(section?.links)?.map((linkData, j) => {
                return (
                  <FooterLink
                    url={linkData?.link?.url}
                    title={linkData?.text}
                    target={linkData?.link?.target}
                    rel={getLinkRel(linkData?.link?.target)}
                    key={`footer-link-${j}`}
                  />
                )
              })}
            </MenuSectionUl>
          </details>
        )
      })}
    </FooterLinksDetails>
  )
}

export { FooterMenuMobile }
