import { EnvConfig } from '../../config/EnvConfig'

const turnstileScript = (turnstileRender) => {
  const script = document.createElement('script')
  script.src = EnvConfig.GATSBY_CLOUDFLARE_TURNSTILE_SRC
  script.async = true
  script.defer = true
  script.onload = turnstileRender

  return script
}

export default turnstileScript
