import React, { useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import RefferAFriend from '../../../../../images/refer-a-friend/icon_refer-afriend-signup.svg'
import CouponIcon from '../../../../../images/icon_coupon.svg'
import { Field } from '../../../../molecules/field'
import { FieldDropdown } from '../../../../molecules/fieldDropdown'
import { RecaptchaMessage } from '../../../../molecules/recaptchaMessage'
import { GooglePlacesField } from '../../../../molecules/googlePlacesField'
import { Alert } from '../../../../molecules/alert'
import { Button } from '../../../../atoms/button'
import { FieldLabel } from '../../../../atoms/fieldLabel'
import { If } from '../../../../atoms/if'
import { Text } from '../../../../atoms/text'
import { brandConfig } from '../../../../../config/brandConfig'

import { StyledCol, StyledRecaptchaWrapper, StyledRow } from '../../styles'
import {
  validateCoupon,
  validatePhoneNumber,
} from '../../../../../helpers/validators'
import {
  nameNormalize,
  stringNormalize,
} from '../../../../../utils/stringNormalize'
import {
  BR_MOBILE_MASK,
  BR_PHONE_MASK,
  BR_RESET_MASK,
  INITIAL_MOBILE_MASK,
} from '../../../../../helpers/mask'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import { AuthContext } from '../../../../../context/authProvider'
import { useTranslation } from '../../../../../context/translationProvider'
import { StyledAddressStep } from '../../styles'
import { useTurnstile } from '../../../../../hooks/useTurnstile'
import { RegistrationCheckmarks } from '../../../../molecules/registrationCheckmarks'
import {
  REGISTRATION_STEP_IDS,
  REGISTRATION_STEPS,
} from '../../../../../hooks/useRegistrationSteps'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const AddressStep = (props) => {
  const { accessCountry } = useContext(AuthContext)
  const {
    formProperties,
    onCompleteStep,
    safeSetCountry,
    setMobilePrefix,
    data,
    friendReferrerCode,
    whitelistedCountries,
    loading,
    useGoogleForms,
    country,
    registerTime,
    onInputFocus,
    onInputBlur,
    captchaToken,
    setToken,
    responseError,
    setStepsDone,
  } = props
  const { phoneCodes, mobilePrefix, countries, regions } = data

  const {
    register,
    trigger,
    getValues,
    setValue,
    formState,
    watch,
    setError,
    clearErrors,
  } = formProperties
  const { errors } = formState

  const [mobileMask, setMobileMask] = useState(INITIAL_MOBILE_MASK)
  const [stepTimer] = useState(new Date())
  const { translate } = useTranslation()
  const { initializeTurnstile, captchaTurnstileOn } = useTurnstile()
  const mobileVerificationStep = REGISTRATION_STEPS.findIndex(
    (step) => step.id === REGISTRATION_STEP_IDS.CONFIRM_PHONE
  )
  const isBelongHereEnabled = useFeatureIsOn(
    'registration_show_belong_here_checkmark'
  )

  const onCaptchaVerified = useCallback((data) => {
    setToken(data)
  }, [])

  useEffect(() => {
    watch(['tandc', 'country', 'mobilePrefix', 'mobileNumber'])
  }, [])

  useEffect(() => {
    if (!responseError) return
    setToken(null)
  }, [responseError])

  initializeTurnstile(onCaptchaVerified, captchaToken, captchaToken)

  const handleMobileMask = (event) => {
    if (mobilePrefix === '+55') {
      const { key, target } = event
      const { value } = target

      if (!Number?.isNaN(Number(key))) {
        const mask = value?.length < 14 ? BR_PHONE_MASK : BR_MOBILE_MASK
        setMobileMask(mask)
      } else if (key === 'Unidentified') {
        setMobileMask(BR_MOBILE_MASK)
      } else if (key === 'Backspace' && value?.length === 15) {
        setMobileMask(BR_RESET_MASK)
      }
    } else {
      setMobileMask(INITIAL_MOBILE_MASK)
    }
  }

  const setPromoCodeMask = (e) => {
    e.target.value = stringNormalize(e?.target?.value || '')
  }

  const onMobilePrefixChange = (e) => {
    const code = e.target.value
    setValue('mobilePrefix', code)
    setMobilePrefix(code)
    setStepsDone((state) => ({ ...state, [mobileVerificationStep]: false }))
  }

  const handleCountryChange = async ({ target: { value } }) => {
    const country = countries.find((country) => country.code === value)
    safeSetCountry(country?.name)
  }

  const handleCompleteStep = () => {
    const actualTime = new Date()

    registerTime(
      'third_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
    onCompleteStep([
      'tandc',
      'address',
      'city',
      'state',
      'country',
      'mobilePrefix',
      'mobileNumber',
    ])
  }

  const createGooglePlacesField = (key, fieldsToAffect, aditionalProps) => (
    <GooglePlacesField
      {...aditionalProps}
      whitelistedCountries={whitelistedCountries}
      formProperties={register(key, {
        required: aditionalProps?.required || false,
      })}
      errors={errors}
      label={translate(`userProfile.${key}`)}
      setValue={setValue}
      fieldsToAffect={fieldsToAffect}
      safeSetCountry={safeSetCountry}
      setError={setError}
      clearErrors={clearErrors}
    />
  )

  const createField = (key, maxLength) => {
    return (
      <StyledCol>
        <Field
          autoCompleteOff
          errors={errors}
          label={translate(`userProfile.${key}`)}
          maxLength={maxLength}
          minLength={3}
          onChange={() => trigger(key)}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur(`${key}_input_timer`)}
          formProperties={register(key, { required: true })}
        />
      </StyledCol>
    )
  }

  const createCountryField = () => {
    if (accessCountry !== country?.name) {
      return (
        <FieldDropdown
          autoCompleteOff
          errors={errors}
          label={translate('userProfile.country')}
          onChange={handleCountryChange}
          fieldValue={getValues('countryCode')}
          formProperties={register('countryCode', { required: true })}
          values={countries
            .filter((country) => country.code != 'BR')
            .map((row, i) => (
              <option key={i} value={row.code}>
                {row.translated_name}
              </option>
            ))}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('country_input_timer')}
        />
      )
    }
    return (
      <Field
        autoCompleteOff
        disabled
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('country_input_timer')}
        label={translate('userProfile.country')}
        fieldValue={getValues('country')}
        formProperties={register('country')}
      />
    )
  }

  const fieldsToWatch = [
    'tandc',
    'address',
    'city',
    'state',
    'country',
    'mobilePrefix',
    'mobileNumber',
  ]

  if (isBelongHereEnabled) fieldsToWatch.push('belongHere')

  return (
    <StyledAddressStep>
      <If
        condition={
          country?.code?.toUpperCase() !== 'BR' &&
          country?.currency_list?.length > 1
        }
        render={() => (
          <FieldDropdown
            autoCompleteOff
            errors={errors}
            label={translate('userProfile.currency')}
            formProperties={register('currency', { required: true })}
            removeFirstOption
            values={country?.currency_list.map((currency, i) => (
              <option value={currency.short_code} key={`currency-${i}`}>
                {currency.name} - ({currency.symbol || currency.short_code})
              </option>
            ))}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('currency_input_timer')}
          />
        )}
      />
      <FieldLabel name="mobilePrefix">
        {translate('userProfile.mobileNumber')}
      </FieldLabel>
      <StyledRow>
        <StyledCol width="32%">
          <FieldDropdown
            autoCompleteOff
            errors={errors}
            fieldValue={getValues('mobilePrefix')}
            formProperties={register('mobilePrefix', { required: true })}
            onChange={onMobilePrefixChange}
            values={[...phoneCodes, '+385'].map((row, i) => (
              <option key={i} value={row}>
                {row}
              </option>
            ))}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('mobile_input_timer')}
          />
        </StyledCol>
        <StyledCol width="68%">
          <Field
            onKeyDown={handleMobileMask}
            mask={mobileMask}
            maxLength="32"
            autoCompleteOff
            type="tel"
            errors={errors}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('mobile_input_timer')}
            defaultValue={getValues('mobileNumber')}
            formProperties={register('mobileNumber', {
              required: true,
              validate: (value) => validatePhoneNumber(value, mobilePrefix),
            })}
          />
        </StyledCol>
      </StyledRow>

      <If
        condition={useGoogleForms}
        render={() => (
          <>
            {createGooglePlacesField(
              'address',
              [
                'street_number',
                'route',
                'postal_code',
                'postal_code_suffix',
                'locality',
                'country',
                'administrative_area_level_1',
                'administrative_area_level_2',
              ],
              {
                required: true,
                description: translate('register.startTyping'),
              }
            )}
            {createGooglePlacesField('city', [
              'postal_code',
              'postal_code_suffix',
              'locality',
              'country',
              'administrative_area_level_1',
              'administrative_area_level_2',
            ])}
          </>
        )}
        renderElse={() => (
          <>
            <StyledRow>{createField('address', 128)}</StyledRow>
            <StyledRow>{createField('city', 90)}</StyledRow>
          </>
        )}
      />

      <FieldDropdown
        autoCompleteOff
        errors={errors}
        label={translate('userProfile.state')}
        fieldValue={getValues('state')}
        onChange={(e) => {
          setValue('state', e.target.value)
          clearErrors('state')
        }}
        formProperties={register('state', {
          required: regions?.length > 0,
        })}
        values={regions.map((row, i) => (
          <option key={i} value={nameNormalize(row.name)}>
            {row.name}
          </option>
        ))}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('state_input_timer')}
      />

      {createCountryField()}
      <If
        condition={!friendReferrerCode}
        render={() => (
          <Field
            errors={errors}
            showErrorMsg
            label={translate('userProfile.couponCode')}
            inputSuffix={<img src={CouponIcon} alt="Coupon" />}
            onChange={setPromoCodeMask}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('couponCode_input_timer')}
            maxLength="8"
            fieldValue={getValues('couponCode')}
            formProperties={register('couponCode', {
              validate: (value) =>
                validateCoupon(value, translate('promoCode.error.notExist')),
            })}
          />
        )}
        renderElse={() => (
          <Alert
            icon={RefferAFriend}
            description={translate('referAFriend.register.referWarn')}
          />
        )}
      />

      <RegistrationCheckmarks register={register} setValue={setValue} />

      <Text style={{ textAlign: 'center' }}>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('common.responsibleGaming'),
          }}
        />
      </Text>

      <div className="checkbox">
        <div id="turnstilCaptcha"></div>
      </div>

      <Button
        id="joinUs"
        expand
        type="submit"
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          loading ||
          formSubmitIsDisabled(fieldsToWatch, getValues, errors) ||
          (!captchaToken && captchaTurnstileOn)
        }
        onClick={handleCompleteStep}
        loading={loading}
      >
        {translate('register.next')}
      </Button>
      <If
        condition={loading}
        render={() => (
          <Text verticalPadding textAlign="left" theme="success">
            {translate('register.creatingMessage')}
          </Text>
        )}
      />
      <StyledRecaptchaWrapper>
        <RecaptchaMessage />
      </StyledRecaptchaWrapper>
    </StyledAddressStep>
  )
}

AddressStep.propTypes = {
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    trigger: PropTypes.func,
    getValues: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    clearErrors: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  setMobilePrefix: PropTypes.func,
  data: PropTypes.shape({
    mobilePrefix: PropTypes.string,
    phoneCodes: PropTypes.array,
    countries: PropTypes.array,
    regions: PropTypes.array,
  }),
  responseError: PropTypes.shape({
    message: PropTypes.string,
    messageCode: PropTypes.number,
  }),
  country: PropTypes.object,
  onCompleteStep: PropTypes.func,
  safeSetCountry: PropTypes.func,
  loading: PropTypes.bool,
  useGoogleForms: PropTypes.bool,
  friendReferrerCode: PropTypes.string,
  whitelistedCountries: PropTypes.string,
  registerTime: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  captchaToken: PropTypes.string,
  setToken: PropTypes.func,
  setStepsDone: PropTypes.func,
}

export { AddressStep }
