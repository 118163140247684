import styled from 'styled-components'

import { themeMedia } from '../../../styles/theme'

export const FormStyleWrapper = styled.div`
  > div.row-fields:first-of-type {
    display: grid;
    grid-template-columns: 1fr;

    ${themeMedia.desktop`
        grid-template-columns: 2fr 1fr;
        gap: 18px;
    `}
  }

  > div.row-fields:nth-of-type(2) {
    display: grid;
    grid-template-columns: 1fr;

    ${themeMedia.desktop`
        grid-template-columns: 1fr 1fr;
        gap: 18px;
    `}
  }
`
