import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledDrawerWrapper,
  StyledDrawerOverlay,
  StyledDrawerHeader,
  StyledDrawerCloseButton,
  StyledDrawerBody,
} from './styles'
import { isBrowser } from '../../../utils/generic'
import useResizeListener from '../../../hooks/useResizeListener'

const Drawer = ({
  customWidth,
  isOpen,
  title,
  onClose,
  children,
  theme,
  isMobile,
  customHeaderStyles,
  id,
}) => {
  const [navbarHeight, setNavbarHeight] = useState(50)
  useEffect(() => {
    if (isBrowser()) {
      isOpen
        ? document.body.classList.add('overflow-hidden')
        : document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  useResizeListener(() => {
    const header = document.querySelector('header')
    setNavbarHeight(header?.clientHeight || 50)
  })

  return (
    <>
      <StyledDrawerOverlay
        isOpen={isOpen}
        onClick={onClose}
        navbarHeight={navbarHeight}
        isMobile={isMobile}
      />
      <StyledDrawerWrapper
        isOpen={isOpen}
        navbarHeight={navbarHeight}
        theme={theme}
        customWidth={customWidth}
        isMobile={isMobile}
        id={id}
      >
        <StyledDrawerHeader
          theme={theme}
          title={title}
          customHeaderStyles={customHeaderStyles}
        >
          {title}
          <StyledDrawerCloseButton
            hasTitle={!!title}
            onClick={onClose}
            theme={theme}
          />
        </StyledDrawerHeader>
        <StyledDrawerBody navbarHeight={navbarHeight} theme={theme}>
          {children}
        </StyledDrawerBody>
      </StyledDrawerWrapper>
    </>
  )
}

Drawer.defaultProps = {
  title: '',
  isOpen: false,
  onClose: null,
  footer: null,
  theme: 'light',
  customWidth: null,
  isMobile: false,
  customHeaderStyles: null,
}

Drawer.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.node,
  theme: PropTypes.string,
  customWidth: PropTypes.string,
  isMobile: PropTypes.bool,
  customHeaderStyles: PropTypes.object,
}

export { Drawer }
