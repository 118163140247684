import React from 'react'
import styled from 'styled-components'

import { If } from '../../atomic-components/atoms/if'
import { Container } from '../../atomic-components/atoms/container'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'
import { getLinkRel } from '../../utils/getLinkRel'

const LogoWrapper = styled.div`
  text-align: center;
  padding: 2rem 0;
`

const FooterLogo = () => {
  const { logoArea } = useFooterApiData()

  if (!logoArea.description || !logoArea.link || !logoArea.image) return null

  return (
    <div>
      <Container>
        <LogoWrapper>
          <If
            condition={logoArea.link.url}
            render={() => (
              <a
                href={logoArea.link.url}
                title={`${logoArea.description}`}
                target={`${logoArea.link.target}`}
                rel={getLinkRel(logoArea.link.target)}
              >
                <img
                  width={logoArea.image.width}
                  height={logoArea.image.height}
                  loading="lazy"
                  src={`${logoArea.image.url}`}
                  alt={`${logoArea.description}`}
                />
              </a>
            )}
            renderElse={() => (
              <img
                width={logoArea.image.width}
                height={logoArea.image.height}
                loading="lazy"
                src={`${logoArea.image.url}`}
                alt={`${logoArea.description}`}
              />
            )}
          />
        </LogoWrapper>
      </Container>
    </div>
  )
}

export default FooterLogo
