import React from 'react'
import PropTypes from 'prop-types'

import { CarouselButtons } from '../../atoms/carouselButtons'
import { If } from '../../atoms/if'
import { PaginationDots } from '../../atoms/paginationDots'
import { ResponsiveImage } from '../../atoms/responsiveImage'
import { Text, Title } from '../../atoms/text'
import {
  StyledDesktopCarouselWrapper,
  StyledImageWrapper,
  StyledSlide,
  StyledSlidesOuterWrapper,
  StyledSlidesWrapper,
  StyledTextContainer,
  StyledWaveTextContainer,
} from './styles'
import { Wave } from '../../atoms/wave'

const DesktopFormCarousel = (props) => {
  const { slides, index, onNext, onPrevious } = props
  const { length } = slides

  return (
    <StyledDesktopCarouselWrapper>
      <CarouselButtons
        showButtons={slides.length > 1}
        onNext={onNext}
        onPrevious={onPrevious}
        overflow="hidden"
      >
        <StyledSlidesOuterWrapper>
          <StyledSlidesWrapper length={length} index={index}>
            {slides.map((slide, banner_i) => (
              <StyledSlide
                key={`banner-slide-${banner_i}`}
                backgroundColor={slide.background_color}
                length={length}
                isShown={banner_i == index}
              >
                <StyledImageWrapper>
                  <ResponsiveImage
                    image={slide.background_image}
                    loading={banner_i <= index + 1 ? 'eager' : 'lazy'}
                    big
                  />
                </StyledImageWrapper>
                <StyledWaveTextContainer
                  backgroundColor={slide.background_color}
                >
                  <Wave direction="horizontal" color={slide.background_color} />
                  <StyledTextContainer>
                    <Title
                      tagType={slide.header_tag}
                      style={{
                        color: slide.text_color,
                        marginBottom: 10,
                        textAlign: 'left',
                        fontSize: 50,
                      }}
                    >
                      {slide.header_text}
                    </Title>
                    <If
                      condition={slide.sub_text}
                      render={() => (
                        <Text style={{ color: slide.text_color }}>
                          {slide.sub_text}
                        </Text>
                      )}
                    />
                  </StyledTextContainer>
                </StyledWaveTextContainer>
              </StyledSlide>
            ))}
          </StyledSlidesWrapper>
        </StyledSlidesOuterWrapper>
      </CarouselButtons>

      <PaginationDots length={length} index={index} />
    </StyledDesktopCarouselWrapper>
  )
}

DesktopFormCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      background_color: PropTypes.string,
      header_tag: PropTypes.string,
      header_text: PropTypes.string,
      sub_text: PropTypes.string,
      text_color: PropTypes.string,
      cta_logged_out: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      cta_logged_in: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      background_image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ),
  index: PropTypes.number,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export { DesktopFormCarousel }
