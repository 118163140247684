import React, { createContext, useContext, useMemo, useReducer } from 'react'

const ModalContext = createContext()

const ActionTypes = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER',
}

const drawerReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_DRAWER: {
      return {
        ...state,
        drawerStatus: true,
      }
    }
    case ActionTypes.CLOSE_DRAWER: {
      return {
        ...state,
        drawerStatus: false,
      }
    }

    default:
      throw new Error(`Action is not supported: ${action.type}`)
  }
}

const initialState = {
  drawerStatus: false,
}

export const DrawerProvider = (props) => {
  const [state, dispatch] = useReducer(drawerReducer, initialState)

  const value = useMemo(() => ({ state, dispatch }), [state])

  return <ModalContext.Provider value={value} {...props} />
}

export const usePaymentDrawerContext = () => {
  const context = useContext(ModalContext)

  if (!context) return {}

  const { state, dispatch } = context
  const { drawerStatus } = state

  const open = () => {
    dispatch({ type: ActionTypes.OPEN_DRAWER })
  }

  const close = () => {
    dispatch({ type: ActionTypes.CLOSE_DRAWER })
  }

  return { open, close, isOpen: drawerStatus }
}
