import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledLabel } from './styles'

const FieldLabel = (props) => {
  const { children, name } = props

  if (!children) return <></>
  return <StyledLabel htmlFor={name}>{children}</StyledLabel>
}

FieldLabel.defaultProps = {
  children: null,
  name: 'name',
}

FieldLabel.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
}

export { FieldLabel }
