import styled, { css } from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.reduceBottomMargin ? '0.5em' : '1em')};

  ${({ noBottomMargin }) =>
    noBottomMargin &&
    css`
      margin-bottom: 0 !important;
    `}
`

export const StyledScrollTo = styled.div`
  position: absolute;
  top: -50px;
`
