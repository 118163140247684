import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const StyledDescription = styled.small`
  font-family: ${brandConfig.fonts.text.small.family};
  font-weight: ${brandConfig.fonts.text.small.weight};
  font-size: 14px;
  color: ${themeStyles.colors.lightGreys[4].main};
  padding-top: 0.2em;

  ${(props) =>
    props.error &&
    `
      padding-top: 0;
      line-height: normal;
      color: ${themeStyles.colors.error.main};
    `}

  ${({ theme }) => {
    switch (theme) {
      case 'warn':
        return `color: ${themeStyles.colors.defaultColors.orange[450]}`
      default:
        return ''
    }
  }}
`
