import organicLandingPages from './organicLandingPages'

export const isGoogleOrganic = function () {
  const getLocation = function (url) {
    const location = window.document.createElement('a')
    location.href = url
    return location
  }

  const isOrganicLandingPage = function () {
    let result = process.env.GATSBY_MY_AFF_BRANDED
    organicLandingPages.forEach(function ({ route, includeSubPages }) {
      if (
        ((includeSubPages?.toLowerCase() === 'true' ||
          includeSubPages === true) &&
          document.location.href?.includes(route)) ||
        ((includeSubPages?.toLowerCase() === 'false' ||
          includeSubPages === false) &&
          document.location.href === route)
      ) {
        result = process.env.GATSBY_MY_AFF_ORGANIC
      }
    })

    return result
  }

  const isGoogle = function () {
    const referrerLocation = getLocation(document.referrer)
    if (
      referrerLocation.hostname.indexOf('www.google.') < 0 ||
      (referrerLocation.pathname !== '' &&
        referrerLocation.pathname !== '/' &&
        referrerLocation.pathname !== 'search' &&
        referrerLocation.pathname !== '/search' &&
        referrerLocation.pathname !== 'url' &&
        referrerLocation.pathname !== '/url')
    ) {
      return false
    }

    return true
  }

  const isBing = function () {
    const referrerLocation = getLocation(document.referrer)

    return !(referrerLocation.hostname.indexOf('www.bing.') < 0)
  }

  const isYahoo = function () {
    const referrerLocation = getLocation(document.referrer)

    return !!referrerLocation.hostname.includes('search.yahoo.com')
  }

  const urlLocation = getLocation(document.location.href)
  if (urlLocation.search.match(/(^\?|&)(gclid|utm_.+)=/gi)) {
    return false
  }

  const isFromSearchEngine = isGoogle() || isBing() || isYahoo()

  return isFromSearchEngine && isOrganicLandingPage()
}
