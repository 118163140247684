import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import EmailIcon from '../../../../../images/icon_email_outline_gray.svg'
import { VerifyEvery60SecondsContent } from '../../../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../../../context/translationProvider'
import {
  doSendValidationEmail,
  doValidateEmail,
} from '../../../../../adapters/auth'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { toast } from '../../../../atoms'
import { removeNationalIdMask } from '../../../../../helpers/mask'

const EmailVerificationStep = (props) => {
  const { getValues, onCompleteStep, stepIsDone } = props
  const { translate } = useTranslation()
  const duration = useFeatureValue('registration_timer_to_resend')
  const email = getValues('email')

  const onResend = async () => {
    if (stepIsDone) return
    const email = getValues('email')
    const languageCode = getValues('languageCode')
    const national_id = getValues('national_id')

    const { ok } = await doSendValidationEmail(
      email,
      removeNationalIdMask(national_id),
      languageCode
    )
    if (!ok) {
      toast.error(translate('registration.error.email.sending'))
    }
  }

  const onInputFieldDone = async (text, setIsLoading) => {
    setIsLoading(true)
    const email = getValues('email')
    const national_id = getValues('national_id')

    const { ok } = await doValidateEmail(
      email,
      removeNationalIdMask(national_id),
      text
    )
    if (!ok) {
      return toast.error(translate('registration.error.email.validating'))
    }

    setIsLoading(false)
    onCompleteStep()
  }

  useEffect(() => {
    if (stepIsDone) {
      onCompleteStep()
    }
  }, [])

  return (
    <VerifyEvery60SecondsContent
      useResendOnStart
      onResend={onResend}
      icon={EmailIcon}
      onInputFieldDone={onInputFieldDone}
      duration={duration}
      ctaText={translate('verification.email.buttonText')}
      title={translate('verification.email.title')}
      description={translate('verification.email.description').replaceAll(
        '{email}',
        email
      )}
      countdownTextTicking={translate('verification.email.60seconds')}
      countdownTextPaused={translate('verification.email.clickBelow')}
    />
  )
}

EmailVerificationStep.defaultProps = {
  getValues: () => {},
  onCompleteStep: () => {},
  stepIsDone: false,
}

EmailVerificationStep.propTypes = {
  getValues: PropTypes.func,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,
}

export { EmailVerificationStep }
