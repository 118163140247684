import React from 'react'
import AuthCode from 'react-auth-code-input'
import PropTypes from 'prop-types'

import { StyledVerificationWrapper } from './styles'
import { FieldLabel } from '../../atoms/fieldLabel'

const FieldVerificationCode = (props) => {
  const {
    allowedCharacters,
    autoFocus,
    length,
    disabled,
    placeholder,
    containerClass,
    label,
    onChange,
  } = props

  return (
    <div>
      {label && <FieldLabel>{label}</FieldLabel>}
      <StyledVerificationWrapper className={containerClass}>
        <AuthCode
          allowedCharacters={allowedCharacters}
          autoFocus={autoFocus}
          length={length}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </StyledVerificationWrapper>
    </div>
  )
}

FieldVerificationCode.defaultProps = {
  allowedCharacters: 'numeric',
  autoFocus: true,
  length: 6,
  disabled: false,
  placeholder: '',
  containerClass: '',
  label: null,
  onChange: () => {},
}

FieldVerificationCode.propTypes = {
  allowedCharacters: PropTypes.oneOf(['alpha', 'alphanumeric', 'numeric']),
  autoFocus: PropTypes.boolean,
  length: PropTypes.number,
  disabled: PropTypes.boolean,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export { FieldVerificationCode }
