import styled, { css } from 'styled-components'
import WhiteChevronLeft from '../../../images/icon_chevron_left_white.svg'
import BlackChevronLeft from '../../../images/icon_chevron_left.svg'
import { themeStyles } from '../../../styles/theme'

export const StyledBackButton = styled.button`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;

  padding: 0;
  margin: 0;

  ${(props) =>
    props.whiteBackground ?
      css`
          background-image: url('${BlackChevronLeft}');
          background-color: ${themeStyles.colors.lightGreys[1].main};
        ` :
      css`
          background-image: url('${WhiteChevronLeft}');
          background-color: ${themeStyles.colors.darkGreys[4].main};
        `}

  ${({ top, left }) => {
    if (!top && !left) return

    return css`
      position: absolute;
      top: ${top};
      left: ${left};
    `
  }}
`
