import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const inputBorderSize = '2px'

export const StyledVerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  div {
    width: 100%;
    input {
      width: 48px;
      height: 48px;
      font-size: ${brandConfig.fonts.text.input.size.desktop};
      font-family: ${brandConfig.fonts.text.input.family};
      line-height: initial;
      color: ${themeStyles.colors.black.main};
      border: ${inputBorderSize} solid ${themeStyles.colors.lightGreys[1].main};
      border-radius: 6px;
      outline: none !important;
      text-align: center;
      box-sizing: border-box;
      margin-right: 10px;
      &:focus-visible {
        border: ${inputBorderSize} solid
          ${themeStyles.colors.lightGreys[1].main};
      }
    }
  }
`
