import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const StyledRecaptchaMessage = styled.p`
  margin: 3rem auto 0.5rem;
  font-size: ${brandConfig.fonts.text.paragraph.size.mobile};

  ${themeMedia.tablet`
  margin: 6rem auto 0 auto;
  width: 70%;
  `};
`
