import React from 'react'
import PropTypes from 'prop-types'
import { StyledImage } from './styles'

const Image = (props) => {
  const { loading, style } = props
  if (props.menuItem) {
    const { menuItem, onClick, className } = props
    return (
      <StyledImage
        onClick={onClick}
        src={menuItem.image.url}
        alt={menuItem.image.alt || menuItem.name}
        aria-label={menuItem.name}
        width={menuItem.image.width}
        height={menuItem.image.height}
        className={`${menuItem.className} ${className}`}
        loading={loading}
        style={style}
      />
    )
  }

  const { size, height, width, url, alt, className, onClick } = props

  return (
    <img
      height={size ?? height}
      width={size ?? width}
      src={url}
      alt={alt}
      className={className}
      onClick={onClick}
      loading={loading}
      style={style}
    />
  )
}

Image.defaultProps = {
  menuItem: null,
  loading: 'lazy',
  style: null,
  size: null,
  width: null,
  height: null,
  url: null,
  alt: null,
  onClick: () => {},
}

Image.propTypes = {
  menuItem: PropTypes.object,
  loading: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export { Image }
