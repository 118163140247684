import { formatCurrency } from '../../../utils/currencyFormatting'

const ENV_LOCALE = process.env.GATSBY_INTL_LOCALE

const FormatAmount = ({
  amount,
  shortCode = 'USD',
  currencySymbol,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  useGrouping = true,
}) => {
  let locale
  switch (shortCode) {
    case 'CLP':
      locale = 'es-cl'
      break
    case 'PEN':
      locale = 'es-pe'
      break
    default:
      locale = ENV_LOCALE
      break
  }
  const options = {
    shortCode,
    currencySymbol,
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
    locale,
  }

  return formatCurrency(amount, options, false)
}

export { FormatAmount }
