import { FB_USER_QUERY_PARAM } from '../utils/constants'

export const isFbUser = () => {
  if (typeof window === 'undefined') return false
  const params = new URLSearchParams(window.location.search)
  const utmSource = params.get('utm_source')
  return !!(utmSource && utmSource === 'meta')
}

export const updateAllHrefsIfFbUser = (text) => {
  if (text) {
    if (isFbUser()) {
      const regex = /href="([^"]+)"/g
      return text.replace(regex, (_, p1) => {
        return `href="${p1}${FB_USER_QUERY_PARAM}"`
      })
    }
    return text
  } else {
    return ''
  }
}
