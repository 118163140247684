import React from 'react'
import { ToastContainer as ToastWrapper } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const toastZIndex = 1000010

const ToastContainer = () => {
  return <ToastWrapper style={{ zIndex: toastZIndex }} />
}

export { ToastContainer, toastZIndex }
