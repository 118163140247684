import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { StyledEmail, StyledSectionHeader } from '../../styles'
import { Button } from '../../../../atoms/button'
import useSlugs from '../../../../../hooks/useSlugs'
import { useTranslation } from '../../../../../context/translationProvider'

const ActivationStep = (props) => {
  const { email } = props
  const [loginSlug] = useSlugs(['login'])
  const { translate } = useTranslation()

  return (
    <section>
      <StyledSectionHeader>
        <span>{translate('register.activateYourAccount')}</span>
      </StyledSectionHeader>
      <div>
        <span>{translate('register.weSentAnEmail')}</span>
      </div>
      <StyledEmail>{email}</StyledEmail>

      <Button expand onClick={() => navigate(`/${loginSlug}/`)}>
        {translate('register.done')}
      </Button>
    </section>
  )
}

ActivationStep.propTypes = {
  email: PropTypes.string,
}

export { ActivationStep }
