export const msToMinutesAndSeconds = (ms) => {
  const timeInSeconds = ms / 1000

  if (timeInSeconds < 60) return `${timeInSeconds} seconds`

  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = Math.floor(timeInSeconds % 60)

  return `${minutes} minutes and ${seconds} seconds`
}
