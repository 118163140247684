import React, { useContext } from 'react'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { FooterLink } from '../../atoms/footerLink'
import {
  MenuSection,
  MenuSectionTitle,
  MenuSectionUl,
  MenuWrapper,
} from './styles'
import { AuthContext } from '../../../context/authProvider'
import { filterVisibleFooterItems } from '../../../dataManipulation/cmsItems'
import { getLinkRel } from '../../../utils/getLinkRel'
import { useSEOContentVisibility } from '../../../hooks/useSEOContentVisibility'

const FooterMenuDesktop = () => {
  const { text_links } = useFooterV2ApiData()
  const { isLoggedIn } = useContext(AuthContext)
  const { filterSEOMenu } = useSEOContentVisibility()

  const filteredSections = text_links
    ?.map((section) => {
      return {
        links: filterVisibleFooterItems(section?.links, isLoggedIn),
        title: section?.title,
      }
    })
    ?.filter((section) => section?.links?.length > 0)

  return (
    <MenuWrapper>
      {filteredSections?.map((section, i) => {
        return (
          <MenuSection key={`footer-link-section-${i}`}>
            <MenuSectionTitle>{section?.title}</MenuSectionTitle>
            <MenuSectionUl
              itemScope
              itemType="http://schema.org/SiteNavigationElement"
            >
              {filterSEOMenu(section?.links)?.map((linkData, j) => (
                <FooterLink
                  url={linkData?.link?.url}
                  title={linkData?.text}
                  target={linkData?.link?.target}
                  rel={getLinkRel(linkData?.link?.target)}
                  index={j}
                  key={`footer-link-${j}`}
                />
              ))}
            </MenuSectionUl>
          </MenuSection>
        )
      })}
    </MenuWrapper>
  )
}
export { FooterMenuDesktop }
