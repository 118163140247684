import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledContent = styled.div`
  padding: 2rem 1rem;

  ${({ isDrawer }) =>
    isDrawer ?
      css`
          color: ${themeStyles.colors.black.main};
          width: 100%;
          box-sizing: border-box;
        ` :
      null};

  h3 {
    margin-bottom: 0.5em;
  }
`

export const StyledForm = styled.form`
  margin: 2rem 0 2rem 0;
`

export const StyledRemindMeLater = styled.p`
  color: ${themeStyles.colors.black.main};
  text-align: center;
  margin: 2rem 0 0.5rem 0;
  display: block;
  text-decoration: underline;
  cursor: pointer;
`
