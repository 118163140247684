import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

const horizontalDesktopContentPadding = '2em'
const horizontalMobileContentPadding = '0.75em'

export const StyledContentOutterWrapper = styled.div`
  padding: 0 ${horizontalDesktopContentPadding};
  background: ${(props) => props.background ?? themeStyles.colors.black.main};
  overflow: hidden;
  margin-top: ${(props) => (props.marginTop ? '1.875rem' : 0)};

  ${themeMedia.maxTablet`
    padding: 0 ${horizontalMobileContentPadding};
  `};

  ${themeMedia.desktop`
    padding-bottom: 4em;

    ${({ hidePadding }) =>
      hidePadding &&
      css`
        padding-bottom: 0em;
      `}
  `}

  ${({ theme }) =>
    theme === 'light' &&
    css`
      background: ${themeStyles.colors.white.main};
    `}

  ${({ verticalPadding }) =>
    !!verticalPadding &&
    css`
      padding-top: ${verticalPadding} !important;
      padding-bottom: ${verticalPadding} !important;
    `}
`

export const StyledContentInnerWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => (props.small ? 800 : 1200)}px;
  margin: auto;
  display: flex;
  flex-direction: column;

  gap: ${(props) => props.gapMobile ?? props.gap};

  ${themeMedia.desktop`
    gap: ${(props) => props.gap};
  `}
`

export const StyledContentUnWrapper = styled.div`
  position: relative;
  width: calc(100% + ${horizontalDesktopContentPadding} * 2);
  left: -${horizontalDesktopContentPadding};

  ${(props) =>
    props.addPaddingToChild &&
    css`
      > div {
        padding: 0 ${horizontalDesktopContentPadding};
      }
    `}

  ${(props) =>
    props.addPaddingToClass &&
    css`
      .undo-the-unwrapper {
        padding: 0 ${horizontalDesktopContentPadding};
      }
    `}

  ${themeMedia.maxTablet`
    width: calc(100% + ${horizontalMobileContentPadding} * 2);
    left: -${horizontalMobileContentPadding};
  
    ${(props) =>
      props.addPaddingToChild &&
      css`
        > div {
          padding: 0 ${horizontalMobileContentPadding};
        }
      `}

    ${(props) =>
      props.addPaddingToClass &&
      css`
        .undo-the-unwrapper {
          padding: 0 ${horizontalMobileContentPadding};
        }
      `}
  `}
`
