import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { cpfValidator } from '../../../utils/cpfValidation'
import { CPF_MASK, removeCpfMask } from '../../../helpers/mask'
import storageService from '../../../services/storageService'
import { updateNationalId } from '../../../adapters/user'
import { Field } from '../field'
import { Button } from '../../atoms/button'
import { If } from '../../atoms/if'
import { Text, Title } from '../../atoms/text'
import { useTranslation } from '../../../context/translationProvider'
import { StyledContent, StyledForm, StyledRemindMeLater } from './styles'
import { EnvConfig } from '../../../config/EnvConfig'

const CpfModalContent = (props) => {
  const { onClose, onSubmit, isDrawer, hideRemindMeLater } = props
  const { translate } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const onFormSubmit = async (data) => {
    setDisabled(true)
    const response = await updateNationalId({
      national_id: removeCpfMask(data.national_id),
    })

    if (response.ok) {
      storageService.setValue('cpf:reminder', 'false')
      if (onSubmit) {
        onSubmit()
      }
    }

    if (!response.ok) {
      switch (response.error.messageCode) {
        case 211:
          setError('national_id', {
            message: (
              <span>
                {`${translate('cpf.register.invalidValue')} `}
                <a
                  className="error"
                  href={`mailto:${EnvConfig.GATSBY_SUPPORT_EMAIL}`}
                >
                  {EnvConfig.GATSBY_SUPPORT_EMAIL}
                </a>
              </span>
            ),
          })
          break
        default:
          break
      }
    }

    setDisabled(false)
    onClose()
  }

  return (
    <StyledContent isDrawer={isDrawer}>
      <Title level={3}>{translate('cpf.collect.title')}</Title>
      <StyledForm
        isDrawer={isDrawer}
        id="cpf-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Field
          showErrorMsg
          type="tel"
          label={'CPF'}
          mask={CPF_MASK}
          placeholder="123.456.789-10"
          maxLength={isDrawer ? null : '14'}
          errors={errors}
          hasBeenSubmitted={hasBeenSubmitted}
          formProperties={register('national_id', {
            required: true,
            validate: (value) =>
              cpfValidator(value) || translate('cpf.register.invalidFormat'),
          })}
        />

        <Button
          expand={!!isDrawer}
          disabled={disabled}
          onClick={() => setHasBeenSubmitted(true)}
          id="btnConfirmNationalID"
        >
          {translate('cpf.collect.confirm')}
        </Button>
      </StyledForm>

      <Title level={3}>{translate('cpf.collect.explanation.title')}</Title>
      <Text>{translate('cpf.collect.explanation.description')}</Text>
      <If
        condition={!hideRemindMeLater}
        render={() => (
          <StyledRemindMeLater onClick={onClose} id="linkRemindLater">
            {translate('cpf.remindLater')}
          </StyledRemindMeLater>
        )}
      />
    </StyledContent>
  )
}

CpfModalContent.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  hideRemindMeLater: false,
}

CpfModalContent.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  hideRemindMeLater: PropTypes.bool,
}

export { CpfModalContent }
