import { cpfValidator } from '../utils/cpfValidation'

export const validateName = (value) => {
  return /^(?=\S)(?=(?:\S+\s){1,}\S{2,}$).+/.test(value)
}

export const validateConfirmPassword = (
  event,
  confirmPassword,
  setError,
  clearErrors,
  errorMessage
) => {
  if (event.target.value !== confirmPassword || event.target.value) {
    setError('confirmPassword', { message: errorMessage })
    return false
  }

  clearErrors('confirmPassword')
  return true
}

export const validatePhoneNumber = (value, mobilePrefix) => {
  return mobilePrefix !== '+55' || value?.length >= 14
}

export const validateNationalId = (value, country, errorMessage) => {
  if (!country?.national_id_required || country?.id !== 31) {
    return true
  }

  return cpfValidator(value) || errorMessage
}

export const validateCoupon = (coupon, errorMessage) => {
  if (!coupon) {
    return true
  }
  return (coupon.length >= 4 && coupon.length <= 8) || errorMessage
}

export const validateDate = (date) => {
  try {
    const minAgeRequirement = 18
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - minAgeRequirement)

    return date <= currentDate
  } catch (_) {
    return false
  }
}

export const dateRegex = /(0[1-9]|[1,2][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}/
