import styled, { css } from 'styled-components'

import { themeMedia, themeStyles } from '../../../styles/theme'
import {
  defaultCarouselGap,
  getSlideTransformCss,
  getSlideWidthCss,
  getSlideWrapperWidthCss,
} from '../../../utils/cssFunctions'

const carouselHeight = '620px'

export const StyledDesktopCarouselWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${carouselHeight};
  max-height: 80vh;
  width: 100%;
  ${themeMedia.tablet`max-width:40vw;`}
  ${themeMedia.desktop`max-width:490px;`}
`

export const StyledSlidesOuterWrapper = styled.div`
  overflow: hidden;
`

export const StyledSlidesWrapper = styled.div`
  transition-duration: 500ms;
  position: relative;
  display: flex;
  overflow: hidden;

  height: ${carouselHeight};
  max-height: 80vh;
  gap: ${defaultCarouselGap};
  ${getSlideWrapperWidthCss}
  ${getSlideTransformCss}
`

export const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${themeStyles.colors.white.main};
  border-radius: 8px;
  padding: 0;
  opacity: ${(props) => (props.isShown ? 1 : 0.4)};
  transition: 0.5s opacity;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  ${getSlideWidthCss}
`
export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props?.backgroundColor};
  padding: 1.25em 2em 1em;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`
export const StyledImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  img,
  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`
export const StyledWaveTextContainer = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`
