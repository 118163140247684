import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ContentWrapper } from '../../atoms/contentWrapper'
import {
  SeoContentCollapseExpand,
  SeoContentHeader,
  SeoHeaderContainer,
  StyledContainer,
} from './styles'
import { useSeoPagesApiData } from '../../../hooks/graphqlStaticQuery/useSeoPagesApiData'
import { If } from '../../atoms/if'
import collapseIcon from '../../../images/icon_collapse_white.svg'
import expandIcon from '../../../images/icon_expand_white.svg'

const SeoContent = ({ seoPathParams, customLocation }) => {
  const [collapsed, setCollapsed] = useState(true)
  const { seoContent, collapsable = false } = useSeoPagesApiData(
    false,
    seoPathParams,
    customLocation
  )

  if (!seoContent) {
    return <div key={'empty-seo-content'} />
  }

  const extractH1fromSeoHtml = (seoContent, collapsable) => {
    if (!collapsable) return { content: seoContent, h1Text: '' }
    const matchFirstH1 = /<h1>(.+)<\/h1>/
    const matchFirstH2 = /<h2>(.+)<\/h2>/
    const headerTextMatch =
      seoContent?.match(matchFirstH1) ?? seoContent?.match(matchFirstH2)
    const h1Text = headerTextMatch?.[1]
    const content = seoContent?.replace(headerTextMatch?.[0], '')

    return { content, h1Text }
  }

  const { content, h1Text } = extractH1fromSeoHtml(seoContent, collapsable)

  const getContentClassName = () => {
    if (!collapsable) return ''
    if (collapsed) return 'collapsed'
    return 'expanded'
  }

  const seoContentClassName = getContentClassName()

  return (
    <ContentWrapper key={'filled-seo-content'}>
      <StyledContainer>
        <If
          condition={collapsable}
          render={() => (
            <SeoHeaderContainer>
              <SeoContentHeader>{h1Text}</SeoContentHeader>
              <SeoContentCollapseExpand
                src={collapsed ? expandIcon : collapseIcon}
                onClick={() => setCollapsed(!collapsed)}
              />
            </SeoHeaderContainer>
          )}
          renderElse={() => <div></div>}
        />
        <div
          className={seoContentClassName}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </StyledContainer>
    </ContentWrapper>
  )
}

SeoContent.defaultProps = { seoPathParams: {} }
SeoContent.propTypes = {
  seoPathParams: PropTypes.object,
  customLocation: PropTypes.object,
  customContent: PropTypes.string,
}

export { SeoContent }
