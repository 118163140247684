import React from 'react'
import PropTypes from 'prop-types'
import { StyledMessage } from './styles'
import { themeStyles } from '../../../styles/theme'

const Message = (props) => {
  const { text, error, success } = props

  const getFontColor = () => {
    if (error) return themeStyles.colors.error.main
    if (success) return themeStyles.colors.success.main
    return themeStyles.colors.black.main
  }

  if (!text) return <></>

  return (
    <StyledMessage color={getFontColor()} {...props}>
      {text}
    </StyledMessage>
  )
}

Message.defaultProps = {
  text: '',
  error: false,
  success: false,
}

Message.propTypes = {
  text: PropTypes.any,
  error: PropTypes.bool,
  success: PropTypes.bool,
}

export { Message }
