import { isMobile } from './generic'
import { isMobileApp } from './mobileAppFunctionality'

export const handlePlatformAction = (
  appAction,
  mobileAction,
  desktopAction
) => {
  if (isMobileApp()) {
    return appAction()
  }
  if (isMobile()) {
    return mobileAction()
  }
  return desktopAction()
}
