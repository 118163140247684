import styled, { css, keyframes } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

const shineAnimation = keyframes`
  0% { background-position: 0% 50%; }
  40% { background-position: 0% 50%; }
  80% { background-position: 100% 50%; }
  100% { background-position: 100% 50%; }
`

const slowShineAnimation = keyframes`
  0% { background-position: 0% 50%; }
  20% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`

const commonCss = css`
  background: linear-gradient(
    45deg,
    ${themeStyles.colors.dominantGrey.main} 50%,
    ${themeStyles.colors.darkGreys[3].main} calc(50% + 8px),
    ${themeStyles.colors.dominantGrey.main} calc(50% + 16px)
  );

  background-size: 400% 400%;
  animation: ${shineAnimation} 4s linear infinite;
`

export const StyledH1Fallback = styled.span`
  ${commonCss}
  height: 2em;
  width: 10em;
  border-radius: 8px;
`

export const StyledH2Fallback = styled.span`
  ${commonCss}
  height: 1.7em;
  width: 8em;
  border-radius: 8px;
`

export const StyledH3Fallback = styled.span`
  ${commonCss}
  height: 0.8em;
  width: 4em;
  border-radius: 8px;
`

export const StyledParagraphFallback = styled.span`
  ${commonCss}
  height: 0.6em;
  width: 3em;
  border-radius: 4px;

  ${({ block }) =>
    !!block &&
    css`
      display: block;
      width: 100%;
    `}

  ${({ theme }) =>
    theme === 'light' &&
    css`
      background: linear-gradient(
        45deg,
        ${themeStyles.colors.lightGreys[1].main} 50%,
        ${themeStyles.colors.white.main} calc(50% + 8px),
        ${themeStyles.colors.lightGreys[1].main} calc(50% + 16px)
      );
      background-size: 400% 400%;
      animation: ${shineAnimation} 4s linear infinite;
    `}
`

export const StyledCircleFallback = styled.div`
  ${commonCss}
  border-radius: 100%;
  height: ${(props) => props.$size};
  width: ${(props) => props.$size};
`

export const StyledRectangleFallback = styled.div`
  ${commonCss}
  animation: ${slowShineAnimation} 4s linear infinite;

  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
`

export const StyledGameTileFallback = styled.div`
  ${commonCss}
  padding: 0 0 calc(130% - 4px) 0;
  margin-bottom: 4px;
  border-radius: 8px;
`
