import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledContent = styled.div`
  padding: 2rem 1rem;
  color: ${themeStyles.colors.black.main};

  form {
    margin-bottom: 6rem;
  }
`

export const StyledRemindMeLater = styled.p`
  color: ${themeStyles.colors.black.main};
  text-align: center;
  margin: 2rem 0 0.5rem 0;
  display: block;
  text-decoration: underline;
  cursor: pointer;
`

export const StyledBlockContent = styled.div`
  margin-bottom: 2rem;

  p,
  h3 {
    margin: 0.5rem;
  }
  a {
    color: ${themeStyles.colors.black.main};
  }
`

export const StyledSuccessIcon = styled.img`
  width: 1rem;
`
