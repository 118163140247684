import styled, { css, keyframes } from 'styled-components'

import { themeStyles } from '../../../styles/theme'

const shineAnimation = keyframes`
  0% { 
    background-position: 0% 50%;
  }
  80% { 
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`

export const StyledPicture = styled.picture`
  display: block;
  width: 100%;
  height: 100%;
  background: ${themeStyles.colors.dominantGrey.main};
  color: transparent;
  overflow: hidden;

  ${(props) =>
    props.$animateLoading &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: linear-gradient(
          45deg,
          ${themeStyles.colors.dominantGrey.main} 50%,
          ${themeStyles.colors.darkGreys[3].main} calc(50% + 8px),
          ${themeStyles.colors.dominantGrey.main} calc(50% + 16px)
        );

        background-size: 400% 400%;

        ${(props) =>
          props.$isLoaded ?
            css`
                opacity: 0;
              ` :
            css`
                opacity: 1;
                animation: ${shineAnimation} 4s linear infinite;
              `}

        transition: 0.5s;
      }
    `}
`

export const StyledGatsbyImageWrapper = styled.div`
  height: 100%;
  width: 100%;

  position: relative;
  z-index: -1;

  * {
    z-index: -1;
    width: 100%;
    height: 100%;
  }
`
