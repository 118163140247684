import React, { useState } from 'react'
import PropTypes from 'prop-types'

const SwipeDetector = ({
  children,
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
}) => {
  const minSwipeDistance = 50
  const detectVertical = onSwipeUp || onSwipeDown

  const [startPos, setStartPos] = useState(null)
  const [endPos, setEndPos] = useState(null)

  const onTouchStart = (e) => {
    setEndPos(null)
    if (detectVertical) setStartPos(e.targetTouches[0].clientY)
    else setStartPos(e.targetTouches[0].clientX)
  }

  const onTouchMoveVertical = (e) => setEndPos(e.targetTouches[0].clientY)
  const onTouchMoveHorizontal = (e) => setEndPos(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!startPos || !endPos) return

    if (detectVertical) detectVerticalSwipes()
    else detectHorizontalSwipes()
  }

  const detectVerticalSwipes = () => {
    const distance = startPos - endPos
    const isUpSwipe = distance > minSwipeDistance
    const isDownSwipe = distance < -minSwipeDistance
    if (isUpSwipe) {
      if (onSwipeUp) onSwipeUp()
    } else if (isDownSwipe) {
      if (onSwipeDown) onSwipeDown()
    }
  }

  const detectHorizontalSwipes = () => {
    const distance = startPos - endPos
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      if (onSwipeLeft) onSwipeLeft()
    } else if (isRightSwipe) {
      if (onSwipeRight) onSwipeRight()
    }
  }

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={detectVertical ? onTouchMoveVertical : onTouchMoveHorizontal}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </div>
  )
}

SwipeDetector.defaultProps = {
  children: <></>,
}

SwipeDetector.propTypes = {
  children: PropTypes.any,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  onSwipeUp: PropTypes.func,
  onSwipeDown: PropTypes.func,
}

export { SwipeDetector }
