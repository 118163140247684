import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'

import useFeatures from '../../../hooks/useFeatures'
import { AuthContext } from '../../../context/authProvider'
import { NavLink } from '../navLink'
import { If } from '../../atoms/if'
import { Image } from '../../atoms/image'
import { SuspensfulComponent } from '../../atoms/suspensfulComponent'
import {
  StyledDesktopNavBarWrapper,
  StyledLeftSide,
  StyledRightSide,
  StyledDesktopLink,
  StyledLogoWrapper,
  StyledRightMenuNotificationWrapper,
  StyledLeftMenuNotificationWrapper,
} from './styles'
import useSlugs from '../../../hooks/useSlugs'
import { sendMobileAppEvent } from '../../../utils/mobileAppFunctionality'
import { NotificationBadge } from '../notificationBadge'
import { pushDataLayer } from '../../../utils/pushDataLayer'

const NavBar = (props) => {
  const {
    data,
    notifications,
    toggleCashbackDrawer,
    hideMobileCTA,
    updateNotifications,
  } = props

  const { isLoggedIn } = useContext(AuthContext)
  const [casinoSlug] = useSlugs(['casino'])

  const isCashbackEnabled = useFeatures('cashback')
  const isContactUsEnabled = useFeatures('contactUs')
  const isPlayerInboxEnabled = useFeatures('playerInbox')

  const [isShown, setSideMenuOpen] = useState(false)
  const [parsedData, setParsedData] = useState({})
  const { burgerMenu, cashbackCta, contactUsCta } = parsedData

  const updateParsedData = () => {
    if (!data || !data.length) return

    const dataToSet = {
      leftSection: [],
      rightSection: [],
    }

    for (const link of data) {
      if (link.className === 'burger-menu' && !dataToSet.burgerMenu) {
        dataToSet.burgerMenu = link
      }
      if (link.className === 'cta cashback' && !dataToSet.cashbackCta) {
        dataToSet.cashbackCta = link
      }
      if (link.className.includes('contact-us') && !dataToSet.contactUsCta) {
        dataToSet.contactUsCta = link
      }
      if (link.section === 'right') {
        dataToSet.rightSection.push(link)
      } else if (link.section === 'left' && link.className != 'burger-menu') {
        dataToSet.leftSection.push(link)
      }
    }
    dataToSet.leftSection.sort(
      (a, b) => parseInt(a.position) - parseInt(b.position)
    )
    dataToSet.rightSection.sort(
      (a, b) => parseInt(a.position) - parseInt(b.position)
    )

    setParsedData(dataToSet)
  }

  useEffect(() => {
    updateParsedData()
  }, [data])

  const onCashback = () => {
    if (!isLoggedIn) navigate('/cashback')

    if (window.location.pathname?.includes(`${casinoSlug}/game`)) {
      toggleCashbackDrawer()
    } else {
      navigate('/cashback')
    }
  }

  const onBurgerClick = () => {
    if (!sendMobileAppEvent('burger')) setSideMenuOpen((isShown) => !isShown)
  }

  const createImageLink = (menuItem, key) => {
    if (!menuItem) return <></>
    const { link, link_logged_in } = menuItem

    let linkUrl = link.url
    if (isLoggedIn && link_logged_in?.url !== '') {
      linkUrl = link_logged_in?.url
    }

    return (
      <Link
        key={key}
        to={linkUrl}
        title={menuItem.link.title}
        className={menuItem?.className}
      >
        <Image menuItem={menuItem} />
      </Link>
    )
  }

  const createLeftLink = (menuItem, i) => {
    if (menuItem.className === 'logo') {
      return (
        <StyledLogoWrapper isLoggedIn={isLoggedIn}>
          {createImageLink(menuItem, i)}
        </StyledLogoWrapper>
      )
    }
    return (
      <StyledDesktopLink key={i}>
        <NavLink menuItem={menuItem} />
      </StyledDesktopLink>
    )
  }

  const createRightLink = (menuItem, i) => (
    <NavLink
      key={i}
      menuItem={menuItem}
      action={menuItem?.name?.toLowerCase() === 'cashback' ? onCashback : null}
    />
  )

  const triggerNotificationGAEvent = () => {
    pushDataLayer({
      eventName: 'notification-opened',
      data: {
        location: 'header',
      },
    })
  }

  const createNotification = (showArrow) => {
    if (!isPlayerInboxEnabled || !isLoggedIn) return <></>
    return (
      <span onClick={triggerNotificationGAEvent}>
        <NotificationBadge
          showArrow={showArrow}
          notifications={notifications}
          updateNotifications={updateNotifications}
        />
      </span>
    )
  }

  return (
    <nav>
      <StyledDesktopNavBarWrapper $isLoggedIn={isLoggedIn}>
        <StyledLeftSide $isLoggedIn={isLoggedIn}>
          <Image onClick={onBurgerClick} menuItem={burgerMenu} />
          {parsedData.leftSection?.map(createLeftLink)}
          <StyledLeftMenuNotificationWrapper $regularScreen>
            {createNotification(true)}
          </StyledLeftMenuNotificationWrapper>
          <StyledLeftMenuNotificationWrapper $smallScreen>
            {createNotification(false)}
          </StyledLeftMenuNotificationWrapper>
        </StyledLeftSide>
        <StyledRightSide isLoggedIn={isLoggedIn} hideMobileCTA={hideMobileCTA}>
          <If
            condition={isLoggedIn}
            render={() => (
              <>
                <StyledRightMenuNotificationWrapper>
                  {createNotification(true)}
                </StyledRightMenuNotificationWrapper>

                {isCashbackEnabled && cashbackCta && (
                  <NavLink menuItem={cashbackCta} action={onCashback} />
                )}
                {isContactUsEnabled && contactUsCta && (
                  <NavLink menuItem={contactUsCta} />
                )}
                <SuspensfulComponent
                  componentName="UserBalance"
                  childProps={{ cashbackCta }}
                />
              </>
            )}
            renderElse={() => parsedData.rightSection?.map(createRightLink)}
          />
        </StyledRightSide>
      </StyledDesktopNavBarWrapper>

      <SuspensfulComponent
        componentName="SideMenu"
        childProps={{ isShown, setSideMenuOpen, menu: data }}
        condition
      />
    </nav>
  )
}

NavBar.defaultProps = {
  data: [],
  notifications: 0,
  hideMobileCTA: false,
  toggleCashbackDrawer: () => {},
}
NavBar.propTypes = {
  data: PropTypes.array,
  notifications: PropTypes.number,
  hideMobileCTA: PropTypes.bool,
  toggleCashbackDrawer: PropTypes.func,
}

export { NavBar }
