import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import ChevronLeft from '../../../images/icon_chevron_left_white.svg'
import { brandConfig } from '../../../config/brandConfig'

export const FooterLinksDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeStyles.colors.black.main};
  padding-top: 3rem;

  ${themeMedia.desktop`
    display: none;
    padding-top: 0;
  `}

  & details:first-child summary {
    border-top: 2px solid ${themeStyles.colors.dominantGrey.main};
  }

  summary {
    position: relative;
    border-bottom: 2px solid ${themeStyles.colors.dominantGrey.main};
    list-style: none;
    padding: 1.4rem;
    &::-webkit-details-marker {
      display: none;
    }

    &:after {
      content: '';
      height: 2.1rem;
      width: 0.5rem;
      background: url('${ChevronLeft}') center no-repeat;

      position: absolute;
      top: 50%;
      right: 1.1rem;
      transform-origin: center;
      transform: translateY(-50%) rotate(-90deg);

      transition: 0.2s;
    }
  }

  details {
    &[open] summary:after {
      transform: translateY(-50%) rotate(90deg);
    }
  }
`

export const MenuSectionTitle = styled.div`
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
`

export const MenuSectionUl = styled.ul`
  background-color: ${themeStyles.colors.dominantGrey.main};

  a {
    color: ${themeStyles.colors.white.main};
  }
  margin: 0;
  padding: 0.25rem 1.2rem 0.25rem 1.4rem;
  font-size: ${brandConfig.fonts.text.small.size.desktop};
  line-height: 2.5rem;
`
