import React from 'react'
import PropTypes from 'prop-types'
import { StyledMainWrapper } from './styles'

const Container = ({ children }) => {
  return <StyledMainWrapper className="container">{children}</StyledMainWrapper>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Container }
