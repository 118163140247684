import styled, { css, keyframes } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import TimesIcon from '../../../images/icon_times_white.svg'

//* Animations
const fadeInAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const slideInAnimation = keyframes`
    0% { transform: translateY(25%); }
    100% { opacity: translateY(0); }
`

export const StyledModalWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 1000001;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: ${themeStyles.colors.black.main}7F;
  color: ${themeStyles.colors.white.main};
  animation: ${fadeInAnimation} 0.3s;
`

export const StyledModalCard = styled.div`
  position: relative;

  max-width: 400px;
  max-height: 80vh;
  overflow: auto;
  border-radius: 8px;

  background-color: ${(props) =>
    props.backgroundColor ?? themeStyles.colors.darkGreys[9].main};
  background-clip: padding-box;
  overflow-x: hidden;
  overflow-y: auto;
  animation: ${slideInAnimation} 0.4s;

  ${themeMedia.desktop`
    max-width: 622px;
  `}

  ${({ outlineColor }) =>
    outlineColor &&
    css`
      border: 2px solid ${outlineColor};
    `}
`

export const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    max-height: 200px;
    object-position: center;
    object-fit: cover;

    ${themeMedia.desktop`
      max-height: 366px;
    `}
  }
`

export const StyledModalContent = styled.div`
  padding: 1.4em;

  display: flex;
  flex-direction: column;
  gap: 1.4em;
`

export const StyledModalTitle = styled.div`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  font-size: 27px;
  font-weight: 900;
  text-transform: uppercase;
`

export const StyledModalSubheader = styled.div`
  h3 {
    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
    font-weight: 400;
    font-size: 18px;
  }
`

export const StyledModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.2em 1.2em;
  gap: 16px;

  a,
  button {
    flex: 1;
  }
`

const closeButtonSize = '0.8em'

export const StyledCloseButton = styled.button`
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: ${closeButtonSize};
  height: ${closeButtonSize};
  background-image: url(${TimesIcon});

  position: absolute;
  top: 0.6em;
  right: 0.6em;

  ${(props) =>
    props.$hidden &&
    css`
      visibility: hidden;
    `}
`
