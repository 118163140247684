import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

const transitionDuration = 400
const transitionDelay = 50
export const loadingTransitionFinished = transitionDelay + transitionDuration
export const StyledLoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  height: 100%;
  width: 100%;
  background-color: ${themeStyles.colors.black.main};
  transition-timing-function: ease-in;
  transition-duration: ${transitionDuration}ms;
  transition-delay: ${transitionDelay}ms;

  ${(props) =>
    props.hideScreen ?
      css`
          opacity: 1;
        ` :
      css`
          opacity: 0;
        `}
`
