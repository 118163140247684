import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { useTranslation } from '../../../context/translationProvider'

import {
  StyledCloseButton,
  StyledModalCard,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalSubheader,
  StyledModalTitle,
  StyledModalWrapper,
  StyledModalContent,
} from './styles'
import { Button } from '../../atoms/button'
import { If } from '../../atoms/if'
import { toast } from '../../atoms/toast'
import http from '../../../adapters/base/coreApi'
import { isBrowser } from '../../../utils/generic'

export const Popup = ({ popup, checkPopup }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { translate } = useTranslation()

  if (!popup || !popup?.content) return null

  const { id, dismissible, template } = popup
  const { title, content, buttons, image } = popup.content

  useEffect(() => {
    setIsOpen(true)
  }, [id])

  const closeModal = async () => {
    await checkPopup(id, 'DISMISSED')
    setIsOpen(false)
  }

  const callCustomApi = async ({ url, method, body }) => {
    setIsLoading(true)

    await http(url, {
      method,
      data: JSON.parse(body),
    })
      .then((res) => {
        if (res.ok || res.ok == null) {
          closeModal()
        } else {
          toast.error(translate('common.unexpectedError'))
        }
      })
      .catch(() => {
        toast.error(translate('common.unexpectedError'))
      })

    await checkPopup(id, 'CLICKED')
    setIsOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (isBrowser() && isOpen) {
      document.body.classList.add('overflow-hidden')
      checkPopup(id, 'SEEN')
    }

    return () => document.body.classList.remove('overflow-hidden')
  }, [isOpen])

  const onButtonClick = (button) => {
    if (button?.type === 'url') {
      checkPopup(id, 'CLICKED')
    } else {
      callCustomApi(button)
    }
  }

  if (!isOpen) return <></>

  const defaultCtaProperties =
    template?.ctaProperties?.[template?.ctaProperties?.length - 1] ?? {}

  const createButton = (button, i) => {
    let path = null
    if (button?.type === 'url') {
      path = button?.url ?? ''
      if (!path.startsWith('http') && !path.startsWith('/')) {
        path = `/${button?.url}`
      }
    }

    const ctaProperties = template?.ctaProperties?.[i] ?? defaultCtaProperties

    return (
      <Button
        minWidth="167px"
        key={i}
        isLoading={isLoading}
        onClick={() => onButtonClick(button)}
        href={path}
        target={button?.target}
        backgroundColor={ctaProperties.backgroundColor}
        textColor={ctaProperties.textColor}
        outlineColor={ctaProperties.outlineColor}
      >
        {button?.value}
      </Button>
    )
  }

  return createPortal(
    <StyledModalWrapper onClick={dismissible ? closeModal : null}>
      <StyledModalCard
        onClick={(e) => e.stopPropagation()}
        backgroundColor={template?.backgroundColor}
        outlineColor={template?.outlineColor}
      >
        <StyledModalHeader>
          <If
            condition={!!image}
            render={() => <img src={image} alt={title} />}
          />
          <StyledCloseButton $hidden />
          <StyledCloseButton onClick={closeModal} />
        </StyledModalHeader>

        <StyledModalContent>
          <StyledModalTitle color={template?.titleTextColor}>
            {title}
          </StyledModalTitle>
          <StyledModalSubheader color={template?.descriptionTextColor}>
            {content}
          </StyledModalSubheader>
        </StyledModalContent>

        <StyledModalFooter>{buttons?.map(createButton)}</StyledModalFooter>
      </StyledModalCard>
    </StyledModalWrapper>,
    document?.body
  )
}

Popup.propTypes = {
  popup: PropTypes.shape({
    id: PropTypes.string,
    dismissible: PropTypes.bool,
    content: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.string,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          image: PropTypes.string,
        })
      ),
    }),
    template: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      backgroundColor: PropTypes.string,
      titleTextColor: PropTypes.string,
      descriptionTextColor: PropTypes.string,
      outlineColor: PropTypes.string,
      ctaProperties: PropTypes.arrayOf(
        PropTypes.shape({
          typeName: PropTypes.string,
          textColor: PropTypes.string,
          backgroundColor: PropTypes.string,
          outlineColor: PropTypes.string,
        })
      ),
    }),
  }),
  checkPopup: PropTypes.func,
}
