import { intervalToDuration, isAfter, differenceInDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'

export const useTimer = ({ endDate, onFinish, immediatellySetTime }) => {
  const [duration, setDuration] = useState({})

  useEffect(() => {
    const interval = setInterval(() => updateDuration(), 1000)

    const updateDuration = () => {
      const now = utcToZonedTime(new Date(), 'UTC')
      if (isAfter(now, endDate)) {
        if (onFinish) onFinish()
        clearInterval(interval)
        return {}
      }

      const aux = intervalToDuration({
        start: endDate,
        end: now,
      })

      const days = differenceInDays(endDate, now)
      setDuration({ ...aux, days })
    }

    if (immediatellySetTime) updateDuration()
    return () => clearInterval(interval)
  }, [endDate])

  return duration
}
