import useFeatures from './useFeatures'

export const useReCaptcha = (onSubmit, action) => {
  const isRecaptchaEnabled = useFeatures('recaptcha')

  return async (params) => {
    if (isRecaptchaEnabled) {
      window?.grecaptcha?.enterprise?.ready(async () => {
        const recaptchaToken = await window?.grecaptcha?.enterprise?.execute(
          process.env.GATSBY_RECAPTCHA_KEY,
          { action }
        )
        onSubmit({ ...params, recaptchaToken })
      })
    } else {
      onSubmit({ ...params, recaptchaToken: null })
    }
  }
}
