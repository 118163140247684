import styled, { css, keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledCashback = styled.button`
  appearance: none;
  color: ${themeStyles.colors.white.main};
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
  text-align: left;
  background: ${themeStyles.colors.black.main} url(${(props) => props.img}) 0px
    no-repeat;
  margin: 0;
  text-transform: capitalize;
  letter-spacing: 0;

  padding: 0.3rem 10px 0.3rem 2rem;
  font-size: 1em;
  height: 36px;
  background-size: 25px;

  ${({ $idSuffix }) =>
    $idSuffix !== '-sideMenu' &&
    css`
      ${themeMedia.desktop`
        background: ${themeStyles.colors.dominantGrey.main} url(${(props) =>
          props.img}) 6px no-repeat;
        border-radius: 36px;
        padding: 0.3rem 0.82rem 0.3rem 2.1rem;
        text-align: right;
      `}
      ${themeMedia.maxNavbarBreakpointTurnCashbackIntoIcon`
        padding: 0;
        font-size: 0;
        width: 36px;
        background-size: 24px;
        background-position: center;
      `}
    `}
`
const scale = 1.07
const notification = keyframes`
  0% {
    transform: rotate(5deg) scale(${scale});
  } 

  25% {
    transform: rotate(-5deg) scale(${scale});
  }

  50% {
    transform: rotate(5deg) scale(${scale});
  }

  75% {
    transform: rotate(-5deg) scale(${scale});
  }

  100% {
    transform: rotate(0deg) scale(${scale});
  }
`

export const StyledContactUs = styled.button`
  height: 36px;
  appearance: none;
  color: ${themeStyles.colors.white.main};
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
  padding: 0.3rem 10px 0.3rem 2rem;
  text-align: left;
  background: ${themeStyles.colors.black.main} url(${(props) => props.img}) 0px
    no-repeat;
  background-size: 25px;
  margin: 0;
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 1em;

  ${({ $idSuffix }) =>
    $idSuffix !== '-sideMenu' &&
    css`
      ${themeMedia.desktop`
      background: transparent url(${(props) => props.img}) 6px no-repeat;
      border-radius: 36px;
      padding: 0.3rem 0.5rem 0.3rem 2.1rem;
      text-align: right;
    `}
      ${themeMedia.maxNavbarBreakpointTurnCashbackIntoIcon`
      padding: 0;
      font-size: 0;
      width: 36px;
      background-size: 24px;
      background-position: center;
      background-color: ${themeStyles.colors.dominantGrey.main};
    `}
    `}
`

export const StyledNotification = styled.button`
  border: 2px solid ${themeStyles.colors.dominantGrey.main};
  color: ${themeStyles.colors.accentColors.secondary.main};
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 36px;
  padding: 0.3rem 0.5rem 0.3rem 0.3rem;
  text-align: right;
  background: ${themeStyles.colors.dominantGrey.main};
  margin: 0 1rem 0 0;
  letter-spacing: 0;
  font-size: 14px;
  transition: 1s;

  ${themeMedia.maxNavbarBreakpointSmallMobile`
    gap: 1px;
    ${({ hasNotification }) =>
      hasNotification &&
      css`
        padding-right: 7px;
      `}
  `}

  ${themeMedia.navbarBreakpointSmallMobile`
     ${({ hasNotification }) =>
       hasNotification &&
       css`
         gap: 2px;
       `}
  `}

  ${({ hasNotification }) =>
    !hasNotification &&
    css`
      padding: 0.3rem;
    `}
`

export const ArrowDownImage = styled.img`
  width: 24px;
  margin-right: -3px;
`

export const NotificationImage = styled.img`
  width: 23px;
  margin-right: 0px;

  ${themeMedia.desktop`
    margin-left: 2px;
  `};

  &.animate {
    animation: ${notification} 1s linear;
  }
`

export const StyledHref = styled(Link)`
  text-transform: capitalize;
  margin: 0;
  color: ${themeStyles.colors.white.main};
  font-size: 1em;
  line-height: 25px;
  display: inline-block;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
  }

  ${(props) => {
    if (props.img) {
      return `
        background-image: url(${props.img});
        background-size: 24px;
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0 0 0 26px;
      `
    }
  }}

  ${(props) =>
    props.$active &&
    `
      &:after {
        background-color: ${themeStyles.colors.primary.main};
      }
    `}

  &.mobile {
    padding: 0 0 0 30px;
  }

  &.refer-friend {
    padding: 0 0 0 10px;
  }

  ${themeMedia.desktop`
    &.mobile {
      background: none;
      padding: 0;
    }

    ${({ $idSuffix }) =>
      $idSuffix !== '-sideMenu' &&
      css`
        &.refer-friend {
          padding: 0;
        }
      `}

    &.active {
      border-bottom: 1px solid ${themeStyles.colors.primary.main};
    }
  `};
`

export const StyledRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 35px;
  align-items: center;

  ${themeMedia.desktop`
    height: auto;
  `};

  > img {
    width: 24px;
    height: 24px;

    &.mobile {
      padding: 0 10px 0 0;
    }

    ${themeMedia.desktop`
      height: auto;

      &.mobile {
        display: none;
      }
    `};
  }
`
