import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const FooterContainer = styled.footer`
  background-color: ${themeStyles.colors.black.main};
  color: ${themeStyles.colors.white.main};

  ${themeMedia.desktop`
    border-top: 2px solid ${themeStyles.colors.dominantGrey.main};
    padding-top: 4em;
  `}
`

export const FooterLinksImages = styled.div`
  display: flex;
  flex-direction: column;

  ${themeMedia.desktop`
    flex-direction: row;
    justify-content: end;
    margin: 0 auto;
    max-width: 1200px;
  `}
`
