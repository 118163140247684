import { useEffect, useState } from 'react'
import Pusher from 'pusher-js'
import { EnvConfig } from '../config/EnvConfig'
import storageService from '../services/storageService'
import useFeatures from './useFeatures'

const APP_KEY = process.env.GATSBY_PUSHER_APP_KEY
const APP_CLUSTER = process.env.GATSBY_PUSHER_APP_CLUSTER

export const usePusher = ({
  user,
  isLoggedIn,
  updateUserWallet,
  updateActiveVaultCards,
}) => {
  const [isPusherAvailable, setIsPusherAvailable] = useState(false)
  const [pusher, setPusher] = useState()
  const [channel, setChannel] = useState()
  const isPusherEnabled = useFeatures('pusher')

  useEffect(() => {
    if (isLoggedIn && !pusher && isPusherEnabled) {
      const newPusher = new Pusher(APP_KEY, {
        cluster: APP_CLUSTER,
        authEndpoint: `${EnvConfig.API_URL}/wallet/realtime/authenticate`,
        auth: {
          headers: {
            Authorization: `Bearer ${storageService.getAccessToken()}`,
          },
        },
      })
      setPusher(newPusher)
    }
  }, [isLoggedIn, pusher, isPusherEnabled])

  useEffect(() => {
    let pusherTimeout

    if (pusher) {
      if (pusher?.connection?.state === 'connecting') {
        pusherTimeout = setTimeout(() => {
          setIsPusherAvailable(pusher?.connection?.state === 'connected')
        }, [1000])
      }

      pusher.connection.bind('error', (err) => {
        setIsPusherAvailable(pusher?.connection?.state === 'connected')
        if (err?.error?.data?.code === 4004) {
          setIsPusherAvailable(false)
        }
      })

      if (!channel) {
        const chanelInstance = pusher.subscribe(`private-payments-${user?.id}`)
        setChannel(chanelInstance)
      }
    }

    return () => {
      clearTimeout(pusherTimeout)
    }
  }, [pusher, channel])

  useEffect(() => {
    if (channel) {
      channel.bind('balance_updated', (data) => {
        const { amount, vault_balance } = data
        updateUserWallet({ amount, vault_balance })
      })

      channel.bind('vault_updated', (data) => {
        updateActiveVaultCards(data)
      })
    }
  }, [channel])

  return { isPusherEnabled, isPusherAvailable }
}
