import React from 'react'

import { ResponsiveImage } from '../responsiveImage'
import { Container } from '../container'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { StyledLogoWrapper, StyledResponsibleText } from './styles'
import { getLinkRel } from '../../../utils/getLinkRel'

const ResponsibleGambling = () => {
  const { footer_misc } = useFooterV2ApiData()
  const responsibleGamingFooterMisc = footer_misc.find(
    (item) => item.acf_fc_layout === 'responsible_gaming'
  )

  if (!responsibleGamingFooterMisc) return

  const renderImageTag = (image) => {
    return <ResponsiveImage loading="lazy" image={image} />
  }
  return (
    <div>
      <Container>
        <StyledLogoWrapper>
          {responsibleGamingFooterMisc?.images?.map((img, i) => {
            return img?.link?.url ? (
              <a
                key={`link-${i}`}
                href={img?.link?.url}
                title={`${img?.link?.url}`}
                target={img?.link?.target}
                rel={getLinkRel(img?.link?.target)}
              >
                {renderImageTag(img.image)}
              </a>
            ) : (
              <div key={`link-${i}`}>{renderImageTag(img.image)}</div>
            )
          })}
        </StyledLogoWrapper>
        <StyledResponsibleText
          dangerouslySetInnerHTML={{
            __html: responsibleGamingFooterMisc?.description,
          }}
        />
      </Container>
    </div>
  )
}

export { ResponsibleGambling }
