import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useIntersection } from '../../../hooks/useIntersectionObserver'
import { If } from '../if'
import { StyledGatsbyImageWrapper, StyledPicture } from './styles'
import { getOptimizedImage } from '../../../utils/getOptimizedImage'
import { GatsbyImage } from 'gatsby-plugin-image'

const ResponsiveImage = (props) => {
  const {
    image = {},
    big,
    width,
    height,
    animateLoading,
    preload,
    loading,
    gatsbyImage,
    useGatsbyImage,
  } = props
  const { sizes = null, alt = '', title = '', url = '' } = image || {}

  if (!sizes || (!big && !sizes?.medium_large) || (big && !sizes?.large))
    return <></>

  const { medium_large, large } = sizes

  const imgURL = url || large || null

  const imgRef = useRef()

  const [isInView, setIsInView] = useState(preload)
  const [isLoaded, setIsLoaded] = useState(false)

  useIntersection(imgRef, () => {
    setIsInView(true)
  })

  useEffect(() => {
    if (isInView) {
      setIsLoaded(true)
    }
  }, [isInView])

  const imgProps = {}
  if (width && height) {
    imgProps.width = width
    imgProps.height = height
  }

  const renderOptimizedImages = () => {
    const images = [
      {
        format: 'webp',
        size: 'w700',
        media: '(max-width: 1023px)',
      },
      {
        format: 'jpg',
        size: 'w700',
        media: '(max-width: 1023px)',
      },
      {
        format: 'webp',
        size: 'w1024',
        media: '(min-width: 1024px)',
      },
      {
        format: 'jpg',
        size: 'w1024',
        media: '(min-width: 1024px)',
      },
    ]

    images.forEach((image) => {
      image.url = getOptimizedImage(imgURL, `${image.size}-${image.format}`)
    })

    const src = large || medium_large
    const isSVG = src?.slice(-4) === '.svg'

    return (
      <>
        <If
          condition={!isSVG}
          render={() =>
            images.map((image) => (
              <source
                type={`image/${image.format}`}
                media={image.media}
                srcSet={image.url}
                key={image.url}
              />
            ))
          }
        />
        <img
          decoding={preload ? 'auto' : 'async'}
          src={src}
          alt={alt || title}
          loading={loading}
          {...imgProps}
        />
      </>
    )
  }

  return (
    <If
      condition={useGatsbyImage}
      render={() => (
        <StyledGatsbyImageWrapper ref={imgRef}>
          <GatsbyImage
            image={gatsbyImage}
            alt={alt || title}
            loading={loading}
            {...imgProps}
          />
        </StyledGatsbyImageWrapper>
      )}
      renderElse={() => (
        <StyledPicture
          ref={imgRef}
          $animateLoading={animateLoading}
          $isLoaded={isLoaded}
        >
          <If condition={isInView} render={() => renderOptimizedImages()} />
        </StyledPicture>
      )}
    />
  )
}

ResponsiveImage.defaultProps = {
  image: {},
  big: false,
  preload: false,
  animateLoading: false,
  loading: '',
  width: null,
  height: null,
}

ResponsiveImage.propTypes = {
  image: PropTypes.object,
  big: PropTypes.bool,
  preload: PropTypes.bool,
  animateLoading: PropTypes.bool,
  useGatsbyImage: PropTypes.bool,
  loading: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export { ResponsiveImage }
