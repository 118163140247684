import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledLogoWrapper = styled.div`
  text-align: center;
  padding-top: 2.375rem;
  padding-bottom: 4.875rem;

  picture {
    background: transparent;
  }

  img {
    height: 19px;
    width: auto;
  }

  ${themeMedia.maxTablet`
    padding: 2rem;
  `}
`
