import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledParagraph,
  StyledSpan,
} from './styles'

const Title = (props) => {
  const { level, tagType, children } = props
  switch (tagType) {
    case 'span':
      return <StyledSpan {...props}>{children}</StyledSpan>
    case 'h3':
      return <StyledH3 {...props}>{children}</StyledH3>
    case 'h2':
      return <StyledH2 {...props}>{children}</StyledH2>
    case 'h1':
      return <StyledH1 {...props}>{children}</StyledH1>
  }

  switch (level) {
    case 3:
      return <StyledH3 {...props}>{children}</StyledH3>
    case 2:
      return <StyledH2 {...props}>{children}</StyledH2>
    case 1:
    default:
      return <StyledH1 {...props}>{children}</StyledH1>
  }
}

const Text = (props) => {
  return <StyledParagraph {...props}>{props.children}</StyledParagraph>
}

Title.defaultProps = {
  children: <></>,
  level: 1,
  tagType: '',
  keepMobileSize: false,
}
Title.propTypes = {
  children: PropTypes.any,
  level: PropTypes.number,
  tagType: PropTypes.string,
  keepMobileSize: PropTypes.bool,
}

Text.defaultProps = {
  children: <></>,
  small: false,
  big: false,
  extraBig: false,
  theme: 'default',
  noMargin: false,
  bold: false,
  black: false,
  isClickable: false,
  verticalPadding: false,
  textAlign: 'left',
  truncate: false,
  truncateWidth: null,
}

Text.propTypes = {
  children: PropTypes.any,
  small: PropTypes.bool,
  big: PropTypes.bool,
  extraBig: PropTypes.bool,
  theme: PropTypes.string,
  noMargin: PropTypes.bool,
  bold: PropTypes.bool,
  black: PropTypes.bool,
  isClickable: PropTypes.bool,
  verticalPadding: PropTypes.bool,
  textAlign: PropTypes.string,
  truncate: PropTypes.bool,
  truncateWidth: PropTypes.string,
}

export { Title, Text }
