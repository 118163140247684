import styled from 'styled-components'

import { themeMedia, themeStyles } from '../../../styles/theme'

export const StyledImg = styled.img`
  height: 15px !important;
  left: 15px;
  position: absolute;
  top: calc(50% - 7px);
  width: 32px !important;
  z-index: 10;
`

export const StyledField = styled.div`
  width: 100%;
  margin: 0.3rem 0 0.3rem 0.1rem;

  ${themeMedia.desktop`
    margin: auto
  `};
`

export const StyledWrapper = styled.div`
  background-color: ${themeStyles.colors.white.main};
  border-radius: 5px;
  color: ${themeStyles.colors.white.main};
  display: flex;
  padding: 0 4px;
  position: relative;
  width: calc(100% - 8px);
  max-width: 100%;

  ${themeMedia.desktop`
    height: 56px;
    position: absolute;
    bottom: 1em;
    left: 1em;
    max-width: 80%;

    input {
      background-color: ${themeStyles.colors.lightGreys[1].main}; 
      padding-left: 45px;
      
      &:focus-visible {
        outline: none;
      }

      &:not(select):not(:disabled):not([value='']) {
        background-color: ${themeStyles.colors.white.main};
      }
    
      &::placeholder {
        color: ${themeStyles.colors.lightGreys[3].main};
      }
      
    }

    Button {
      width: 18rem !important;
    }

  `};

  input {
    padding-left: 45px;
    padding-right: 10px;
  }

  Button {
    height: 48px;
    margin: auto 0 auto 0.5rem;
    min-width: 1rem;
    width: 3rem;

    img {
      max-height: 13px;
      max-width: 8px;
    }
  }
`
