import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledHeaderWrapper = styled.header`
  background-color: ${themeStyles.colors.black.main};
  position: sticky;
  top: 0;
  z-index: 500;

  ${(props) =>
    props.page === 'vflm' &&
    css`
      z-index: 2500;
    `}

  > nav {
    color: ${themeStyles.colors.white.main};
    padding: 0 0.75em;
    height: var(--header-size);

    ${({ isLoggedIn, hideMobileCTA }) =>
      !isLoggedIn &&
      !hideMobileCTA &&
      themeMedia.maxNavbarBreakpointMobileTablet`
        height: var(--header-size-expanded);
      `}

    ${(props) =>
      !props.isLoggedIn &&
      !props?.hideMobileBottomNav &&
      css`
        padding-bottom: 0.3em;
      `}

    ${themeMedia.navbarBreakpointMobileTablet`
      padding-left: 1em;
      padding-bottom: 0;
    `}
  }
`
