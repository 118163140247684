import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const StyledGridWrapper = styled.div`
  margin: 0 0 2rem 0;
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
`

export const StyledHeader = styled.div`
  background-color: ${themeStyles.colors.lightGreys[1].main};
  border-radius: 4px;
  font-weight: 600;

  .cell {
    word-break: break-word;
  }
`

export const StyledCell = styled.div.attrs((props) => ({
  color: props.color || '',
  size: props.size || '',
}))`
  word-break: break-word;
  color: ${(props) => props.color};
  flex: ${(props) => props.size};
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
`

export const StyledRow = styled.div`
  display: flex;
  padding: 1.25rem 1rem;
  border-bottom: 2px solid ${themeStyles.colors.lightGreys[1].main};
`

export const StyledNoData = styled.div`
  text-align: center;
  line-height: 60px;
  border-bottom: 1px solid ${themeStyles.colors.lightGreys[1].main};
`
