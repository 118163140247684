// import SlugsWorker from './slugs.worker'

// const slugsWorker = typeof window === 'object' && new SlugsWorker()

// export default slugsWorker

function filterSlugs(slugs, slugKeys) {
  return slugKeys?.map(
    (slugKey) =>
      slugs.find((slug) => slug.generic_route.toLowerCase() === slugKey)
        ?.language_route ?? slugKey
  )
}

export default { filterSlugs }
