import React from 'react'
import { toast as baseToast } from 'react-toastify'
import { StyledMessage, StyledToast } from './styles'
import TimesIcon from '../../../images/icon_times_black.svg'
import { toastZIndex } from '../../atoms/toastContainer'

const toastInternal = (status, message, options, statusOverrides) => {
  const createCloseButton = ({ closeToast }) => (
    <img src={TimesIcon} className="toast-close-button" onClick={closeToast} />
  )

  const createToast = () => (
    <StyledToast>
      <StyledMessage id={`toast_${status}_${Math.floor(Math.random() * 100)}`}>
        {message}
      </StyledMessage>
    </StyledToast>
  )

  const params = {
    className: status,
    autoClose: 30000,
    hideProgressBar: false,
    pauseOnVisibilityChange: false,
    draggable: false,
    pauseOnHover: false,
    position: 'top-center',
    closeButton: options?.hideCloseButton ? false : createCloseButton,
    style: { zIndex: toastZIndex },
    ...statusOverrides,
    ...options,
  }

  return baseToast(createToast(), params)
}

const toast = {
  success: (message, options) => toastInternal('success', message, options),
  info: (message, options) => toastInternal('info', message, options),
  warn: (message, options) => toastInternal('warn', message, options),
  error: (message, options) => toastInternal('error', message, options),
  dismiss: (id) => baseToast.dismiss(id),
}

export { toast }
