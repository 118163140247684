import styled from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

export const Wrapper = styled.div`
  margin: 1em 0 0 0;
  padding: 1em;
  text-align: center;
  color: ${themeStyles.colors.black.main};
`

export const Message = styled.p`
  line-height: 1.5em;
  margin: 0.6em 0;
  &.mobile-align-left {
    ${themeMedia.maxTablet`
    text-align: left;
  `}
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75em;
  margin: 2em 0;

  &.with-spacing {
    margin: 3em 0 2em;
  }

  ${themeMedia.maxTablet`
    flex-direction: column-reverse;
    margin: 0;

    button {
      width: 100%;
    }
  `}
`

export const ButtonsAndGridOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  ${themeMedia.maxTablet`
    flex-direction: column-reverse;
    margin-top: 1.5em;
    gap: 1em;
  `}
`

export const MobileDetails = styled.div`
  border-radius: 11px;
  border: 3px solid ${themeStyles.colors.lightGreys[1].main};
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  > div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.4em;
    border-bottom: 1px solid ${themeStyles.colors.lightGreys[2].main};

    span:first-child {
      font-weight: 600;
    }
  }
`

export const ProceedToDeposit = styled(Message)`
  font-size: 0.9em;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
`
