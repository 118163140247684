import React from 'react'
import PropTypes from 'prop-types'
import { FieldInputArea } from '../../atoms/fieldInputArea'
import { FieldWrapper } from '../fieldWrapper'

const Field = (props) => {
  const {
    label,
    placeholder,
    defaultValue,
    doNotValidate,
    type,
    step,
    description,
    descriptionTheme,
    inputmode,
    disabled,
    showErrorMsg,
    autoCompleteOff,
    maxLength,
    minLength,
    errors,
    formProperties,
    hasBeenSubmitted,
    onChange,
    renderError,
    inputSuffix,
    onKeyDown,
    fieldValue,
    noBottomMargin,
    forceValue,
    mask,
    labelButton,
    suffixPointerEvents,
    hideSymbols,
    onFocus,
    onBlur,
    immediatellyValidate,
  } = props

  if (!formProperties) return <></>

  return (
    <FieldWrapper
      label={label}
      description={description}
      descriptionTheme={descriptionTheme}
      doNotValidate={doNotValidate}
      showErrorMsg={showErrorMsg}
      errors={errors}
      formProperties={formProperties}
      hasBeenSubmitted={hasBeenSubmitted}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      renderError={renderError}
      key={`input-${formProperties.name}`}
      minLength={minLength}
      fieldValue={fieldValue}
      noBottomMargin={noBottomMargin}
      labelButton={labelButton}
      immediatellyValidate={immediatellyValidate}
      renderInputField={({
        onChangeCombined,
        onBlurCombined,
        onKeyDownCombined,
        onFocusCombined,
        invalid,
        valid,
      }) => (
        <FieldInputArea
          type={type}
          step={step}
          placeholder={placeholder}
          autoComplete={!autoCompleteOff}
          defaultValue={defaultValue}
          maxLength={maxLength}
          minLength={minLength}
          disabled={disabled}
          error={!!errors[formProperties.name]}
          formProperties={formProperties}
          hasBeenSubmitted={hasBeenSubmitted}
          valid={valid}
          invalid={invalid}
          onChange={onChangeCombined}
          onKeyDown={onKeyDownCombined}
          onBlur={onBlurCombined}
          onFocus={onFocusCombined}
          inputSuffix={inputSuffix}
          suffixPointerEvents={suffixPointerEvents}
          value={fieldValue}
          forceValue={forceValue}
          mask={mask}
          hideSymbols={hideSymbols}
          inputmode={inputmode}
        />
      )}
    />
  )
}

Field.defaultProps = {
  label: null,
  placeholder: null,
  defaultValue: null,
  doNotValidate: false,
  type: 'text',
  step: null,
  description: null,
  inputMode: null,
  disabled: false,
  showErrorMsg: false,
  hasBeenSubmitted: null,
  autoCompleteOff: false,
  noBottomMargin: false,
  hideSymbols: false,
  immediatellyValidate: false,
  errors: {},
  maxLength: null,
  minLength: null,
  formProperties: {},
  onChange: () => {},
  onKeyDown: () => {},
  renderError: null,
  inputSuffix: null,
  fieldValue: null,
  mask: null,
  labelButton: null,
  suffixPointerEvents: 'none',
  descriptionTheme: null,
  onFocus: () => {},
  onBlur: () => {},
}

Field.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  doNotValidate: PropTypes.bool,
  type: PropTypes.string,
  step: PropTypes.string,
  description: PropTypes.string,
  descriptionTheme: PropTypes.string,
  inputmode: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  hasBeenSubmitted: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  hideSymbols: PropTypes.bool,
  immediatellyValidate: PropTypes.bool,
  errors: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  renderError: PropTypes.func,
  inputSuffix: PropTypes.element,
  fieldValue: PropTypes.any,
  suffixPointerEvents: PropTypes.string,
  mask: PropTypes.string,
  labelButton: PropTypes.element,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export { Field }
