import React, { useEffect, useContext, useState, useCallback } from 'react'
import { format } from 'date-fns-tz'
import { AuthContext } from '../../context/authProvider'
import { useLocation } from '@reach/router'

import storageService from '../../services/storageService'
import { isBrowser, removeLocaleFromURL } from '../../utils/generic'
import { useLiveAgentApiData } from '../../hooks/graphqlStaticQuery/useLiveAgentApiData'
import { EnvConfig } from '../../config/EnvConfig'

const LiveAgentScript = () => {
  const [isEnabled, setIsEnabled] = useState()
  const { pathname } = useLocation()
  const { isLoggedIn } = useContext(AuthContext)
  const { availableRoutes } = useLiveAgentApiData()

  const addLiveAgentScript = useCallback(() => {
    const chatButtons = document?.querySelectorAll('[title="Live chat button"]')
    if (chatButtons?.length > 0) {
      return
    }

    const user = storageService.getUser()
    const group = EnvConfig.GATSBY_LIVEAGENT_GROUP

    const script = document.createElement('script')
    script.src = EnvConfig.GATSBY_LIVEAGENT_SRC
    script.defer = true
    script.id = EnvConfig.GATSBY_LIVEAGENT_ID

    script.onload = script.onreadystatechange = function () {
      const rs = this.readyState

      if (rs && rs !== 'complete' && rs !== 'loaded') {
        return
      }

      if (user) {
        window.LiveAgent.setUserDetails(
          user.email || '',
          user.first_name || '',
          user.last_name || ''
        )

        window.LiveAgent.addContactField('id', user.id)
        window.LiveAgent.addContactField(
          'ubos_url',
          `${EnvConfig.GATSBY_UBOS_URL}/players/${user.id}/general/player-info`
        )
        window.LiveAgent.addContactField('status', user.user_status?.name || '')
        window.LiveAgent.addContactField(
          'cpf_status',
          user.national_id_status || ''
        )
        window.LiveAgent.addContactField('kyc_status', user.kyc_status || '')
        window.LiveAgent.addContactField(
          'registration_date',
          format(new Date(user.created_at), 'yyyy-MM-dd HH:mm') || ''
        )
        window.LiveAgent.addContactField(
          'last_login_date',
          format(new Date(user.last_login), 'yyyy-MM-dd HH:mm') || ''
        )
      }

      window.chatButton = window.LiveAgent.createButton(group, this)
    }

    document.body.appendChild(script)
  }, [isLoggedIn])

  const removeLiveAgentScript = () => {
    const chatButtons = document?.querySelectorAll('[title="Live chat button"]')
    chatButtons?.forEach((item) => item?.parentElement?.removeChild(item))

    const emailButtons = document?.querySelectorAll('[title="Contact us button"]')
    emailButtons?.forEach((item) => item?.parentElement?.removeChild(item))
  }

  useEffect(() => {
    if (!isBrowser()) {
      return
    }
    if (isEnabled === false) {
      removeLiveAgentScript()
    } else if (isEnabled) {
      addLiveAgentScript()
    }
  }, [isEnabled])

  const handleLiveAgentScript = async () => {
    const path = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname
    let findPage = false
    availableRoutes.map(({ path_of_page, include_subpages }) => {
      if (
        (!include_subpages && path_of_page === path) ||
        (!include_subpages &&
          removeLocaleFromURL(path_of_page) === removeLocaleFromURL(path)) ||
        (include_subpages && path.includes(path_of_page))
      ) {
        findPage = true
      }
    })

    setIsEnabled(findPage)
  }

  useEffect(() => {
    handleLiveAgentScript()
  }, [availableRoutes, pathname])

  return <></>
}

export default LiveAgentScript
