import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import ChevronDown from '../../../images/icon_chevron_down_white.svg'

export const StyledFooterButtonWrapper = styled.div`
  padding: 0.625rem 0 0.625rem 0.93rem;
  background-color: ${themeStyles.colors.darkGreys[4].main};
  border-radius: 6px;
  width: 12.5rem;

  * {
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
    color: ${themeStyles.colors.white.main};
  }

  > * {
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    outline: none;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  ${themeMedia.maxTablet`
    width: 80vw;
  `}
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 2rem auto;

  ${themeMedia.widescreen`
    max-width: 1200px;
    padding: 0;
  `}

  ${themeMedia.maxTablet`
    flex-direction: column;
    gap: 10px;
  `}
`

export const StyledFieldDropdown = styled.div`
  div {
    margin-bottom: 0;
  }

  div > select {
    width: calc(12.5rem - 34px);
    background-color: ${themeStyles.colors.darkGreys[4].main} !important;
    background: url('${ChevronDown}') no-repeat right 0.5rem center;
    border: none;
    padding: 0;
    height: 100%;

    &:focus {
      background-color: ${themeStyles.colors.darkGreys[4].main};
      color: ${themeStyles.colors.white.main};
    }

    ${themeMedia.maxTablet`
      width: calc(80vw - 34px);
    `}

    &:not(:placeholder-shown) {
      background-color: ${themeStyles.colors.darkGreys[4].main};
    }
  }
`
