import styled, { css } from 'styled-components'

export const Flex = styled.div`
  display: flex;

  ${({ height }) =>
    !!height &&
    css`
      height: ${height};
    `}

  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}

  ${({ justifyContent }) =>
    !!justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignItems }) =>
    !!alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ gap }) =>
    !!gap &&
    css`
      gap: ${gap};
    `}

  ${({ direction }) =>
    !!direction &&
    css`
      flex-direction: ${direction};
    `}
`
