import { cookiePrefix } from '../utils/cookies'
import { isBrowser } from '../utils/generic'

const storageService = {
  setTokens: function (tokenObj) {
    localStorage.setItem(`${cookiePrefix}access_token`, tokenObj.access_token)
    localStorage.setItem(`${cookiePrefix}refresh_token`, tokenObj.refresh_token)
  },

  getAccessToken: function () {
    if (!isBrowser()) return
    return localStorage.getItem(`${cookiePrefix}access_token`)
  },

  getRefreshToken: function () {
    return localStorage.getItem(`${cookiePrefix}refresh_token`)
  },

  setRefreshTokenId: function (identifier) {
    localStorage.setItem(`${cookiePrefix}refresh_token_id`, identifier)
  },

  setRefreshingToken: function () {
    localStorage.setItem(`${cookiePrefix}refreshing_token`, true)
  },

  getRefreshingToken: function () {
    return localStorage.getItem(`${cookiePrefix}refreshing_token`)
  },

  setNextRefreshToken: function (timestamp) {
    localStorage.setItem(`${cookiePrefix}next_refresh_token`, timestamp)
  },

  getIsIntercomOpen: function () {
    return localStorage.getItem(`${cookiePrefix}isIntercomOpen`)
  },

  setIsIntercomOpen: function (value) {
    localStorage.setItem(`${cookiePrefix}isIntercomOpen`, value)
  },

  getIntercomOnBoot: function () {
    return localStorage.getItem(`${cookiePrefix}intercomOnBoot`)
  },

  setIntercomOnBoot: function (value) {
    localStorage.setItem(`${cookiePrefix}intercomOnBoot`, value)
  },

  getNextRefreshToken: function () {
    return localStorage.getItem(`${cookiePrefix}next_refresh_token`)
  },

  setUser: function (user) {
    localStorage.setItem(`${cookiePrefix}user`, user)
  },

  getUser: function () {
    if (!isBrowser()) return
    const userStorage = localStorage.getItem(`${cookiePrefix}user`)
    if (userStorage && userStorage !== 'undefined') {
      return JSON.parse(userStorage)
    }
  },

  setRequiredData: function (requiredData) {
    localStorage.setItem(`${cookiePrefix}requiredData`, requiredData)
  },

  getRequiredData: function () {
    if (!isBrowser()) return
    const data = localStorage.getItem(`${cookiePrefix}requiredData`)
    if (data && data !== 'undefined') return JSON.parse(data)
    return {}
  },

  setValue: function (key, value) {
    if (!isBrowser()) return
    localStorage.setItem(`${cookiePrefix}${key}`, value)
  },

  getValue: function (key) {
    if (!isBrowser()) return
    return localStorage.getItem(`${cookiePrefix}${key}`)
  },

  removeValue: function (key) {
    if (!isBrowser()) return
    return localStorage.removeItem(`${cookiePrefix}${key}`)
  },

  setSessionValue: function (key, value) {
    if (!isBrowser()) return
    sessionStorage.setItem(`${cookiePrefix}${key}`, value)
  },

  getSessionValue: function (key) {
    if (!isBrowser()) return
    return sessionStorage.getItem(`${cookiePrefix}${key}`)
  },

  clearIntercomData: function () {
    localStorage.removeItem(`${cookiePrefix}isIntercomOpen`)
    localStorage.removeItem(`${cookiePrefix}intercomOnBoot`)
  },

  clearTokens: function () {
    localStorage.removeItem(`${cookiePrefix}access_token`)
    localStorage.removeItem(`${cookiePrefix}refresh_token`)
    localStorage.removeItem(`${cookiePrefix}refresh_token_id`)
    localStorage.removeItem(`${cookiePrefix}refreshing_token`)
    localStorage.removeItem(`${cookiePrefix}next_refresh_token`)
    localStorage.removeItem(`${cookiePrefix}user`)
    localStorage.removeItem(`${cookiePrefix}requiredData`)
    localStorage.removeItem(`${cookiePrefix}activation-warning`)
    localStorage.removeItem(`${cookiePrefix}sow:showModal`)
    localStorage.removeItem(`${cookiePrefix}rollbackSettings`)
    localStorage.removeItem(`${cookiePrefix}cpf:reminder`)
    localStorage.removeItem(`${cookiePrefix}alreadyDeposited`)
    localStorage.removeItem(`${cookiePrefix}realityCheckStartTime`)
    localStorage.removeItem(`${cookiePrefix}showRealityCheckModal`)
    localStorage.removeItem(`${cookiePrefix}isWaitingVerification`)
  },

  setIsWaitingVerification: function () {
    const user = this.getUser()
    localStorage.setItem(`${cookiePrefix}isWaitingVerification`, user?.id)
  },

  getIsWaitingVerification: function () {
    const user = this.getUser()
    const isWaitingVerification = localStorage.getItem(
      `${cookiePrefix}isWaitingVerification`
    )
    return user?.id == isWaitingVerification
  },

  clearIsWaitingVerification: function () {
    localStorage.removeItem(`${cookiePrefix}isWaitingVerification`)
  },
}
export default storageService
