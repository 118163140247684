import { useState } from 'react'
import useResizeListener from './useResizeListener'

const useScreenWidth = (breakpoint) => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  useResizeListener(() => setWidth(window.innerWidth))

  return { width }
}

export default useScreenWidth
