import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import error_icon from '../../../images/error_icon.svg'
import success_icon from '../../../images/success_icon.svg'
import { brandConfig } from '../../../config/brandConfig'

const InputWrapperBase = css`
  box-sizing: border-box;
  padding: 12px 15px;
  width: 100%;
  max-width: 100%;
  line-height: initial;

  background-color: ${themeStyles.colors.lightGreys[1].main};
  color: ${themeStyles.colors.black.main};
  border: 2px solid ${themeStyles.colors.lightGreys[1].main};
  border-radius: 6px;
`

const InputDisabled = css`
  background-color: ${themeStyles.colors.lightGreys[2].main};
  border: 0;

  /* Override iOS disabled css */
  -webkit-text-fill-color: ${themeStyles.colors.lightGreys[4].main};
  -webkit-opacity: 1;
  opacity: 1;
  color: ${themeStyles.colors.lightGreys[4].main};
`

const InputInvalid = css`
  background-color: ${themeStyles.colors.white.main};
  border-color: ${themeStyles.colors.error.main};

  background-image: url('${error_icon}');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 32px;
  padding-right: 2.5em;
`

const InputValid = css`
  &.focus {
    background-color: ${themeStyles.colors.white.main};
    border-color: ${themeStyles.colors.accentColors.primary.main};
  }

  background-image: url('${success_icon}');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 32px;
  padding-right: 2.5em;
`

const InputFocus = css`
  &.focus {
    background-color: ${themeStyles.colors.white.main};
    border-color: ${themeStyles.colors.products.sports.main};
  }
`

const WrapperCSS = css`
  ${InputWrapperBase}

  ${(props) => !props.valid && !props.invalid && !props.disabled && InputFocus}
  ${(props) => props.valid && InputValid}
  ${(props) => props.invalid && InputInvalid}

  &:focus-visible {
    outline: none;
  }

  &:not(:placeholder-shown) {
    background-color: ${themeStyles.colors.white.main};
  }

  &:disabled {
    ${InputDisabled}
  }
`

export const StyledInputError = styled.div`
  color: ${themeStyles.colors.error.main};
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

export const StyledInput = styled.input`
  width: 100%;
  font-size: ${brandConfig.fonts.text.depositInput.size.desktop};
  font-family: ${brandConfig.fonts.text.depositInput.family};
  color: ${themeStyles.colors.black.main};
  font-style: normal;
  font-weight: ${brandConfig.fonts.text.depositInput.weight};

  border: none;

  padding: 0;

  &::placeholder {
    color: ${themeStyles.colors.lightGreys[3].main};
  }

  &:focus-visible {
    outline: none;
  }
`

export const StyledInputPrefix = styled.span`
  font-size: ${brandConfig.fonts.text.depositInput.size.desktop};
  font-family: ${brandConfig.fonts.text.depositInput.family};
  color: ${themeStyles.colors.black.main};
  font-style: normal;
  font-weight: ${brandConfig.fonts.text.depositInput.weight};

  border: none;

  &:focus-visible {
    outline: none;
  }
`

export const StyledInputWrapper = styled.div`
  ${WrapperCSS}
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 4px;
`
