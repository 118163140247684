import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../atoms/button'
import { Title } from '../../atoms/text'
import {
  StyledBlockContent,
  StyledContent,
  StyledRemindMeLater,
  StyledSuccessIcon,
} from './styles'
import success_icon from '../../../images/success_icon.svg'
import { useTranslation } from '../../../context/translationProvider'
import { EnvConfig } from '../../../config/EnvConfig'

const CpfInvalidContent = ({ onClose }) => {
  const { translate } = useTranslation()

  const startChat = () => {
    onClose()
    try {
      document.querySelector('.circleChatButtonWrap').click()
    } catch (err) {
      location.href = `mailto:${EnvConfig.GATSBY_SUPPORT_EMAIL}`
    }
  }

  return (
    <StyledContent>
      <StyledBlockContent>
        <Title level={3}>{translate('cpf.validateIssue.title')}</Title>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>{translate('cpf.validateIssue.step1')}</p>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>
          <StyledSuccessIcon src={success_icon} alt="check" />
          {translate('cpf.validateIssue.check1')}
        </p>
        <p>
          <StyledSuccessIcon src={success_icon} alt="check" />
          {translate('cpf.validateIssue.check2')}
        </p>
        <p>
          <StyledSuccessIcon src={success_icon} alt="check" />
          {translate('cpf.validateIssue.check3')}
        </p>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>{translate('cpf.validateIssue.step2')}</p>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>{translate('cpf.validateIssue.step3')}</p>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>
          {translate('cpf.validateIssue.step4')}{' '}
          <a className="dark" href={`mailto:${EnvConfig.GATSBY_SUPPORT_EMAIL}`}>
            {EnvConfig.GATSBY_SUPPORT_EMAIL}
          </a>
        </p>
      </StyledBlockContent>

      <Button expand onClick={startChat}>
        {translate('cpf.validateIssue.button')}
      </Button>
      <StyledRemindMeLater onClick={onClose}>
        {translate('cpf.remindLater')}
      </StyledRemindMeLater>
    </StyledContent>
  )
}

CpfInvalidContent.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
}

CpfInvalidContent.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export { CpfInvalidContent }
