import React, { useState } from 'react'

import CookieSettings from '../../../templates/footer/cookieSettings'
import useModal from '../../../hooks/useModal'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { cookiePrefix, setCookie } from '../../../utils/cookies'
import { useTranslation } from '../../../context/translationProvider'
import { FieldDropdown } from '../fieldDropdown'
import { If } from '../../atoms/if'
import { Modal } from '../../atoms/modal'
import {
  StyledContainer,
  StyledFieldDropdown,
  StyledFooterButtonWrapper,
} from './styles'
import { getLinkRel } from '../../../utils/getLinkRel'

const FooterCookie = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const { footer_misc } = useFooterV2ApiData()
  const footerCookieMisc = footer_misc.find(
    (item) => item.acf_fc_layout === 'cookie_settings'
  )
  const [flagImage, setFlagImage] = useState('')
  const { translate } = useTranslation()
  if (!footerCookieMisc) return

  const onLanguageChange = (language) => {
    setCookie(`${cookiePrefix}market`, language, 365)
    setCookie('nolangredirect', 'true', 365)
    window.location.assign(`${process.env.GATSBY_KTO_URL}/${language}`)
  }

  const createCookieButton = (item, index) => {
    switch (item?.type) {
      case 'cookie_setting':
        return (
          <StyledFooterButtonWrapper key={`cookie-${index}`}>
            <button onClick={() => openModal()}>
              <img src={item.image.url} alt={item.image.alt} />
              {item.text}
            </button>
          </StyledFooterButtonWrapper>
        )
      case 'button':
        return (
          <StyledFooterButtonWrapper key={`btn-wrapper-${index}`}>
            <a
              href={item?.link?.url}
              target={item?.link?.target}
              rel={getLinkRel(item?.link?.target)}
            >
              <img src={item.image.url} alt={item.image.alt} />
              {item?.text}
            </a>
          </StyledFooterButtonWrapper>
        )
      case 'country_dropdown':
        return (
          <StyledFooterButtonWrapper key={`cookie-${index}`}>
            <StyledFieldDropdown>
              <img src={flagImage ? flagImage : item.countries[0].flag.url} />
              <FieldDropdown
                removeFirstOption
                defaultValue={item.countries[0].name}
                values={item.countries.map((country, i) => (
                  <option
                    key={i}
                    value={country.name}
                    data-code={country.code}
                    data-url={country.flag.url}
                  >
                    {country.name}
                  </option>
                ))}
                onChange={(e) => {
                  onLanguageChange(
                    e.target.options[e.target.selectedIndex].getAttribute(
                      'data-code'
                    )
                  )
                  setFlagImage(
                    e.target.options[e.target.selectedIndex].getAttribute(
                      'data-url'
                    )
                  )
                }}
              />
            </StyledFieldDropdown>
          </StyledFooterButtonWrapper>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      <StyledContainer>
        {footerCookieMisc?.items.map(createCookieButton)}
      </StyledContainer>

      <If
        condition={isOpen}
        render={() => (
          <Modal
            title={translate('common.cookie.settings')}
            isOpen={isOpen}
            onClose={closeModal}
          >
            <CookieSettings onClose={closeModal} />
          </Modal>
        )}
      />
    </div>
  )
}

export { FooterCookie }
