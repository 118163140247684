import React from 'react'

import { If } from '../if'
import { ResponsiveImage } from '../responsiveImage'
import { Container } from '../container'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import { StyledLogoWrapper } from './styles'
import { getLinkRel } from '../../../utils/getLinkRel'

const FooterLogo = () => {
  const { footer_misc } = useFooterV2ApiData()
  const { logo, link } = footer_misc.find(
    (item) => item.acf_fc_layout === 'logo'
  )

  if (!logo) return

  const renderImage = () => {
    return <ResponsiveImage loading="lazy" image={logo} showAltOnHover />
  }

  return (
    <div>
      <Container>
        <StyledLogoWrapper>
          <If
            condition={link?.url}
            render={() => (
              <a
                href={link?.url}
                target={link?.target}
                rel={getLinkRel(link?.target)}
              >
                {renderImage()}
              </a>
            )}
            renderElse={() => {
              renderImage()
            }}
          />
        </StyledLogoWrapper>
      </Container>
    </div>
  )
}

export { FooterLogo }
