import React, { useEffect } from 'react'
import styled from 'styled-components'

import { themeStyles } from '../../styles/theme'
import { StyledH1 } from '../../atomic-components/atoms/text/styles'
import { Layout } from '../../atomic-components/organisms/layout'
import { Button } from '../../atomic-components/atoms/button'

import { useTranslation } from '../../context/translationProvider'

const Container = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  color: ${themeStyles.colors.white.main};
  background: ${themeStyles.colors.black.main};
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`

export const ErrorFallback = ({ error }) => {
  const { translate } = useTranslation()

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name)
          })
        })
      }
    }

    // try {
    //   /* eslint-disable no-undef */
    //   Sentry.configureScope((scope) => {
    //     Object.keys(error).forEach((key) => {
    //       scope.setExtra(key, error?.[key])
    //     })
    //   })
    //   Sentry.captureException('ErrorBoundary: ' + error)
    // } catch (ex) {
    //   console.error(ex)
    // }
  }, [error])

  return (
    <Layout>
      <Container>
        <StyledH1>{translate('errors.500')}</StyledH1>
        <p>{error?.message}</p>
        <Button style={{ width: '200px', margin: '0 auto' }} href={'/'}>
          {translate('common.back')}
        </Button>
      </Container>
    </Layout>
  )
}
