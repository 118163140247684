import { cookiePrefix } from './cookies'
import { isBrowser, removeLocaleFromURL } from './generic'

const history_key = `${cookiePrefix}user_history`

export const getFullHistory = () => {
  const historyRaw = sessionStorage.getItem(history_key)
  if (!historyRaw) return []
  return JSON.parse(historyRaw)
}

export const getPreviousPage = () => {
  if (!isBrowser()) return

  const history = getFullHistory()
  const filteredHistory = history.filter((page) => page !== '/login/')

  if (filteredHistory.length < 2) {
    return removeLocaleFromURL(localStorage.getItem('lobby'))
  }

  return filteredHistory[filteredHistory.length - 2]
}

export const addCurrentPage = () => {
  if (!isBrowser()) return

  const fullHistory = getFullHistory()
  const currentPath = removeLocaleFromURL(window.location.pathname)
  const maxNumberOfHistory = 10

  if (
    fullHistory.length == 0 ||
    fullHistory[fullHistory.length - 1] != currentPath
  ) {
    fullHistory.push(currentPath)
  }

  if (
    fullHistory.length > 2 &&
    fullHistory[fullHistory.length - 3] == currentPath
  ) {
    fullHistory.pop()
    fullHistory.pop()
  }

  if (fullHistory.length > maxNumberOfHistory) {
    fullHistory.splice(0, fullHistory.length - maxNumberOfHistory)
  }

  sessionStorage.setItem(history_key, JSON.stringify(fullHistory))
}
