import React from 'react'

import { FooterMenuMobile } from '../footerMenuMobile'
import { FooterMenuDesktop } from '../footerMenuDesktop'

const FooterMenu = () => {
  return (
    <>
      <FooterMenuMobile />
      <FooterMenuDesktop />
    </>
  )
}

export { FooterMenu }
