import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledMainWrapper = styled.div`
  max-width: 100%;
  padding: 0 1rem;

  ${themeMedia.widescreen`
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
  `}
`
