import styled from 'styled-components'

import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledPagination = styled.div`
  display: flex;
  gap: 0.1em;
  justify-content: center;
  align-items: flex-end;
  height: 1em;

  ${themeMedia.desktop`
    align-items: center;
    height: 2em;
  `}
`

export const StyledDot = styled.div`
  position: relative;
  height: 8px;
  width: 8px;
  padding: 0.25em;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'initial')};

  &:after {
    content: '';
    display: block;
    top: 0;
    left: 0;

    background-color: ${themeStyles.colors.darkGreys[4].main};
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }

  &.active {
    &:after {
      background-color: ${themeStyles.colors.primary.main};
    }
  }
`
