import { apiGET } from '../utils/adapterFunctions'

const baseUrl = '/maps/api/place'

export const getPlacesAutocomplete = async (input, components, types) => {
  const params = { input, components, types }
  return await apiGET(
    `${baseUrl}/autocomplete/json`,
    { params },
    { hasNestedData: false }
  )
}

export const getPlaceDetail = async (place_id) => {
  const params = { place_id }
  return await apiGET(
    `${baseUrl}/details/json`,
    { params },
    { hasNestedData: false }
  )
}
