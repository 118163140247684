import storageService from '../../services/storageService'
import { parseQueryString, removeLocaleFromURL } from '../generic'
import { MOBILE_FEATURES, MOBILE_FEATURE_NAMES } from './constants'

export const getMobileAppVersion = () => {
  if (typeof window === 'undefined') return 0

  const regex = /KTOMobileApp_(\d+)/
  const match = window.navigator.userAgent.match(regex)

  if (match && match[1]) return match[1]
  return 0
}

export const canUseMobileFeature = (featureKey) => {
  const version = getMobileAppVersion()
  if (!version) return false
  const { minimumVersion, maximumVersion } = MOBILE_FEATURES[featureKey] ?? {}
  return (
    (!minimumVersion || version >= minimumVersion) &&
    (!maximumVersion || version <= maximumVersion)
  )
}

export const isiOS = () => {
  if (typeof window === 'undefined') return false
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
}

export const isMobileApp = () => {
  if (typeof window === 'undefined') return false
  return !!window.navigator.userAgent.match(/KTOMobileApp_(\d+)/)
}

/**
 *
 * @param {key that will be used with MOBILE_FEATURE_NAMES} featureKey
 * @returns Did it manage to send the event
 */
export const sendMobileAppEvent = (featureKey, body = {}) => {
  if (!MOBILE_FEATURE_NAMES[featureKey]) {
    console.error('Feature key does not exist!')
    return false
  }
  if (
    window.KTOMobileApp?.postMessage &&
    canUseMobileFeature(MOBILE_FEATURE_NAMES[featureKey])
  ) {
    window.KTOMobileApp?.postMessage(
      JSON.stringify({ event: MOBILE_FEATURE_NAMES[featureKey], ...body })
    )
    return true
  }
  return false
}

export const updateMobileLocation = (location) => {
  if (!isMobileApp()) return

  const slugs = JSON.parse(storageService.getValue('mobileTabsSlugs'))
  if (!slugs) return

  const basePath = removeLocaleFromURL(location.pathname).split('/')[1]
  switch (basePath) {
    case slugs.totoSlug:
    case slugs.virtualSportsSlug:
    case slugs.liveSportsSlug:
    case slugs.sportsSlug: {
      const whitelistedPages = ['overview', 'live', 'betHistory', 'allSports']
      const page = parseQueryString(location.search)?.page
      if (page && whitelistedPages.includes(page)) {
        sendMobileAppEvent('activeTab', { active: page })
      } else if (!page) {
        if (basePath === slugs.liveSportsSlug) {
          sendMobileAppEvent('activeTab', { active: 'live' })
        } else {
          sendMobileAppEvent('activeTab', { active: 'overview' })
        }
      }
      break
    }
    case slugs.liveCasinoSlug:
    case slugs.casinoSlug:
      sendMobileAppEvent('activeTab', { active: 'casino' })
      break
    case slugs.promotionsSlug:
      sendMobileAppEvent('activeTab', { active: 'promotions' })
      break
  }
}
