import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import useModal from '../../../hooks/useModal'
import { AuthContext } from '../../../context/authProvider'
import { useTranslation } from '../../../context/translationProvider'
import { StyledTopBanner } from './styles'
import { Text } from '../../atoms/text'
import { Button, If } from '@techmobilt/ui-components'
import { navigate } from 'gatsby'
import { getUserLimits, getUserScheduledLimits } from './../../../adapters/user'
import { Modal } from '../../atoms/modal'
import iconCoolOff from './../../../images/icon_cool_off.svg'
import { getFormattedDate } from '../../../utils/datetime'
import { addMinutes } from 'date-fns'
import { ModalContent } from './styles'
import { Image } from '../../atoms/image'

const CoolOffBanner = ({ page }) => {
  const { translate } = useTranslation()
  const { isLoggedIn } = useContext(AuthContext)
  const { openModal, closeModal, isOpen } = useModal()
  const [showBanner, setShowBanner] = useState(false)
  const [coolOffData, setCoolOffData] = useState(null)
  const [coolOffScheduleLimitData, setCoolOffScheduleLimitData] = useState(null)

  const modalPages = [
    'overview',
    'live',
    'casino',
    'live-casino',
    'toto',
    'virtual',
    'bingo',
  ]

  useEffect(() => {
    if (isLoggedIn) {
      fetchData()
    } else {
      setShowBanner(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (coolOffData) {
      fetchUserScheduledLimits()
    }
  }, [coolOffData])

  const getExpiryDate = () => {
    if (coolOffData) {
      const startDate = coolOffScheduleLimitData
        ? coolOffScheduleLimitData.execute_at
        : coolOffData.updated_at
      const duration = coolOffScheduleLimitData
        ? coolOffScheduleLimitData.duration
        : coolOffData.duration
      const createdDate = new Date(startDate)
      const formattedExpirationDate = getFormattedDate(
        addMinutes(createdDate, duration),
        'DD/MM/YYYY'
      )
      return formattedExpirationDate
    }
  }

  const fetchData = async () => {
    const response = await getUserLimits()
    if (response.ok) {
      const coolOffLimit = response.data.data.find(
        (limit) => limit.type === 'COOL_OFF_LIMIT'
      )
      setShowBanner(!!coolOffLimit)
      setCoolOffData(coolOffLimit)
      if (coolOffLimit && modalPages.includes(page)) {
        openModal()
      } else {
        closeModal()
      }
    }
  }

  const fetchUserScheduledLimits = async () => {
    const response = await getUserScheduledLimits(coolOffData.id)
    if (response.ok && response.data.data.length > 0) {
      setCoolOffScheduleLimitData(response.data.data[0])
    } else {
      setCoolOffScheduleLimitData(null)
    }
  }

  return (
    <>
      <If
        condition={showBanner && coolOffData}
        render={() => (
          <StyledTopBanner>
            <Text>
              {translate('account.limits.topBannerText')?.replace(
                '{0}',
                getExpiryDate()
              )}
            </Text>
            <Button
              onClick={() =>
                navigate('/account/responsible/', {
                  state: {
                    coolOffVisible: true,
                  },
                })
              }
            >
              {translate('navigation.account')}
            </Button>
          </StyledTopBanner>
        )}
      />
      <Modal
        title={translate('account.limits.coolOff')}
        theme="payments"
        onClose={closeModal}
        isOpen={isOpen}
      >
        <ModalContent>
          <Text
            textAlign="center"
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            <b>{translate('account.limits.restrictedAccount')}</b>
          </Text>
          <Image
            url={iconCoolOff}
            width={100}
            style={{ margin: '0 auto 30px auto' }}
          />
          <Text textAlign="center" style={{ marginBottom: '30px' }}>
            <span
              dangerouslySetInnerHTML={{
                __html: translate('account.limits.coolOffModalText')?.replace(
                  '{0}',
                  `<b>${getExpiryDate()}</b>`
                ),
              }}
            ></span>
          </Text>
          <Button onClick={() => closeModal()}>
            {translate('account.requestMyDataConfirmationButton')}
          </Button>
        </ModalContent>
      </Modal>
    </>
  )
}
CoolOffBanner.propTypes = {
  page: PropTypes.string.isRequired,
}

export default CoolOffBanner
