import { useStaticQuery, graphql } from 'gatsby'

export const useDepositApiData = () => {
  const data = useStaticQuery(graphql`
    {
      depositApi {
        values {
          content {
            header_text
            offer_section {
              title
              description
              icon {
                alt
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  const depositApi = data?.depositApi?.values?.[0]?.content?.[0]

  const headerText = depositApi?.header_text
  const offerSection = depositApi?.offer_section

  return {
    headerText,
    offerSection,
  }
}
