import { graphql, useStaticQuery } from 'gatsby'

export const useCashbackData = () => {
  const { cashbackDataApi } = useStaticQuery(graphql`
    {
      cashbackDataApi {
        values {
          cashbackData {
            title
            subtitle
            image {
              url
              width
            }
            cashback_cards {
              title
              text_color
              identifier
              expiry_reminder
              background_color
              icon {
                url
                width
              }
              background_image {
                width
                url
              }
            }
          }
        }
      }
    }
  `)

  const cashbackContent = cashbackDataApi?.values?.[0]?.cashbackData?.[0]
  const cardsTemplates =
    cashbackDataApi?.values?.[0]?.cashbackData?.[0]?.cashback_cards

  const getCardTemplate = (type) => {
    const findTemplate = cardsTemplates?.find(
      (item) => item?.identifier === type
    )
    return {
      icon: findTemplate?.icon?.url,
      title: findTemplate?.title,
      backgroundImage: findTemplate?.background_image?.url,
      backgroundColor: findTemplate?.background_color,
      color: findTemplate?.text_color,
      expiryReminder: findTemplate?.expiry_reminder,
    }
  }

  return {
    cashbackContent,
    getCardTemplate,
  }
}
