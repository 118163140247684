import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledLicenseWrapper = styled.div`
  gap: 20px;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 0;
    font-size: 15px;
  }

  ${themeMedia.maxTablet`
    flex-direction: column;
    align-items: center;
    
    p {
      text-align: center;
      margin-bottom: 1rem;
    }
  `};
`

export const StyledLicenseLogo = styled.div`
  gap: 12px;

  img {
    height: 75px !important;
    width: auto !important;
  }

  ${themeMedia.maxTablet`
    margin: 1rem auto;
  `};

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
`
