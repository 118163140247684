import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from '../../atomic-components/atoms/button'
import { toast } from '../../atomic-components/atoms/toast'
import { Checkbox } from '../../atomic-components/atoms/checkbox'
import useCookie from '../../hooks/useCookie'

import { themeStyles, themeMedia } from '../../styles/theme'
import { useTranslation } from '../../context/translationProvider'
import { brandConfig } from '../../config/brandConfig'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 0 1rem;
  ${themeMedia.tablet`
    padding: 0;
  `}
`

const H1 = styled.h1`
  font-size: 1.6em;
  font-weight: 600;
  margin: 2rem 0 1rem 0;
`

const P = styled.p`
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  line-height: 1.2em;
  margin: 0.5rem 0 1rem 0;

  a {
    color: ${themeStyles.colors.black.main};
  }
`

const CheckboxWrapper = styled.div`
  background-color: ${themeStyles.colors.lightGreys[1].main};
  border-radius: 6px;
  padding: 0.3rem;
  margin-bottom: 1rem;

  > div {
    align-items: center;
    margin: 0 0 0 0.4rem;
  }

  ${({ disabled }) =>
    disabled &&
    `
    > div {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
  text-align: center;
`

const CookieSettings = (props) => {
  const { translate } = useTranslation()
  const [cookie, updateCookie] = useCookie('caccept', '')
  const [paCookie, updatePaCookie] = useCookie('paccept', '')

  // Force check if user opens cookie settings modal from the main banner.
  const [cookieChecked, setCookieChecked] = useState(
    props.forceCheck ? true : cookie === 'on'
  )

  const [paCookieChecked, setPaCookieChecked] = useState(
    props.forceCheck ? true : paCookie === 'on'
  )

  const onInputChange = () => {
    setCookieChecked((c) => !c)
  }

  const onPaInputChange = () => {
    setPaCookieChecked((c) => !c)
  }

  const onSubmit = () => {
    if (props.onClose) {
      props.onClose()
    }
    updateCookie(cookieChecked ? 'on' : 'off', 365)
    updatePaCookie(paCookieChecked ? 'on' : 'off', 365)
    if (props.onUpdate) {
      props.onUpdate()
    }
    toast.success(translate('common.cookie.saveSettings'))
  }

  return (
    <Wrapper>
      <H1>
        <span>{translate('common.cookie.necessaryCookies')}</span>
      </H1>
      <P
        dangerouslySetInnerHTML={{
          __html: translate('common.cookie.necessaryCookiesDisclaimer'),
        }}
      />

      <CheckboxWrapper disabled>
        <Checkbox
          formProperties={{
            name: 'necessaryCookies',
          }}
          checked
          disabled
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.necessaryCookies'),
            }}
          />
        </Checkbox>
      </CheckboxWrapper>

      <H1>
        <span>{translate('common.cookie.advertCookies')}</span>
      </H1>
      <P>
        <span>{translate('common.cookie.advertCookiesDisclaimer')}</span>
      </P>

      <CheckboxWrapper onClick={onInputChange}>
        <Checkbox
          formProperties={{
            name: 'advertCookies',
          }}
          checked={cookieChecked}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.advertCookies'),
            }}
          />
        </Checkbox>
      </CheckboxWrapper>

      <H1>
        <span>{translate('common.cookie.performanceAnalyticsCookies')}</span>
      </H1>
      <P>
        <span>
          {translate('common.cookie.performanceAnalyticsCookiesDisclaimer')}
        </span>
      </P>

      <CheckboxWrapper onClick={onPaInputChange}>
        <Checkbox
          formProperties={{
            name: 'performanceAnalyticsCookies',
          }}
          checked={paCookieChecked}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: translate('common.cookie.performanceAnalyticsCookies'),
            }}
          />
        </Checkbox>
      </CheckboxWrapper>

      <ButtonWrapper>
        <Button onClick={onSubmit} primary>
          {translate('common.saveSettings')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

CookieSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  forceCheck: PropTypes.bool,
}

export default CookieSettings
