import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledTopBanner = styled.div`
  display: flex;
  min-height: 56px;
  background-color: #343434;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  button {
    height: 40px;
    display: flex;
    justify-content: center;
  }
  ${themeMedia.maxTablet`
    display: block;
    padding: 15px;
    button {
      width: 100%;
      margin-top: 10px;
    }
  `}
  p {
    margin-bottom: 0;
    color: #fff;
    font-size: 12px;
  }
`
export const ModalContent = styled.div`
  padding: 24px;
  text-align: center;
  button {
    width: 492px;
    max-width: 100%;
    text-transform: none;
    ${themeMedia.maxTablet`
      position: absolute;
      width: calc(100% - 48px);
      left: 24px;
      right: 0;
      bottom: 30px; 
      margin-top: 10px;
    `}
  }
`
