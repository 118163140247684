import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledChildWrapper,
  StyledNavigationTab,
  StyledRow,
  StyledWrapper,
} from './styles'

const Tabs = (props) => {
  const { children, expandChildren, underlineChildren, hideGap } = props
  return (
    <StyledWrapper>
      <StyledRow expand={expandChildren} hideGap={hideGap}>
        {children?.map((child, i) => (
          <StyledChildWrapper
            key={`menu_item-${i}`}
            expand={expandChildren}
            underlineChildren={underlineChildren}
            active={child?.props?.active === child?.props?.value}
          >
            {child}
          </StyledChildWrapper>
        ))}
      </StyledRow>
    </StyledWrapper>
  )
}

const Tab = ({ label, theme = '', value, onSelect, active }) => {
  return (
    <button
      role="link"
      onClick={() => onSelect(value)}
      className={`link ${value === active ? 'active' : ''} ${theme}`}
      style={{ padding: '0.8rem 20px', paddingBottom: '6px' }}
    >
      {label}
    </button>
  )
}

const NavigationTab = ({ label, theme = '', value, onSelect, active }) => {
  return (
    <StyledNavigationTab
      role="link"
      onClick={() => onSelect(value)}
      className={theme}
    >
      <span className={active === value ? 'active' : ''}>{label}</span>
    </StyledNavigationTab>
  )
}

Tabs.defaultProps = {
  children: [],
  expandChildren: false,
  underlineChildren: false,
  hideGap: false,
}

Tabs.propTypes = {
  children: PropTypes.any,
  expandChildren: PropTypes.bool,
  underlineChildren: PropTypes.bool,
  hideGap: PropTypes.bool,
}

Tab.defaultProps = {
  label: '',
  theme: '',
  value: 0,
  active: 0,
  onSelect: () => {},
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.string,
  value: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
}

NavigationTab.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.string,
  value: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export { Tabs, Tab, NavigationTab }
