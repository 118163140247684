import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useContext,
  createContext,
} from 'react'

import { AuthContext } from './authProvider'
import { getRollbackWithdrawalSettings } from '../adapters/settings'

import {
  getDepositPaymentProviders,
  getPaymentWithdrawalLobby,
} from '../adapters/payments'

import storageService from '../services/storageService'
import { isNullOrUndefined } from '../helpers/falsyHelper'

const PaymentsContext = createContext()

const ActionTypes = {
  SET_CURRENT_METHOD: 'SET_CURRENT_METHOD',
  SET_COUNTRIES: 'SET_COUNTRIES',
}

const paymentsReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_METHOD: {
      return {
        ...state,
        currentMethod: action.payload,
      }
    }

    default:
      throw new Error(`Action is not supported: ${action.type}`)
  }
}

const initialState = {
  currentMethod: null,
}

export const PaymentsProvider = (props) => {
  const { isLoggedIn } = useContext(AuthContext)
  const [state, dispatch] = useReducer(paymentsReducer, initialState)
  const [depositPaymentMethods, setDepositPaymentMethods] = useState()
  const [withdrawalPaymentMethods, setWithdrawalPaymentMethods] = useState()
  const [
    isRollbackWithdrawalAllowed,
    setIsRollbackWithdrawalAllowed,
  ] = useState(false)

  const getRollback = async () => {
    const value = await getRollbackWithdrawalSettings()
    setIsRollbackWithdrawalAllowed(value)
    storageService.setValue('rollbackSettings', JSON.stringify(value))
  }

  useEffect(() => {
    const initialRollbackValue = JSON.parse(
      storageService.getValue('rollbackSettings')
    )

    if (isLoggedIn) {
      isNullOrUndefined(initialRollbackValue) ?
        getRollback() :
        setIsRollbackWithdrawalAllowed(initialRollbackValue)
    }
  }, [isLoggedIn])

  const getPaymentsByType = async (type) => {
    type = type?.toUpperCase()

    if (type === 'DEPOSIT') {
      const { data } = await getDepositPaymentProviders()
      setDepositPaymentMethods(data)
    }
    if (type === 'WITHDRAWAL') {
      const { data } = await getPaymentWithdrawalLobby()
      setWithdrawalPaymentMethods(data?.methods)
    }
  }

  const value = useMemo(
    () => ({
      state,
      dispatch,
      depositPaymentMethods,
      withdrawalPaymentMethods,
      getPaymentsByType,
      isRollbackWithdrawalAllowed,
    }),
    [
      state,
      depositPaymentMethods,
      withdrawalPaymentMethods,
      isRollbackWithdrawalAllowed,
    ]
  )

  return <PaymentsContext.Provider value={value} {...props} />
}

export const usePaymentsContext = () => {
  const context = useContext(PaymentsContext)

  if (!context) {
    throw new Error('usePaymentsContext must be used inside a PaymentsProvider')
  }

  const {
    state,
    dispatch,
    depositPaymentMethods,
    withdrawalPaymentMethods,
    getPaymentsByType,
    isRollbackWithdrawalAllowed,
  } = context
  const { currentMethod } = state

  const setCurrentMethod = (method) => {
    dispatch({ type: ActionTypes.SET_CURRENT_METHOD, payload: method })
  }

  return {
    depositPaymentMethods,
    withdrawalPaymentMethods,
    getPaymentsByType,
    currentMethod,
    setCurrentMethod,
    isRollbackWithdrawalAllowed,
  }
}
