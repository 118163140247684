import React from 'react'

import ToolsIcon from '../../../images/icon_tools.svg'
import ExclamationCircleIcon from '../../../images/icon_exclamation_circle_white.svg'
import InfoCircleIcon from '../../../images/icon_info_circle.svg'
import { useMaintenanceApiData } from '../../../hooks/graphqlStaticQuery/useMaintenanceApiData'
import { StyledImg, StyledNoticeText, StyledWrapper } from './styles'
import { If } from '../if'

const Maintenance = () => {
  const { notices } = useMaintenanceApiData()

  if (notices.length === 0) return null

  return (
    <StyledWrapper>
      <If
        condition={notices[0]?.type === 'issue'}
        render={() => <StyledImg src={ExclamationCircleIcon} alt="Issue" />}
      />

      <If
        condition={notices[0]?.type === 'maintenance'}
        render={() => <StyledImg src={ToolsIcon} alt="Maintenance" />}
      />

      <If
        condition={notices[0]?.type === 'info'}
        render={() => <StyledImg src={InfoCircleIcon} alt="Info" />}
      />

      <StyledNoticeText
        dangerouslySetInnerHTML={{ __html: notices[0]?.message }}
      />
    </StyledWrapper>
  )
}

export { Maintenance }
