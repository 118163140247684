import React from 'react'
import styled from 'styled-components'
import { Container } from '../../atomic-components/atoms/container'

const ImageWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 2rem;

  > a {
    display: inline-block;
    flex: 0 0 45px;
    margin: 0 1rem 0 0;
    width: 45px;

    &:last-child {
      margin: 0;
    }
  }
`

const SocialMediaLinks = ({ data }) => {
  return (
    <Container>
      <ImageWrapper>
        {data?.map((socialMediaLink, i) => {
          return (
            <a key={i} href={`${socialMediaLink.destinationUrl}`}>
              <img
                src={`${socialMediaLink.iconLocation}`}
                alt={`${socialMediaLink.linkDescription}`}
                height={socialMediaLink.height}
                width={socialMediaLink.width}
              />
            </a>
          )
        })}
      </ImageWrapper>
    </Container>
  )
}

export default SocialMediaLinks
