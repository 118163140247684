import { createGlobalStyle, css } from 'styled-components'

import { themeStyles, themeMedia } from '../theme'

import { loadingTransitionFinished } from '../../atomic-components/atoms/loadingScreen/styles'
import { brandConfig } from '../../config/brandConfig'

const reset = css`
  html,
  body,
  figure,
  iframe {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  html {
    box-sizing: border-box;
  }
  body {
    line-height: 1;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: 'tnum';
    -moz-font-feature-settings: 'tnum';
    -ms-font-feature-settings: 'tnum';
    -webkit-font-feature-settings: 'tnum';
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  button {
    border: 0;
    font-size: 1em;
  }
`

const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    --header-size: 50px;
    --header-size-expanded: 90px;

    ${themeMedia.navbarBreakpointTabletDesktop`
      --header-size: 60px;
    `}
  }

  body {
    background-color: ${themeStyles.theme.background};
    font-family: ${brandConfig.fonts.text.body.family};
    font-size: ${brandConfig.fonts.text.body.size.desktop};
    font-display: swap;

    &.overflow-hidden {
      overflow: hidden;
    }

    .grecaptcha-badge { visibility: hidden; }

    &:has(.loading-screen-atom) main {
      transform-origin: 50% 0;
      transition-timing-function: ease-in-out;
      transition: 400ms;
      transition-delay: ${loadingTransitionFinished / 2}ms;
      transform: scale(0.8);
      opacity: 0.5;
    }
    &:has(.loading-screen-hidden) main {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

  h1,
  h2 {
    font-weight: 900;
  }

  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 600;
  }

  button {
    font-family: ${brandConfig.fonts.text.button.family};
    font-display: swap;
    line-height: 1;
    cursor: pointer;
  }

  form {
    label {
      margin: 0 0 .2rem 0;
      display: block;
      font-family: ${brandConfig.fonts.text.label.family};
      font-display: swap;
    }

    small {
      color: ${themeStyles.colors.lightGreys[4].main};
      display: block;
      line-height: 145%;
    }

    p {
      text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      font-weight: 600;
      font-size: 1.5em;
    }


    ${themeMedia.desktop`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 2em;
      }
    `};
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="submit"],
  input[type="button"] {
    cursor: pointer;
    background-color: ${themeStyles.colors.accentColors.primary.main};
    color: ${themeStyles.colors.white.main};
    text-transform: uppercase;
    letter-spacing: 2px;
    border-color: ${themeStyles.colors.accentColors.primary.main};
    margin: 2rem 0 0 0;
    appearance: button;
    font-weight: 600;

    &.secondary {
      background-color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.black.main};
      color: ${themeStyles.colors.black.main};
      width: auto;
      padding: 0 2rem;
    }

    &.disabled {
      background-color: ${themeStyles.colors.lightGreys[3].main};
      border-color: ${themeStyles.colors.lightGreys[3].main};
      color: ${themeStyles.colors.lightGreys[3].main};
      opacity: .65;
      cursor: initial;
    }

  ${themeMedia.maxTablet`
    letter-spacing: initial;
  `}
  }

  .ReactModal__Overlay {
    background-color: rgba(0,0,0,0.5) !important;
  }

  .i-kb-search {
    display: none !important;
  }

  .Toastify__toast-container {
    width: 100%;
    z-index: 99999999;

    .Toastify__toast-body,
    .Toastify__toast {
      padding: 0;
    }

    .Toastify__toast {
      max-height: 100px;
      min-height: 50px;

      &.info {
        background-color: ${themeStyles.colors.info.main};
        color: ${themeStyles.colors.black.main};
  
        .toast {
          max-width: initial;
          background-color: ${themeStyles.colors.info.main};
          color: ${themeStyles.colors.black.main};
        }
      }
  
      &.error {
        background-color: ${themeStyles.colors.error.main};
        color: ${themeStyles.colors.black.main};
  
        .toast {
          background-color: ${themeStyles.colors.error.main};
          color: ${themeStyles.colors.black.main};
        }
      }
  
      &.success {
        background-color: ${themeStyles.colors.accentColors.primary.main};
        color: ${themeStyles.colors.accentColors.primary.contrast};
  
        .toast {
          background-color: ${themeStyles.colors.accentColors.primary.main};
          color: ${themeStyles.colors.accentColors.primary.contrast};
        }
      }
  
      &.warn {
        background-color: ${themeStyles.colors.info.main};
        color: ${themeStyles.colors.black.main};
  
        .toast {
          background-color: ${themeStyles.colors.info.main};
          color: ${themeStyles.colors.black.main};
        }
      }
    }

    .Toastify__toast-body {
      > div {
        width: 100%;
      }
    }

    .Toastify__progress-bar {
      display: none; 
    }
  }
  .toast-close-button {
    width: 15px;
    align-self: center;
    padding-right: 15px;
  }
  .tl-wrapper-status--exiting {
    transition: 0.34s;
    opacity: 0 !important;
    transform: scale(1.05);
    transform-origin: 50vw 50vh;
  }
  .tl-wrapper-status--entering {
    opacity: 0 !important;
  }
  .tl-wrapper-status--entered {
    opacity: 1;
    transition: 0.44s;
  }

  a.dark {
    color: ${themeStyles.colors.black.main};
  }
  a.error {
    color: ${themeStyles.colors.error.main};
  }

  ${themeMedia.desktop`
    .Toastify__toast-container {
      width: 520px;
    }
    
    .i-kb-search {
      display: block !important;
    }
  `};

  div[id*="b_7zydrxo4"] > div,
  div[id*="b_a9mu547i"] > div,
  div[id*="b_w904ehqw"] > div,
  div[id*="b_i3wud71s"] > div,
  div[id*="b_c2ms0dsd"] > div,
  div[id*="b_e7no1e0a"] > div,
  div[id*="b_rp7clpxf"] > div {
    left: 0px !important;
    right: initial !important;
  }

  ${themeMedia.desktop`
    div[id*="b_7zydrxo4"] > div,
    div[id*="b_a9mu547i"] > div,
    div[id*="b_w904ehqw"] > div,
    div[id*="b_i3wud71s"] > div,
    div[id*="b_c2ms0dsd"] > div,
    div[id*="b_e7no1e0a"] > div,
    div[id*="b_rp7clpxf"] > div {
      right: 20px !important;
      left: initial !important;
    }
  `};

  body.add-keyboard-padding-to-containers .keyboard-padding-container {
    padding-bottom: 50vh;
  }
`

export default GlobalStyle
