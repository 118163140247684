import React from 'react'
import PropTypes from 'prop-types'

import { StyledIcon, StyledOfferMessageContainer } from './styles'

import GiftIcon from '../../../images/icon_gift_black.svg'
const DEFAULT_ICON = GiftIcon

const OfferMessage = ({ text, icon, isInformation }) => {
  return (
    <StyledOfferMessageContainer isInformation={isInformation}>
      <StyledIcon
        isInformation={isInformation}
        backgroundUrl={icon ? icon : DEFAULT_ICON}
      />
      <span>{text}</span>
    </StyledOfferMessageContainer>
  )
}

OfferMessage.defaultProps = {
  text: '',
  icon: null,
}

OfferMessage.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
}

export { OfferMessage }
