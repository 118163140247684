import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledImg = styled.img`
  width: 18px;
`

export const StyledWrapper = styled.div`
  vertical-align: middle;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: ${themeStyles.colors.black.main};
  text-align: left;
  display: flex;
  gap: 20px;
  padding: 1.2rem 1rem;
  margin: 0 auto;
  font-size: 0.9em;

  ${themeMedia.tablet`
    width: 60%;
    bottom: 20px;
    border-radius: 6px;
    font-size: 1em;
  `};
`

export const StyledNoticeText = styled.div`
  color: ${themeStyles.colors.white.main};

  p {
    margin: 0;
  }

  a {
    color: ${themeStyles.colors.accentColors.secondary.main};
    text-decoration: underline;
    cursor: pointer;
  }
`
