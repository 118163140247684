import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './styles'
import { Spinner } from '../spinner'
import { If } from '../if'

const Button = (props) => {
  const {
    id,
    red,
    black,
    grey,
    bordered,
    dark,
    disabled,
    expand,
    withdraw,
    nav,
    href,
    redirectURL,
    onClick,
    className,
    loading,
    minWidth,
    size,
    rounded,
    uppercase,
    bold,
    mobileExpand,
    target,
    type,
    backgroundColor,
    textColor,
    outlineColor,
  } = props

  return (
    <StyledButton
      id={id}
      $red={red}
      $black={black}
      $grey={grey}
      $bordered={bordered || outlineColor}
      $dark={dark}
      disabled={disabled || loading}
      $expand={expand}
      $minWidth={minWidth}
      $size={size}
      $withdraw={withdraw}
      $nav={nav}
      className={className}
      onClick={onClick}
      href={href}
      type={type}
      style={props.style}
      state={{ redirectURL }}
      isButton={!href}
      target={target}
      $rounded={rounded}
      $uppercase={uppercase}
      $bold={bold}
      $mobileExpand={mobileExpand}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $outlineColor={outlineColor}
    >
      <If
        condition={loading}
        render={() => <Spinner />}
        renderElse={() => <>{props?.children}</>}
      />
    </StyledButton>
  )
}

Button.defaultProps = {
  id: undefined,
  red: undefined,
  black: undefined,
  grey: undefined,
  bordered: undefined,
  dark: undefined,
  disabled: undefined,
  loading: undefined,
  expand: undefined,
  withdraw: undefined,
  nav: undefined,
  href: undefined,
  redirectURL: undefined,
  onClick: undefined,
  className: undefined,
  minWidth: undefined,
  type: undefined,
  size: 'default',
  rounded: undefined,
  uppercase: true,
  bold: true,
  mobileExpand: true,
  target: undefined,
  children: <></>,
  style: undefined,
  backgroundColor: undefined,
  textColor: undefined,
  outlineColor: undefined,
}

Button.propTypes = {
  id: PropTypes.string,
  red: PropTypes.bool,
  black: PropTypes.bool,
  grey: PropTypes.bool,
  bordered: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  expand: PropTypes.bool,
  minWidth: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  withdraw: PropTypes.bool,
  nav: PropTypes.bool,
  href: PropTypes.string,
  redirectURL: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  uppercase: PropTypes.bool,
  bold: PropTypes.bool,
  mobileExpand: PropTypes.bool,
  target: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  outlineColor: PropTypes.string,
}

export { Button }
