import { graphql, useStaticQuery } from 'gatsby'

export const useBrandConfigApiData = () => {
  const data = useStaticQuery(graphql`
    {
      brandConfigApi {
        values {
          lang
          config {
            features_flags {
              value_json
            }
          }
        }
      }
    }
  `)
  const {
    brandConfigApi: { values },
  } = data

  let featuresFlags

  values?.filter((value) =>
    value?.config?.filter((config) => {
      if (config?.features_flags?.[0]) {
        featuresFlags = config?.features_flags?.[0]?.value_json
      }
    })
  )

  const normalizeJSON = (data) => {
    return data
      ?.replaceAll(' ', '')
      ?.replaceAll('\n', '')
      ?.replaceAll('\r', '')
      ?.replaceAll('\\', '')
      ?.replaceAll(',}', '}')
  }

  if (typeof featuresFlags === 'string') {
    featuresFlags = normalizeJSON(featuresFlags)
    featuresFlags = JSON.parse(featuresFlags)
  }

  return { featuresFlags }
}
