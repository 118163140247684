import { graphql, useStaticQuery } from 'gatsby'
import slugsWorker from '../workers/slugs/slugsWorker'

const useSlugs = (slugKeys) => {
  const data = useStaticQuery(graphql`
    {
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const { values } = data?.customSlugsApi ?? {}

  const slugs = values?.[0]?.slugs || []

  return slugsWorker?.filterSlugs(slugs, slugKeys)
}

export default useSlugs
