import { useEffect } from 'react'

const useResizeListener = (onResize, dependencies = []) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !onResize) return
    onResize()

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, dependencies)
}

export default useResizeListener
