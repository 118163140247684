import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { EnvConfig } from '../config/EnvConfig'

dayjs.extend(utc)
const now = dayjs()

export const isItemActive = (item) => {
  return (
    (item.activeFrom === 0 || now >= dayjs.unix(item.activeFrom)) &&
    (item.activeTo === 0 || now <= dayjs.unix(item.activeTo))
  )
}

export const getActiveItems = (items) => {
  return items.filter(isItemActive)
}

export const getItemByType = (items, lang, type) => {
  const formattedItems = [...items]

  const checkCTA = (ctaList) => {
    ctaList.forEach((cta) => {
      if (!cta.link) return
      if (!cta.link.includes(EnvConfig.CMS_URL.replace('{0}', lang))) return

      cta.link = cta.link.replace(EnvConfig.CMS_URL.replace('{0}', lang), '')
    })
  }

  formattedItems.forEach((item) => {
    if (item['cta_logged_out']) checkCTA(item['cta_logged_out'])
    if (item['cta_logged_in']) checkCTA(item['cta_logged_in'])
  })

  return formattedItems.filter((b) => b.vertical === type)
}

export const filterVisibleFooterItems = (items, isLoggedIn) => {
  return items?.filter((item) => {
    if (item?.visibility?.length === 2) return item
    if (item?.visibility?.includes('logged_in') && isLoggedIn) return item
    if (item?.visibility?.includes('logged_out') && !isLoggedIn) return item
    return
  })
}

export const filterSlides = (slides, isLoggedIn) => {
  const visibilitySetting = isLoggedIn ? 'logged_in' : 'logged_out'

  const slidesByVisibilitySetting =
    slides?.filter(
      (slide) =>
        !slide.visibility_setting ||
        slide.visibility_setting === 'always' ||
        slide.visibility_setting === visibilitySetting
    ) ?? []

  return getActiveItems(slidesByVisibilitySetting)
}
