import React from 'react'
import { StyledWaveWrapper } from './styles'
import PropTypes from 'prop-types'

const Wave = (props) => {
  const { color, direction = 'vertical' } = props
  if (direction === 'horizontal') {
    return (
      <StyledWaveWrapper $direction={direction} $backgroundColor={color}>
        <svg
          viewBox="0 0 337 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 77H291.5H314.5L331.674 77.7633L337 78V79H0V77Z"
            fill={color}
          />
          <path
            d="M56.25 63.83C35.97 62.56 0 52.35 0 0V78H337.01L56.25 63.83Z"
            fill={color}
          />
        </svg>
      </StyledWaveWrapper>
    )
  }

  return (
    <StyledWaveWrapper $direction={direction}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="167"
        height="290"
        fill="none"
        viewBox="0 0 167 290"
      >
        <g clipPath="url(#A)">
          <path
            d="M0 0v289.98l106.7-242C111.98 37 129.31 0 166.5 0H0z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="A">
            <path fill="#fff" d="M0 0h166.5v289.98H0z" />
          </clipPath>
        </defs>
      </svg>
    </StyledWaveWrapper>
  )
}
Wave.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
}

Wave.defaultProps = {
  color: '#fff',
  direction: 'horizontal',
}

export { Wave }
