import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const MethodsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`

export const MethodsContainer = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 0 0.5rem 0;

  justify-items: center;

  ${({ showMaxMethods }) => {
    if (showMaxMethods) {
      return `
        grid-template-columns: repeat(${showMaxMethods}, 1fr);
      `
    }
  }}
`

export const Method = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 2px solid #e1e1e1;

  transition: all 0.1s ease-in;

  padding: 7px;
  box-sizing: border-box;

  ${({ showMaxMethods }) => {
    if (!showMaxMethods) {
      return `
        max-width: 101px;
      `
    }
  }}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
  }

  &.active {
    border-color: ${themeStyles.colors.accentColors.primary.main};
  }

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
`

export const StyledShowMoreButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 5px 14px;

  align-self: center;

  border-radius: 40px;
  background: ${themeStyles.colors.lightGreys[1].main};
  line-height: 140%;
  color: ${themeStyles.colors.black.main};
  font-size: 0.9375rem;
`

export const ButtonIcon = styled.div`
  margin: 0;
  display: flex;
`
