import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../button'
import { useTranslation } from '../../../context/translationProvider'
import {
  StyledBlockContent,
  StyledCapabilitiesDisclaimerWrapper,
  StyledTitle,
} from './styles'
import { EnvConfig } from '../../../config/EnvConfig'

const CapabilitiesDisclaimer = (props) => {
  const { onClose } = props
  const { translate } = useTranslation()

  const startChat = () => {
    onClose()
    try {
      document.querySelector('.circleChatButtonWrap').click()
    } catch (err) {
      location.href = `mailto:${EnvConfig.GATSBY_SUPPORT_EMAIL}`
      onClose()
    }
  }

  return (
    <StyledCapabilitiesDisclaimerWrapper color={props?.color}>
      <StyledBlockContent>
        <StyledTitle>{translate('capabilities.unallowed.title')}</StyledTitle>
      </StyledBlockContent>

      <StyledBlockContent>
        <p>{translate('capabilities.unallowed.description')}</p>
      </StyledBlockContent>

      <Button expand onClick={startChat} id="support-button">
        {translate('capabilities.unallowed.button')}
      </Button>
    </StyledCapabilitiesDisclaimerWrapper>
  )
}

CapabilitiesDisclaimer.defaultProps = {
  onClose: () => {},
}

CapabilitiesDisclaimer.propTypes = {
  onClose: PropTypes.func,
}

export { CapabilitiesDisclaimer }
