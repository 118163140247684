import React from 'react'
import PropTypes from 'prop-types'

import { FormatAmount } from '../../atoms/formatAmount'
import {
  AmountWrapper,
  Amount,
  ValueRange,
  ValueRangeValue,
  AmountContainer,
} from './styles'
import { useTranslation } from '../../../context/translationProvider'

const AmountSelector = ({
  amountOptions,
  depositAmount,
  setDepositAmount,
  currencyCode,
  currencySymbol,
  maximumAmount,
  minimumAmount,
  fullWidth,
}) => {
  const { translate } = useTranslation()
  const handleValueChange = (amount) => {
    setDepositAmount(parseInt(amount))
  }

  const renderAmount = (amount) => (
    <FormatAmount
      amount={parseInt(amount)}
      shortCode={currencyCode}
      maximumFractionDigits="0"
      useGrouping={parseInt(amount).toString().length >= 5}
      currencySymbol={currencySymbol}
    />
  )

  return (
    <AmountWrapper>
      <AmountContainer>
        {amountOptions
          ?.filter(
            (amount) =>
              parseInt(amount) >= minimumAmount &&
              parseInt(amount) <= maximumAmount
          )
          ?.map((amount) => {
            return (
              <Amount
                fullWidth={fullWidth}
                key={parseInt(amount)}
                onClick={() => handleValueChange(amount)}
                className={
                  Number(amount) === Number(depositAmount) ? 'active' : ''
                }
              >
                {renderAmount(amount)}
              </Amount>
            )
          })}
      </AmountContainer>

      <ValueRange>
        {minimumAmount ? (
          <ValueRangeValue>
            {`${translate('depositAmountMin')} `}
            {renderAmount(minimumAmount)}
          </ValueRangeValue>
        ) : null}

        {maximumAmount ? (
          <ValueRangeValue>
            {`${translate('depositAmountMax')} `}
            {renderAmount(maximumAmount)}
          </ValueRangeValue>
        ) : null}
      </ValueRange>
    </AmountWrapper>
  )
}

AmountSelector.defaultProps = {
  depositAmount: 0,
  setDepositAmount: () => {},
  setFormValue: () => {},
  formFieldName: '',
  triggerFormField: () => {},
  currencyCode: '',
  currencySymbol: '',
  fullWidth: false,
}

AmountSelector.propTypes = {
  depositAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setDepositAmount: PropTypes.func,
  setFormValue: PropTypes.func,
  formFieldName: PropTypes.string,
  triggerFormField: PropTypes.func,
  currencyCode: PropTypes.string,
  currencySymbol: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export { AmountSelector }
