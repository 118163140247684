import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const AmountWrapper = styled.div`
  width: 100%;
  margin-top: 1.625rem;
`

export const AmountContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 0 0.5rem 0%;

  justify-items: center;
`

export const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '101px')};
  width: 100%;
  height: 50px;
  border-radius: 6px;

  background: var(--light-ui-greys-light-grey-1, #eee);

  color: ${themeStyles.colors.black.main};
  text-align: center;
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  transition: all 0.2s ease-in-out;

  &.active {
    background-color: ${themeStyles.colors.accentColors.primary.main};
    color: ${themeStyles.colors.accentColors.primary.contrast};
  }

  &:hover {
    cursor: pointer;
  }
`

export const ValueRange = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const ValueRangeValue = styled.span`
  display: flex;
  width: max-content;

  color: var(--light-ui-greys-light-grey-4, #9b9b9b);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
`
