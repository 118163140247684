import { useState } from 'react'
import { checkShowSEOContentStatus } from '../utils/checkShowSEOContentStatus'
import useSlugs from './useSlugs'

const renderSEOPages = checkShowSEOContentStatus()

const seoRoutes = ['research', 'sitemap']

// CREATED THIS HOOK BECAUSE IF IN THE FUTURE WE MOVE THIS FLAG
// FROM THE ENV VARS TO GROWTHBOOK OR ANOTHER TOOL,
// WE CAN JUST CHANGE IT IN ONE PLACE
export const useSEOContentVisibility = () => {
  const [researchSlug, sitemapSlug] = useSlugs(seoRoutes)
  const [showSEOContent] = useState(renderSEOPages)

  const checkIfIncludesPath = (url, path) => {
    return url.includes(`/${path}`)
  }

  const filterSEOMenu = (menu) => {
    if (showSEOContent) return menu

    return menu.filter(
      ({ link: { url } }) =>
        !checkIfIncludesPath(url, researchSlug) &&
        !checkIfIncludesPath(url, sitemapSlug)
    )
  }

  return {
    showSEOContent,
    filterSEOMenu,
  }
}
