import React from 'react'
import styled from 'styled-components'

import { Container } from '../../atomic-components/atoms/container'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'

const ImageWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  > a,
  > img {
    display: inline-block;
    flex: 0 0 45px;
    margin: 0 1rem 0 0;
    width: 45px;

    &:last-child {
      margin: 0;
    }
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 0.9em;
  margin: 1rem 0;
`

const FooterSponsors = () => {
  const { partnersSponsors } = useFooterApiData()

  return (
    <div>
      <Container>
        <Title>{partnersSponsors.partnerText}</Title>
        <ImageWrapper>
          {generateImgElements(
            partnersSponsors.images.filter(
              (i) => i.type_of_record.toLowerCase() === 'partner'
            )
          )}
        </ImageWrapper>
        <Title>{partnersSponsors.sponsorText}</Title>
        <ImageWrapper>
          {generateImgElements(
            partnersSponsors.images.filter(
              (i) => i.type_of_record.toLowerCase() === 'sponsor'
            )
          )}
        </ImageWrapper>
      </Container>
    </div>
  )

  function generateImgElements(arr) {
    const elements = []

    arr.forEach((img, i) => {
      if (!img.image.url) return
      img.link !== ''
        ? elements.push(
            <a key={i} href={`${img.link}`} title={`${img.description}`}>
              <img
                width={45}
                height={45}
                loading="lazy"
                src={`${img.image.url}`}
                alt={`${img.image.alt}`}
              />
            </a>
          )
        : elements.push(
            <img
              key={i}
              width={45}
              height={45}
              loading="lazy"
              src={`${img.image.url}`}
              alt={`${img.image.alt}`}
            />
          )
    })

    return elements
  }
}

export default FooterSponsors
