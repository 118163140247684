import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledEyeButton,
  StyledPasswordWrapper,
  StyledRequirementsWrapper,
} from './styles'

import { FieldWrapper } from '../fieldWrapper'
import { FieldInputArea } from '../../atoms/fieldInputArea'
import { FieldDescription } from '../../atoms/fieldDescription'
import { If } from '../../atoms/if'
import { PasswordRequirements } from '../../atoms/passwordRequirements'

import EyeIcon from '../../../images/icon_eye.svg'
import EyeSlashIcon from '../../../images/icon_eye_slash.svg'
import { useTranslation } from '../../../context/translationProvider'

const FieldPassword = (props) => {
  const {
    label,
    placeholder,
    description,
    disabled,
    showErrorMsg,
    reduceBottomMargin,
    autoCompleteOff,
    maxLength,
    errors,
    formProperties,
    defaultValue,
    hasBeenSubmitted,
    onChange,
    onDone,
    renderError,
    showTipsOnFocus,
    scrollOnFocus,
    showPasswordByDefault,
    onFocus,
    onBlur,
  } = props
  const { translate } = useTranslation()
  const [showPassword, setShowPassword] = useState(showPasswordByDefault)

  if (!formProperties) return <></>

  return (
    <FieldWrapper
      label={label || translate('common.password')}
      showErrorMsg={showErrorMsg}
      reduceBottomMargin={reduceBottomMargin}
      errors={errors}
      formProperties={formProperties}
      hasBeenSubmitted={hasBeenSubmitted}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      renderError={renderError}
      key={`password-${formProperties.name}`}
      scrollOnFocus={scrollOnFocus}
      renderInputField={({
        onChangeCombined,
        onBlurCombined,
        onFocusCombined,
        invalid,
        valid,
        data,
        isInFocus,
      }) => (
        <StyledPasswordWrapper>
          <FieldInputArea
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            autoComplete={autoCompleteOff ? 'new-password' : null}
            maxLength={maxLength}
            disabled={disabled}
            defaultValue={defaultValue}
            formProperties={formProperties}
            hasBeenSubmitted={hasBeenSubmitted}
            valid={valid}
            invalid={invalid}
            onChange={onChangeCombined}
            onBlur={onBlurCombined}
            onFocus={onFocusCombined}
            onDone={onDone}
            scrollOnFocus={scrollOnFocus}
            hideSymbols
            suffixPointerEvents="initial"
            inputSuffix={
              <StyledEyeButton
                src={showPassword ? EyeSlashIcon : EyeIcon}
                alt={showPassword ? 'Hide Password' : 'Show Password'}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
          />
          <If
            condition={showTipsOnFocus && (isInFocus || invalid)}
            render={() => (
              <StyledRequirementsWrapper>
                <PasswordRequirements password={data || defaultValue} />
              </StyledRequirementsWrapper>
            )}
            renderElse={() => (
              <FieldDescription>{description}</FieldDescription>
            )}
          />
        </StyledPasswordWrapper>
      )}
    />
  )
}

FieldPassword.defaultProps = {
  label: '',
  defaultValue: '',
  placeholder: null,
  description: null,
  disabled: false,
  showErrorMsg: false,
  reduceBottomMargin: false,
  hasBeenSubmitted: null,
  autoCompleteOff: false,
  showTipsOnFocus: false,
  showPasswordByDefault: false,
  errors: {},
  maxLength: null,
  formProperties: {},
  onChange: () => {},
  onDone: () => {},
  renderError: null,
  onBlur: () => {},
  onFocus: () => {},
}

FieldPassword.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  reduceBottomMargin: PropTypes.bool,
  hasBeenSubmitted: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
  showTipsOnFocus: PropTypes.bool,
  scrollOnFocus: PropTypes.bool,
  showPasswordByDefault: PropTypes.bool,
  errors: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onDone: PropTypes.func,
  renderError: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export { FieldPassword }
