import styled from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

export const StyledImage = styled.img`
  width: 80px;
  height: auto;
  vertical-align: middle;
  cursor: pointer;

  &.logo {
    width: ${themeStyles.logo.mobile.width};
  }

  &.burger-menu,
  &.main-search {
    height: 30px;
    width: 30px;
  }

  @media (min-width: 1400px) {
    &.main-search {
      margin: 0 1.4rem 0 0;
      height: 34px;
      width: 34px;
    }
  }

  ${themeMedia.desktop`
    &.logo {
      width: ${themeStyles.logo.smallDesktop.width};
    }
  `}

  @media (min-width: 1600px) {
    &.logo {
      width: ${themeStyles.logo.desktop.width};
    }
    &.burger-menu {
      display: none;
    }
  }
`
