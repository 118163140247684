import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledOfferMessageContainer = styled.section`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${({ isInformation }) => (isInformation ? '15px' : '9px')};
  align-items: center;
  margin-top: 12px;

  width: 100%;
  max-width: 100%;
  padding: ${({ isInformation }) =>
    isInformation ? '10px 19px 10px 14px' : '10px 13px 10px 8px'};
  border-radius: 6px;
  border: 2px solid
    var(
      --primary-colours-kto-green,
      ${({ isInformation }) =>
        isInformation
          ? themeStyles.colors.lightGreys[1].main
          : themeStyles.colors.success.main}
    );

  span {
    color: var(--primary-colours-black, ${themeStyles.colors.black.main});
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
`

export const StyledIcon = styled.div`
  width: ${({ isInformation }) => (isInformation ? '24px' : '46px')};
  height: ${({ isInformation }) => (isInformation ? '24px' : '46px')};
  background: var(
    --light-ui-greys-light-grey-1,
    ${({ isInformation }) =>
      isInformation ? 'none' : themeStyles.colors.lightGreys[1].main}
  );

  background-image: url(${(props) => props.backgroundUrl});
  background-repeat: no-repeat;
  background-size: initial;
  background-position: center;

  border-radius: 100%;
`
