import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledArrowDown = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  filter: invert(1);
  transition: 0.2s;

  ${({ showMore }) =>
    showMore &&
    css`
      transform: rotate(180deg);
    `}
`

export const StyledShowMoreTandCBtn = styled.div`
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;

  span {
    background: ${themeStyles.colors.lightGreys[1].main};
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }
`

export const StyledTandCBox = styled.div`
  max-height: 0;
  overflow-y: hidden;
  transition: 0.2s;
  padding: 0;
  margin-bottom: 2rem;

  ${({ showMore }) =>
    showMore &&
    css`
      padding: 5px 0;
      max-height: min-content;
      overflow-y: auto;
    `}
`
