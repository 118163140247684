import React from 'react'
import { useTranslation } from '../../../context/translationProvider'
import { BlockContent, Content, Title } from './styles'

export const PendingCpf = ({ color }) => {
  const { translate } = useTranslation()
  return (
    <Content color={color}>
      <BlockContent>
        <Title>{translate('cpf.processing.title')}</Title>
      </BlockContent>

      <BlockContent>{translate('cpf.processing.description')}</BlockContent>
    </Content>
  )
}
