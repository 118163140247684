import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'

import { AuthContext } from './authProvider'
import { checkUserPopup, getUserPopups } from '../adapters/user'
import useFeatures from '../hooks/useFeatures'
import storageService from '../services/storageService'

const PopupsContext = createContext()

const getUserLanguage = () => {
  const tags = {
    en: 'en-US',
    pt: 'pt-BR',
    pe: 'es-PE',
    ca: 'en-CA',
    cl: 'es-CL',
  }

  return tags[process.env.GATSBY_INTL_LOCALE]
}

export const PopupsProvider = (props) => {
  const isPopupsEnabled = useFeatures('popups')
  const { user } = useContext(AuthContext)
  const [popups, setPopups] = useState([])
  const [popup, setPopup] = useState(null)

  const getData = async () => {
    if (
      !user ||
      !isPopupsEnabled ||
      popups?.length > 0 ||
      !storageService.getValue('checkNotifications')
    )
      return
    storageService.removeValue('checkNotifications')

    const userLang = getUserLanguage()
    const { data, ok } = await getUserPopups(userLang || '')

    if (!ok || !data) return

    setPopups(data)
    setPopup(data[0])
  }

  const checkPopup = async (id, status) => {
    if (!popups || !user) return

    try {
      await checkUserPopup(id, status)

      if (status === 'SEEN') return

      const newPopups = popups?.filter((item) => item.id !== id)

      setPopups(newPopups)

      if (newPopups?.length <= 0) {
        setPopup(null)
        return
      }

      setPopup(newPopups[0])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [user, isPopupsEnabled])

  const value = useMemo(() => ({ popup, checkPopup }), [popup, user])

  return <PopupsContext.Provider value={value} {...props} />
}

export const usePopups = () => {
  const context = useContext(PopupsContext)

  if (!context) return {}

  const { popup, checkPopup } = context

  return { popup, checkPopup }
}
