import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  MethodsWrapper,
  MethodsContainer,
  Method,
  StyledShowMoreButton,
  ButtonIcon,
} from './styles'

import PlusIcon from '../../../images/icon_plus_black.svg'
import MinusIcon from '../../../images/icon_minus_black.svg'
import { useTranslation } from '../../../context/translationProvider'

const PaymentMethodSelector = ({
  methods,
  selectedMethod,
  setSelectedMethod,
  showMaxMethods,
}) => {
  const INITIAL_MAX_METHODS = showMaxMethods ? methods?.length : 3
  const [maxMethods, setMaxMethods] = useState(INITIAL_MAX_METHODS)
  const isMaxMethods = maxMethods === methods?.length
  const { translate } = useTranslation()

  const handleSelectionChange = (method) => {
    setSelectedMethod(method.paymentMethodDetailId)
  }

  const handleChangeMaxMethods = () => {
    if (isMaxMethods) {
      setMaxMethods(INITIAL_MAX_METHODS)
      return
    }

    setMaxMethods(methods?.length)
  }

  useEffect(() => {
    if (!methods?.length) return
    if (isMaxMethods) return

    const selectedMethodIndex = methods?.findIndex(
      (method) => method?.paymentMethodDetailId === selectedMethod
    )

    if (selectedMethodIndex < maxMethods) return

    setMaxMethods(methods?.length)
  }, [selectedMethod])

  return (
    <MethodsWrapper>
      <MethodsContainer
        showMaxMethods={showMaxMethods ? methods?.length : false}
      >
        {methods
          ?.filter((_, index) => index < maxMethods)
          ?.map((method) => {
            return (
              <Method
                showMaxMethods={showMaxMethods ? methods?.length : false}
                key={method?.orderNumber}
                onClick={() => handleSelectionChange(method)}
                className={
                  method?.paymentMethodDetailId === selectedMethod
                    ? 'active'
                    : ''
                }
              >
                <img
                  src={method?.resourceUrl}
                  alt={method?.paymentMethodName}
                />
              </Method>
            )
          })}
      </MethodsContainer>

      {methods?.length > INITIAL_MAX_METHODS ? (
        <StyledShowMoreButton type="button" onClick={handleChangeMaxMethods}>
          {isMaxMethods ? (
            <ButtonIcon>
              <img src={MinusIcon} />
            </ButtonIcon>
          ) : (
            <ButtonIcon>
              <img src={PlusIcon} />
            </ButtonIcon>
          )}
          {isMaxMethods
            ? translate('depositLessMethods')
            : translate('depositMoreMethods')}
        </StyledShowMoreButton>
      ) : null}
    </MethodsWrapper>
  )
}

PaymentMethodSelector.defaultProps = {
  methods: [],
  selectedMethod: 0,
  setSelectedMethod: () => {},
  showMaxMethods: false,
}

PaymentMethodSelector.propTypes = {
  methods: PropTypes.array,
  selectedMethod: PropTypes.number,
  setSelectedMethod: PropTypes.func,
  showMaxMethods: PropTypes.bool,
}

export { PaymentMethodSelector }
