import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import DotIcon from '../../../images/icon_list_circle.svg'
import TickIcon from '../../../images/icon_list_tick.svg'
import { brandConfig } from '../../../config/brandConfig'

export const StyledPasswordRequirements = styled.div`
  padding: 0.6em 0.8em;
  font-size: 14px;
  font-weight: ${brandConfig.fonts.text.paragraph.weight};
  background: ${themeStyles.colors.lightGreys[1].main};
  border-radius: 8px;
  line-height: 1.5em;
  ul {
    margin: 0;
    padding: 0.2em 1.2em;
    list-style: none;
  }
`

export const StyledLi = styled.li`
  position: relative;
  &:before {
    content: '';
    width: 1em;
    height: 1em;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-120%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('${({ $isActive }) =>
      $isActive ? TickIcon : DotIcon}');
  }
`
