import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isBrowser } from '../../../utils/generic'

const ResponsiveContainer = (props) => {
  const { biggerThan, sizeNumber, render, renderElse, renderFallback } = props
  const hasWindow = isBrowser()

  const [width, setWidth] = useState(0)

  const onResizeWindow = useCallback(() => setWidth(window?.innerWidth), [])

  useEffect(() => {
    if (hasWindow) {
      onResizeWindow()
      window.top.addEventListener('resize', onResizeWindow)
    }

    return () => {
      if (hasWindow) window.top.removeEventListener('resize', onResizeWindow)
    }
  }, [])

  if (!width) return renderFallback()

  if (
    (biggerThan && sizeNumber < width) ||
    (!biggerThan && sizeNumber > width)
  ) {
    return render()
  }

  return renderElse()
}

ResponsiveContainer.defaultProps = {
  biggerThan: false,
  sizeNumber: 0,
  render: () => <></>,
  renderElse: () => <></>,
  renderFallback: () => <></>,
}
ResponsiveContainer.propTypes = {
  biggerThan: PropTypes.bool,
  sizeNumber: PropTypes.number,
  render: PropTypes.func,
  renderElse: PropTypes.func,
  renderFallback: PropTypes.func,
}

export { ResponsiveContainer }
