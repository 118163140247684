export const MOBILE_FEATURE_NAMES = {
  burger: 'MOBILE_APP_BURGER',
  depositFromHeader: 'MOBILE_APP_DEPOSIT_FROM_HEADER',
  depositFromAccount: 'MOBILE_APP_DEPOSIT_FROM_ACCOUNT',
  readyForToken: 'WEB_IS_READY_FOR_TOKEN',
  requestNewToken: 'MOBILE_APP_REQUEST_NEW_TOKEN',
  authState: 'MOBILE_APP_AUTH_STATE',
  logout: 'MOBILE_APP_LOGOUT',
  refreshToken: 'MOBILE_APP_REFRESH_TOKEN',
  tokenVerification: 'TOKEN_VERIFICATION',
  navLogin: 'MOBILE_APP_NAV_LOGIN',
  navRegister: 'MOBILE_APP_NAV_REGISTER',
  activeTab: 'MOBILE_APP_ACTIVE_TAB',
  walletStatus: 'MOBILE_APP_WALLET_STATUS',
  enablePushNotifications: 'MOBILE_APP_ENABLE_PUSH_NOTIFICATIONS',
}

export const MOBILE_FEATURES = {
  [MOBILE_FEATURE_NAMES.burger]: {
    minimumVersion: 10,
  },
  [MOBILE_FEATURE_NAMES.depositFromHeader]: {
    minimumVersion: 10,
  },
  [MOBILE_FEATURE_NAMES.authState]: {
    minimumVersion: 15,
  },
  [MOBILE_FEATURE_NAMES.logout]: {
    minimumVersion: 15,
  },
  [MOBILE_FEATURE_NAMES.refreshToken]: {
    minimumVersion: 15,
  },
  [MOBILE_FEATURE_NAMES.readyForToken]: {
    minimumVersion: 15,
  },
  [MOBILE_FEATURE_NAMES.activeTab]: {
    minimumVersion: 52,
  },
  [MOBILE_FEATURE_NAMES.depositFromAccount]: {
    minimumVersion: 117,
  },
}
