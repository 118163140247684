import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FieldWithSuggestions } from '../fieldWithSuggestions'
import { useTranslation } from '../../../context/translationProvider'
import { useDebounce } from '../../../hooks/useDebounce'
import {
  getPlaceDetail,
  getPlacesAutocomplete,
} from '../../../adapters/googlePlaces'
import { nameNormalize } from '../../../utils/stringNormalize'

const GooglePlacesField = (props) => {
  const {
    formProperties,
    label,
    setValue,
    description,
    fieldsToAffect,
    whitelistedCountries,
    // safeSetCountry,
    errors,
    pickingValueIsRequired,
    showDropdownIcon,
    setError,
    clearErrors,
  } = props
  const { translate } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [predictions, setPredictions] = useState([])
  const typesToSendToGoogle = {
    address: 'route',
    city: 'locality|administrative_area_level_2',
    state: 'administrative_area_level_1',
    country: 'country',
  }

  const addressLevel = {
    address: 1,
    city: 2,
    state: 3,
    country: 4,
  }

  const onChangeInput = (event) => {
    if (pickingValueIsRequired) {
      setError(formProperties.name, {
        message: translate('error.mustPick'),
      })
    } else {
      clearErrors(formProperties.name)
    }
    const value = event?.target?.value
    setValue(formProperties.name, value)
    if (!value) return setIsLoading(false)
    setIsLoading(true)
    debounceAutocomplete(value)
  }

  const getAutocomplete = async (value) => {
    const { ok, data } = await getPlacesAutocomplete(
      value,
      whitelistedCountries,
      typesToSendToGoogle[formProperties.name]
    )
    setIsLoading(false)
    if (!ok || !data?.predictions) return
    setPredictions(data.predictions)
  }
  const debounceAutocomplete = useDebounce(getAutocomplete, 500)

  // Examples:
  // address should update all fields
  // city should update all fields except for address
  // state should update all except for address and city...
  const setErrorIfThereShouldBeData = (key) => {
    if (addressLevel[formProperties.name] > addressLevel[key]) return
    setError(key)
  }

  const safeSetValue = (key, value) => {
    if (value) {
      clearErrors(key)
      setValue(key, value)
    } else {
      setErrorIfThereShouldBeData(key)
    }
  }

  const updateForm = (place) => {
    const addressObject = {}
    place.address_components.forEach((component) => {
      const componentType = component.types[0]
      if (fieldsToAffect.includes(componentType)) {
        if (addressObject[componentType]) {
          addressObject[componentType] += component.long_name
        } else {
          addressObject[componentType] = component.long_name
        }
      }
    })

    if (addressObject.postal_code) {
      setValue(
        'postcode',
        `${addressObject.postal_code}-${addressObject.postal_code_suffix ?? ''}`
      )
    }

    safeSetValue(
      'state',
      nameNormalize(
        addressObject.administrative_area_level_1 ?? ''
      )?.replaceAll('State of ', '')
    )

    safeSetValue(
      'city',
      addressObject.administrative_area_level_2 || addressObject.locality
    )

    safeSetValue(
      'address',
      addressObject.route
        ? `${addressObject.route} ${addressObject.street_number ?? ''}`
        : null
    )

    // If we ever bring back changing countries, this will be necessary
    // if (addressObject.country) {
    //   clearErrors('country')
    //   setValue('country', `${addressObject.country}`)
    //   const countryCode = place.address_components.find(
    //     (component) => component.long_name == addressObject.country
    //   )?.short_name
    //   safeSetCountry(countryCode)
    // }
  }

  const onValueSelected = async (item) => {
    const { ok, data } = await getPlaceDetail(item.place_id)
    setIsLoading(false)
    if (!ok || !data?.result) return
    updateForm(data.result)
  }

  const createHighlightedDescription = (item, itemIndex) => {
    const {
      description,
      structured_formatting: { main_text_matched_substrings },
    } = item

    const highlightedText = []

    let currentIndex = 0
    main_text_matched_substrings?.forEach((substring) => {
      const { offset, length } = substring
      highlightedText.push(description.substring(currentIndex, offset))
      highlightedText.push(description.substring(offset, offset + length))
      currentIndex = offset + length
    })

    if (currentIndex < description.length) {
      highlightedText.push(description.substring(currentIndex))
    }

    return highlightedText.map((text, index) => (
      <span
        key={`text-${itemIndex}-${index}`}
        className={index % 2 === 1 ? 'highlighted' : ''}
      >
        {text}
      </span>
    ))
  }

  return (
    <FieldWithSuggestions
      description={description}
      onChange={onChangeInput}
      isLoading={isLoading}
      suggestionHint={translate('register.keepTyping')}
      formProperties={formProperties}
      label={label}
      showErrorMsg
      errors={errors}
      showDropdownIcon={showDropdownIcon}
      suggestions={predictions?.map((item, i) => (
        <button
          key={`suggestion-${i}`}
          type="button"
          onClick={() => onValueSelected(item)}
        >
          {createHighlightedDescription(item, i)}
        </button>
      ))}
    />
  )
}

GooglePlacesField.defaultProps = {
  fieldsToAffect: [],
  formProperties: {},
  errors: {},
  label: null,
  description: null,
  whitelistedCountries: '',
  setValue: () => {},
  safeSetCountry: () => {},
  setError: () => {},
  clearErrors: () => {},
  pickingValueIsRequired: false,
  showDropdownIcon: false,
}

GooglePlacesField.propTypes = {
  fieldsToAffect: PropTypes.arrayOf(PropTypes.string),
  formProperties: PropTypes.object,
  errors: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  whitelistedCountries: PropTypes.string,
  setValue: PropTypes.func,
  safeSetCountry: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  pickingValueIsRequired: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
}

export { GooglePlacesField }
