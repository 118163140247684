import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { AuthContext } from '../../../context/authProvider'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { WithIconText } from '../../molecules/withIconText'

import { StyledUspsBarWrapper } from './styles'
import useFeatures from '../../../hooks/useFeatures'
import { navigate } from 'gatsby'
import useSlugs from '../../../hooks/useSlugs'
import { sendRegistrationEvent } from '../../../utils/commonDataLayer'
import { useModalContext } from '../../../context/modalProvider'
import { ModalTypes } from '../../../utils/constants'

const UspsBar = ({ usps, cta, isReducedSize, showSpacingIfNoBar }) => {
  const [registerSlug] = useSlugs(['register'])
  const { isMobile } = useDeviceDetect()
  const { isLoggedIn } = useContext(AuthContext)
  const { open } = useModalContext()

  const isUSPEnabled = useFeatures('usp')

  if (!usps.length || isLoggedIn || !isUSPEnabled) {
    if (showSpacingIfNoBar) return <div />
    return <></>
  }

  const handleBarClick = () => {
    if (!cta?.link) return

    if (cta.link.includes(`/${registerSlug}/`)) {
      sendRegistrationEvent('usp-bar')
      open({ type: ModalTypes.REGISTER })
      return
    }
    navigate(cta.link)
  }

  return (
    <StyledUspsBarWrapper
      hasAction={!!cta}
      onClick={handleBarClick}
      isReducedSize={isReducedSize}
    >
      {usps?.map((usp) => (
        <WithIconText key={usp.title} usp={usp} isReducedSize={isReducedSize} />
      ))}

      {cta && !isMobile && !isReducedSize && (
        <WithIconText
          key={cta.title}
          usp={cta}
          hasRightIcon
          isCtaElement
          isSmallIcon
        />
      )}
    </StyledUspsBarWrapper>
  )
}

const WithIconTextShape = {
  icon: PropTypes.string,
  title: PropTypes.string,
}

const WithIconTextCtaShape = {
  ...WithIconTextShape,
  action: PropTypes.func,
}

UspsBar.defaultProps = {
  usps: [],
  cta: null,
  isReducedSize: false,
  showSpacingIfNoBar: false,
}

UspsBar.propTypes = {
  usps: PropTypes.arrayOf(PropTypes.shape(WithIconTextShape)),
  cta: PropTypes.shape(WithIconTextCtaShape),
  isReducedSize: PropTypes.bool,
  showSpacingIfNoBar: PropTypes.bool,
}

export { UspsBar }
