import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 537px;
  align-self: center;
`
export const Title = styled.h1`
  font-size: 25px;
  font-weight: 400;
  line-height: 28.75px;
  margin-bottom: 3rem;
`
export const Subtitle = styled.h2`
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  margin-bottom: 1.5rem;
`
export const Text = styled.p`
  font-size: 17px;
  font-weight: 400;
  line-height: 24.65px;
  margin-bottom: 3.7rem;
  margin-top: 1.5rem;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`
