import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { AuthContext } from './authProvider'
import { getUserLimits } from '../adapters/user'
import storageService from '../services/storageService'

const RealityCheckContext = createContext()

export const RealityCheckProvider = (props) => {
  const { isLoggedIn, logoutUser } = useContext(AuthContext)
  const [realityCheckTimeInSeconds, setRealityCheckTimeInSeconds] =
    useState(null)
  const [realityCheckTimeCache, setRealityCheckTimeCache] = useState(null)
  const [userSetting, setUserSetting] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const timer = useRef(null)
  const channel = useRef(new BroadcastChannel('reality_check_channel')).current

  const handleRealityCheck = () => {
    channel.postMessage('openRealityCheckModal')
    setIsModalOpen(true)
    storageService.setValue('showRealityCheckModal', true)
  }

  const handleKeepMePlaying = () => {
    channel.postMessage('closeRealityCheckModal')
    setIsModalOpen(false)
    setRealityCheckTimeInSeconds(`${realityCheckTimeCache}:${Date.now()}`)

    storageService.removeValue('showRealityCheckModal')
    storageService.setValue('realityCheckStartTime', new Date().getTime())
  }

  const handleLogMeOut = () => {
    logoutUser()

    setIsModalOpen(false)
    setRealityCheckTimeInSeconds(null)
  }

  const handleChangeRealityCheckSetting = (duration) => {
    setUserSetting({
      ...userSetting,
      duration,
    })

    setRealityCheckTimeInSeconds(`${duration}:${Date.now()}`)
    storageService.setValue('realityCheckStartTime', new Date().getTime())
  }

  const initialUserSetup = async () => {
    if (!isLoggedIn) {
      return
    }

    const {
      data: { data },
    } = await getUserLimits()
    const realityCheck = data?.find((item) => item.type === 'REALITY_CHECK')

    if (!realityCheck) {
      return
    }

    const showRealityCheckModal = storageService.getValue(
      'showRealityCheckModal'
    )

    if (showRealityCheckModal) {
      setIsModalOpen(true)
    }

    const userLoginTime = storageService.getValue('realityCheckStartTime')

    const timePassedInSecondsSinceLogin = Math.floor(
      (Date.now() - userLoginTime) / 1000
    )

    const correctTime = realityCheck?.duration - timePassedInSecondsSinceLogin

    if (correctTime <= 0) {
      storageService.setValue('realityCheckStartTime', new Date().getTime())
      setRealityCheckTimeInSeconds(`${realityCheck?.duration}:${Date.now()}`)
      setRealityCheckTimeCache(realityCheck?.duration)

      return
    }

    setUserSetting(realityCheck)
    setRealityCheckTimeCache(realityCheck.duration)
    setRealityCheckTimeInSeconds(`${correctTime}:${Date.now()}`)
  }

  useEffect(() => {
    if (!realityCheckTimeInSeconds) {
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    const [timeInSeconds] = realityCheckTimeInSeconds.split(':')
    const timeInMilliseconds = timeInSeconds * 1000

    timer.current = window?.setTimeout(handleRealityCheck, timeInMilliseconds)

    return () => clearTimeout(timer.current)
  }, [realityCheckTimeInSeconds])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    initialUserSetup()

    channel.onmessage = (event) => {
      if (event.data === 'closeRealityCheckModal') {
        setIsModalOpen(false)
      }
      if (event.data === 'openRealityCheckModal') {
        setIsModalOpen(true)
      }
    }
  }, [isLoggedIn])

  return (
    <RealityCheckContext.Provider
      value={{
        handleChangeRealityCheckSetting,
        handleKeepMePlaying,
        handleLogMeOut,
        realityCheckTimeInSeconds,
        userSetting,
        isModalOpen,
      }}
      {...props}
    />
  )
}

export const useRealityCheckContext = () => {
  const context = useContext(RealityCheckContext)

  if (!context) return {}

  const {
    handleChangeRealityCheckSetting,
    handleKeepMePlaying,
    handleLogMeOut,
    realityCheckTimeInSeconds,
    userSetting,
    isModalOpen,
  } = context

  return {
    handleChangeRealityCheckSetting,
    handleKeepMePlaying,
    handleLogMeOut,
    realityCheckTimeInSeconds,
    userSetting,
    isModalOpen,
  }
}
