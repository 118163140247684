import React, { useContext } from 'react'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import {
  FooterImageSectionContainer,
  FooterImageSectionImages,
  FooterImageSectionTitle,
} from './styles'
import { Image } from '../../atoms/image'
import { filterVisibleFooterItems } from '../../../dataManipulation/cmsItems'
import { AuthContext } from '../../../context/authProvider'
import { getLinkRel } from '../../../utils/getLinkRel'

const FooterImagesSections = () => {
  const { image_sections } = useFooterV2ApiData()
  const { isLoggedIn } = useContext(AuthContext)
  const filteredSections = image_sections
    ?.map((section) => {
      return {
        images: filterVisibleFooterItems(section?.images, isLoggedIn),
        title: section?.title,
      }
    })
    ?.filter((section) => section?.images?.length > 0)

  return (
    <FooterImageSectionContainer>
      {filteredSections?.map((section, i) => {
        return (
          <div key={`footer-image-section-${i}`}>
            <FooterImageSectionTitle>{section?.title}</FooterImageSectionTitle>
            <FooterImageSectionImages>
              {section?.images?.map((imageData, j) => (
                <a
                  href={imageData?.link?.url}
                  target={imageData?.link?.target}
                  rel={getLinkRel(imageData?.link?.target)}
                  key={`footer-image-link-${j}`}
                >
                  <Image
                    url={imageData?.image?.url}
                    alt={imageData?.image?.url}
                    width={imageData?.image?.width}
                    height={imageData?.image?.height}
                  />
                </a>
              ))}
            </FooterImageSectionImages>
          </div>
        )
      })}
    </FooterImageSectionContainer>
  )
}

export { FooterImagesSections }
