import styled from 'styled-components'
import { brandConfig } from '../../../config/brandConfig'
import { themeStyles } from '../../../styles/theme'

export const StyledLabel = styled.label`
  font-family: ${brandConfig.fonts.text.label.family};
  font-style: normal;
  font-weight: ${brandConfig.fonts.text.label.weight};
  font-size: ${brandConfig.fonts.text.label.size.desktop};
  line-height: 145%;
  margin: 0 0 0.2em 0;

  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;
    color: ${themeStyles.colors.lightGreys[4].main};
    font-size: 0.9375rem;
  }
`
