import styled, { css } from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

export const Container = styled.div`
  display: inline-flex;
  gap: 1em;
  padding: 0.5em 0;
  position: relative;
  z-index: 1;
  float: left;

  ${({ isExpiring }) =>
    isExpiring &&
    css`
      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        width: calc(100% + 1em);
        height: 100%;
        background: ${themeStyles.colors.white.main};
        border-radius: 3px;
        top: -0.05em;
        left: -0.5em;
      }
    `}

  ${({ isExpiring }) =>
    !isExpiring &&
    css`
      margin-top: -5px;
      margin-bottom: 5px;
    `}
`

export const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  color: ${themeStyles.colors.black.main};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ isExpiring }) =>
    isExpiring &&
    css`
      color: ${themeStyles.colors.primary.main};
    `}

  &:first-child {
    margin-right: 0.25em;
  }

  &:nth-child(3) {
    &:after,
    &:before {
      content: ':';
      position: absolute;
      top: 0;
      font-size: 1.4em;
    }

    &:after {
      right: -0.5em;
    }

    &:before {
      left: -0.5em;
    }
  }
`

export const Value = styled.div`
  font-size: 24px;
  font-weight: 500;

  ${({ $small }) =>
    $small &&
    css`
      font-size: 19px;
    `}

  ${themeMedia.desktop`
      ${({ $small }) =>
        $small &&
        css`
          font-size: 29px;
        `}
  `}
`

export const Label = styled.div`
  font-size: 8px;
  font-weight: 400;
  text-transform: uppercase;

  ${({ $small }) =>
    $small &&
    css`
      font-size: 8px;
    `}

  ${themeMedia.desktop`
    ${({ $small }) =>
      $small &&
      css`
        font-size: 9px;
      `}
  `}
`

export const Clock = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  background: ${themeStyles.colors.primary.main};
  top: calc(-3px - 0.5em);
  right: calc(-3px - 1em);
  border-radius: 100%;
  border: 1px solid ${themeStyles.colors.white.main};

  ${({ isExpiring }) =>
    isExpiring &&
    css`
      display: flex;
    `}
`
