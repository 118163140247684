import browserLanguagesConfig from './browserLanguages.json'

const locale = process.env.GATSBY_INTL_LOCALE

export const getBrowserLanguage = () => {
  try {
    const lang = navigator?.language || browserLanguagesConfig?.default

    const language = browserLanguagesConfig?.languages?.find(
      ({ browserLanguage }) =>
        browserLanguage.toLowerCase() === lang.toLowerCase()
    )

    return language?.locale || locale
  } catch (ex) {
    return locale
  }
}
