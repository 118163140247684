import { getBrazilianBanks } from '../../../adapters/common'

export const getBankAccountFormFields = async (translate) => {
  const bankAccountTypes = {
    CRN: translate('payments.currentAccount'),
    PAC: translate('payment.paymentAccount'),
    SVN: translate('payment.savingsAccount'),
    SAL: translate('payment.salaryAccount'),
  }

  const bankAccountFormFields = [
    [
      {
        name: 'bankId',
        type: 'dropdown',
        label: translate('payments.bankName'),
        options: [],
      },
      {
        name: 'bankBranch',
        type: 'text',
        label: translate('payments.bankBranch'),
        placeholder: translate('payments.bankBranch'),
        minLength: 4,
        maxLength: 5,
      },
    ],
    [
      {
        name: 'accountNumber',
        type: 'text',
        label: translate('payments.bankAccount'),
        placeholder: translate('payments.bankAccount'),
        minLength: 4,
        maxLength: 20,
      },
      {
        name: 'accountType',
        type: 'dropdown',
        label: translate('payments.accountType') || 'Account Type',
        options: Object.keys(bankAccountTypes).map((key) => ({
          value: key,
          label: `${key} - ${bankAccountTypes[key]}`,
        })),
      },
    ],
  ]

  const parseBankToOption = (bank) => ({
    value: bank.id,
    label: bank.name.split('-')[1],
  })

  const brazilianAccounts = (await getBrazilianBanks()).data.data.map((bank) =>
    parseBankToOption(bank)
  )
  const sortedBrazilianAccounts = brazilianAccounts.sort((a, b) =>
    a.label.localeCompare(b.label)
  )
  bankAccountFormFields[0][0].options = sortedBrazilianAccounts

  return bankAccountFormFields
}
