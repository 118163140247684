import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const MenuSectionLi = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  > a {
    color: ${themeStyles.colors.lightGreys[4].main};
    font-weight: 400;
    text-decoration: none;
    line-height: 1;
    white-space: nowrap;
    &:hover {
      color: ${themeStyles.colors.white.main};
    }
  }
`
