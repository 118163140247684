export const getOptimizedImage = (url = '', size) => {
  url = url?.replace('.jpeg', '')?.replace('.jpg', '')?.replace('.png', '')

  switch (size) {
    case 'q60-webp':
      return url + '-kto_compressed-q60.webp'
    case 'w300-webp':
      return url + '-kto_compressed-w300.webp'
    case 'w700-jpg':
      return url + '-kto_compressed-w700.jpg'
    case 'w700-webp':
      return url + '-kto_compressed-w700.webp'
    case 'w1024-jpg':
      return url + '-kto_compressed-w1024.jpg'
    case 'w1024-webp':
      return url + '-kto_compressed-w1024.webp'
  }

  return url
}
