import { coreApi } from './base'

export const verifyPromoCode = async (coupon) => {
  try {
    const response = await coreApi.get('/promotions/promocodes/validate', {
      params: {
        coupon,
      },
    })

    return { ok: response.data.data, error: response.data.messageCode }
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}
