import styled, { css } from 'styled-components'

export const StyledWaveWrapper = styled.div`
  width: 100%;
  position: relative;
  ${({ $direction }) =>
    $direction === 'horizontal' &&
    css`
      svg {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
      }
    `}

  ${({ $direction }) =>
    $direction === 'vertical' &&
    css`
      position: absolute;
      height: 100%;
      width: auto;
      svg {
        width: auto;
        height: 100%;
        margin-left: -1px;
      }
    `}
`
