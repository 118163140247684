import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledRow = styled.ul`
  margin: 0;
  padding: 0 0 0.5em 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  align-items: center;
  overflow: auto;

  ${({ hideGap }) =>
    !hideGap &&
    css`
      gap: 1em;
    `}

  ${({ expand }) =>
    expand &&
    css`
      width: 100%;
    `}
`

const RedBar = css`
  &:after {
    height: 2px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    position: absolute;
    content: '';
    background: ${themeStyles.colors.primary.main};
  }
`

export const StyledChildWrapper = styled.li`
  margin: 0;
  list-style: none;
  text-align: center;

  ${({ expand }) =>
    expand &&
    css`
      width: 100%;

      button:after {
        display: none;
      }
    `}

  ${({ expand, underlineChildren }) =>
    expand &&
    underlineChildren &&
    css`
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: ${themeStyles.colors.primary.main};
      }
    `}


  ${({ active, underlineChildren }) =>
    active &&
    underlineChildren &&
    css`
      border-bottom: 2px solid ${themeStyles.colors.primary.main};
      button.active:after {
        display: none;
      }
    `}

  a, .link {
    background: transparent;
    color: ${themeStyles.colors.white.main};
    padding: 0.8rem 0;
    padding-bottom: 6px;
    line-height: 25px;
    text-decoration: none;
    position: relative;
    display: inline-block;

    &.dark {
      color: ${themeStyles.colors.black.main};
    }

    &.active {
      text-decoration: none;
      ${RedBar};
    }
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
        ${RedBar};
      }
    }
  }
`

export const StyledNavigationTab = styled.button`
  border: none;
  background: transparent;
  color: ${themeStyles.colors.white.main};
  padding: 0.25em;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 17px;

  &.dark {
    color: ${themeStyles.colors.black.main};
  }

  & span {
    padding-bottom: 6px;
    border-bottom: 2px solid transparent;

    &.active,
    &:hover {
      border-color: ${themeStyles.colors.primary.main};
    }
  }
`
