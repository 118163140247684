import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Container } from '../../atomic-components/atoms/container'
import { themeStyles } from '../../styles/theme'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'

const LogoWrapper = styled.div`
  text-align: center;
  padding: 0.5rem 0;

  > a {
    &:first-child {
      margin: 0 1rem 0 0;
    }
  }
`

const ResponsibleText = styled.div`
  text-align: center;
  font-size: 0.8em;
  margin: 0 0 1rem 0;
  padding: 1rem 0 0 0;
`

const ResponsibleLink = styled(Link)`
  color: ${themeStyles.colors.white.main};
`

const FooterResponsibleGambling = () => {
  const { responsibleGaming } = useFooterApiData()

  return (
    <div>
      <Container>
        <LogoWrapper>
          {responsibleGaming.images.map((img, i) => {
            if (img.link.url !== '') {
              return (
                <a key={i} href={img.link.url} title={`${img.link.title}`}>
                  <img
                    width={img.image.width}
                    height={img.image.height}
                    loading="lazy"
                    src={`${img.image.url}`}
                    alt={`${img.image.alt}`}
                  />
                </a>
              )
            } else {
              return (
                <img
                  key={i}
                  width={img.image.width}
                  height={img.image.height}
                  loading="lazy"
                  src={`${img.image.url}`}
                  alt={`${img.image.alt}`}
                />
              )
            }
          })}
        </LogoWrapper>
        <ResponsibleLink to={`${responsibleGaming.url}`}>
          <ResponsibleText>{responsibleGaming.text}</ResponsibleText>
        </ResponsibleLink>
      </Container>
    </div>
  )
}

export default FooterResponsibleGambling
