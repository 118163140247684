import React from 'react'
import PropTypes from 'prop-types'

import { StyledDot, StyledPagination } from './styles'

const PaginationDots = (props) => {
  const { index, length } = props

  if (length <= 1) return <></>

  return (
    <StyledPagination>
      {Array(length)
        .fill()
        .map((_, i) => (
          <StyledDot
            className={`${index === i ? 'active' : ''}`}
            key={i}
            role="radio"
            aria-label={i}
            name={i}
          />
        ))}
    </StyledPagination>
  )
}

PaginationDots.propTypes = {
  index: null,
  length: 0,
}

PaginationDots.propTypes = {
  index: PropTypes.number,
  length: PropTypes.number.isRequired,
}

export { PaginationDots }
