import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContentInnerWrapper,
  StyledContentOutterWrapper,
  StyledContentUnWrapper,
} from './styles'

const ContentWrapper = (props) => {
  const { gap, gapMobile, background, small } = props

  return (
    <StyledContentOutterWrapper {...props}>
      <StyledContentInnerWrapper
        gap={gap}
        gapMobile={gapMobile}
        background={background}
        small={small}
      >
        {props.children}
      </StyledContentInnerWrapper>
    </StyledContentOutterWrapper>
  )
}

const ContentUnWrapper = (props) => {
  const { children, addPaddingToChild, addPaddingToClass } = props

  return (
    <StyledContentUnWrapper
      addPaddingToChild={addPaddingToChild}
      addPaddingToClass={addPaddingToClass}
    >
      {children}
    </StyledContentUnWrapper>
  )
}

ContentWrapper.defaultProps = {
  gap: '0em',
  gapMobile: null,
  background: null,
  small: false,
  children: <></>,
}
ContentWrapper.propTypes = {
  gap: PropTypes.string,
  gapMobile: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.any,
  small: PropTypes.bool,
}
ContentUnWrapper.defaultProps = {
  children: <></>,
  addPaddingToChild: false,
  addPaddingToClass: false,
}
ContentUnWrapper.propTypes = {
  children: PropTypes.any,
  addPaddingToChild: PropTypes.bool,
  addPaddingToClass: PropTypes.bool,
}

export { ContentWrapper, ContentUnWrapper }
