import styled, { css } from 'styled-components'
import { themeMedia } from '../../../styles/theme'
import { Title } from '../../atoms/text'
import { brandConfig } from '../../../config/brandConfig'

export const StyledWithIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-basis: content;

  ${({ hasRightIcon }) =>
    hasRightIcon &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `}

  ${({ isCtaElement }) =>
    isCtaElement &&
    css`
      margin-left: auto;
      text-decoration: underline;
      gap: 18px;

      ${themeMedia.maxTablet`
      display: none;
    `}
    `}

  ${themeMedia.maxMobile`
    gap: .2em;
  `}

  ${({ isReducedSize }) =>
    isReducedSize &&
    css`
      gap: 0.2em;
    `}
`
export const StyledTickIcon = styled.img`
  width: 20px;
  height: 20px;

  ${themeMedia.desktop`
    width: 24px;
    height: 24px;
  `}

  ${({ isSmallIcon }) =>
    isSmallIcon &&
    css`
      margin-top: 2px;
    `}
`
export const StyledText = styled(Title)`
  font-weight: 700;
  line-height: 110%;
  text-align: left;
  font-size: ${brandConfig.fonts.text.small.size.mobile};

  ${themeMedia.maxMobile`
    letter-spacing: -0.02em;
  `}

  ${themeMedia.tablet`
    font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  `}

  ${({ isReducedSize }) =>
    isReducedSize &&
    css`
      font-size: ${brandConfig.fonts.text.small.size.mobile};
    `}
`
