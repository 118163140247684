import styled from 'styled-components'

import icon_carousel_left from '../../../images/icon_carousel_left_transparent_bg.svg'
import icon_carousel_right from '../../../images/icon_carousel_right_transparent_bg.svg'
import { themeStyles } from '../../../styles/theme'

const paginationButtonSize = '2em'

export const StyledSlidesAndArrowsWrapper = styled.div`
  position: relative;

  .pmCarousel-next-button,
  .pmCarousel-previous-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: ${paginationButtonSize};
    height: ${paginationButtonSize};

    cursor: pointer;
    border-radius: 50%;
    border: ${themeStyles.colors.white.main} 2px solid;

    background-color: ${themeStyles.colors.black.main};
    background-position: center;
    background-size: 0.5em;
    background-repeat: no-repeat;

    opacity: 0;
    transition: 0.3s opacity;
  }

  .pmCarousel-previous-button {
    background-image: url(${icon_carousel_left});
    left: -${parseInt(paginationButtonSize) / 2}em;
  }

  .pmCarousel-next-button {
    background-image: url(${icon_carousel_right});
    right: -${parseInt(paginationButtonSize) / 2}em;
  }

  &:hover .pmCarousel-next-button,
  &:hover .pmCarousel-previous-button {
    opacity: 1;
  }
`
