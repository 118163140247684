import { useStaticQuery, graphql } from 'gatsby'

export const useLiveAgentApiData = () => {
  const data = useStaticQuery(graphql`
    {
      liveAgentApi {
        values {
          lang
          availablePaths {
            include_subpages
            path_of_page
          }
        }
      }
    }
  `)

  const {
    liveAgentApi: { values },
  } = data

  const availableRoutes = values?.[0]?.availablePaths || []

  return { availableRoutes }
}
