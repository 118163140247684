import React from 'react'
import styled from 'styled-components'

import { Container } from '../../atomic-components/atoms/container'
import { themeMedia } from '../../styles/theme'
import useFeatures from '../../hooks/useFeatures'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'
import { EnvConfig } from '../../config/EnvConfig'
import { If } from '../../atomic-components/atoms/if'

const LicenseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;

  ${themeMedia.tablet`
    flex-direction: row;
    padding: 1rem 2rem;
    align-items: center;
  `};
`

const LicenseLogo = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  .license-logo {
    max-width: 40px;
  }

  ${themeMedia.tablet`
      .license-logo {
        max-width: 75px;
      }
  `};
`

const LicenseText = styled.div`
  text-align: center;
  font-size: 0.85em;
  line-height: 20px;

  ${themeMedia.tablet`
      text-align: left;
  `};
`

const FooterLicense = () => {
  const isMgaLicense = useFeatures('mgaLicense')
  const { license } = useFooterApiData()

  return (
    <div>
      <Container>
        <LicenseWrapper>
          {isMgaLicense && (
            <LicenseLogo
              dangerouslySetInnerHTML={{
                __html: license?.script,
              }}
            />
          )}
          {!isMgaLicense && (
            <LicenseLogo id="license-script-tag">
              <If
                condition={!!EnvConfig.GATSBY_LICENSE_XCM_SCRIPT}
                render={() => (
                  <div
                    className="license-logo"
                    id={`xcm-${EnvConfig.GATSBY_LICENSE_XCM_SCRIPT}`}
                    data-xcm-seal-id={EnvConfig.GATSBY_LICENSE_XCM_SCRIPT}
                    data-xcm-image-size="128"
                    data-xcm-image-type="basic-small"
                  />
                )}
              />
              <If
                condition={!!EnvConfig.GATSBY_LICENSE_APG_SCRIPT}
                render={() => (
                  <div
                    className="license-logo"
                    id={`apg-${EnvConfig.GATSBY_LICENSE_APG_SCRIPT}`}
                    data-apg-seal-id={EnvConfig.GATSBY_LICENSE_APG_SCRIPT}
                    data-apg-image-size="128"
                    data-apg-image-type="basic-small"
                  />
                )}
              />
            </LicenseLogo>
          )}
          <LicenseText>{license?.description}</LicenseText>
        </LicenseWrapper>
      </Container>
    </div>
  )
}

export default FooterLicense
