import { css } from 'styled-components'

export const defaultCarouselGap = '1em'

// Slide wrapper width is calculated by essentially combining everyones
// general width and then adding the gap that was inbetween each
// General width is "thePercentageWidthThatTheSlideTakes * numberOfSlides"
export const getSlideWrapperWidthCss = (props) => {
  const { gap = defaultCarouselGap, length, maxItemsVisible = 1 } = props
  const generalWidth = (100 / maxItemsVisible) * length
  const gapDifference = parseFloat(gap) * (length / maxItemsVisible - 1)

  return css`
    width: calc(${generalWidth}% + ${gapDifference}em);
  `
}

// Slide width is calculated by dividing 100 width the number of total slides,
// this way we divide the entire StyledSlidesWrapper in even segments
// Then we subtract a few ems so it doesnt take the full segment
// css`calc(${100 / length}% - ${length > 1 ? gapSpacing : '0em'})`
const getSlideWidth = (props) => {
  const { length, maxItemsVisible = 1, addAditionalMinimizing } = props
  const slideWidth = (100 / length) * maxItemsVisible

  if (addAditionalMinimizing)
    return `calc(${slideWidth}% - ${length > 1 ? defaultCarouselGap : '0em'})`
  return `${slideWidth}%`
}

export const getSlideWidthCss = (props) => {
  return css`
    width: ${getSlideWidth(props)};
  `
}

// Transform position is calculated by taking the width of a slide,
// adding some ems (because of the gap) and then multplying it with the index
// We need the index to be a negative number so that the position goes backwards
// Lastly we add the correction, we dont need this in many places
// Right now only MobileCarousel is using it because its slides are smaller

// The remainder is used to see if there are less slides shown than needed
// (e.g. 10 slides, and we need to show 4 each time,
// meaning on the last segment, there will only be 2 slides)
export const getSlideTransformCss = (props) => {
  const {
    gap = defaultCarouselGap,
    length,
    maxItemsVisible = 1,
    correction = '0em',
    addAditionalMinimizing,
  } = props
  let { index } = props

  if (length < maxItemsVisible || length == 1 || index == 0) return ''

  const remainder = length - maxItemsVisible * index
  const slideWidth = getSlideWidth(props)
  let gapWidth = parseFloat(gap)

  if (!addAditionalMinimizing) gapWidth = gapWidth / (length / maxItemsVisible)
  if (maxItemsVisible > 1) {
    if (remainder < maxItemsVisible)
      index -= (maxItemsVisible - remainder) / maxItemsVisible
  }

  return css`
    transform: translate3d(
      calc((${slideWidth} + ${gapWidth}em) * ${-index} + ${correction}),
      0,
      0
    );
  `
}
