import styled, { css, keyframes } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

const fadeInAnimation = keyframes`
    0% { animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148); opacity: 0; }
    100% { opacity: 1; }
`

export const StyledModalWrapper = styled.div.attrs((props) => ({
  flexAlign: props.theme === 'search' ? 'start' : 'center',
}))`
  position: fixed;
  overflow: hidden;
  top: ${(props) => (props.theme === 'search' ? '50px' : '0')};
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) =>
    props.hideOverlay ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)'};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: ${(props) => props.flexAlign};
  justify-content: ${(props) => props.flexAlign};
  z-index: ${(props) => !props.hideOverlay && 999999};
  box-sizing: border-box;

  &.modal-fade {
    animation: ${fadeInAnimation} 1s 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }
`

export const StyledModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const StyledModalBody = styled.div`
  z-index: 2;
  margin: 0 auto;
  background-color: ${(props) =>
    props?.isTransparent ? 'transparent' : themeStyles.colors.white.main};
  background-clip: padding-box;
  height: ${({ mobileHeight }) => `${mobileHeight}px`};
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  color: ${themeStyles.colors.black.main};
  border: ${(props) =>
    !props.hideOverlay && '1px solid ${themeStyles.colors.black.main}'};
  box-sizing: border-box;
  padding: 0;
  position: fixed;
  top: ${(props) => (props.theme === 'search' ? '50px' : '0')};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.theme === 'game-screenshot' &&
    css`
      background-color: ${themeStyles?.colors?.darkGreys?.[3]?.main};
    `}

  ${themeMedia.tablet`
    height: initial;
    position: relative;
    max-height: 90vh;
    max-width: ${(props) => (props.isPage ? '1098px' : '600px')};
    min-height: ${(props) => (props.minHeight ? 'calc(100% - 30rem)' : 'auto')};
    border-radius: ${(props) =>
      props.theme === 'search' ? '0 0 8px 8px' : '8px'};
    padding: ${(props) => (props?.isTransparent ? '0' : '1rem 2rem 2rem')};
    top: 0;

    ${({ fullScreen }) =>
      !!fullScreen &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        overflow: auto;
        border-radius: 0;
      `}
    

    ${(props) =>
      props.paymentIQMinHeight &&
      css`
        min-height: ${props.paymentIQMinHeight}px;
      `}

    ${(props) =>
      props.theme === 'game-screenshot' &&
      css`
        min-width: 800px;
        max-width: 1000px;
        padding: 0;
      `}
    ${(props) => {
      switch (props.theme) {
        default:
          return 'min-width: auto;'
        case 'search':
          return 'min-width: 600px;'
        case 'form':
        case 'payments':
          return 'min-width: 800px;'
        case 'simple':
          return 'min-width: min(900px, 90vw);'
      }
    }}
  `};

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `}
`

export const StyledModalTitle = styled.div`
  text-align: center;
  padding: 0;
  font-weight: normal;

  ${themeMedia.tablet`
    font-size: 1.6em;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  `};
`

export const StyledModalHeader = styled.div`
  text-align: center;
  background-color: ${themeStyles.colors.black.main};
  width: 100%;
  line-height: 50px;
  color: ${themeStyles.colors.white.main};
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  box-sizing: border-box;

  ${themeMedia.tablet`
    padding: 0;
    color: ${themeStyles.colors.black.main};
    line-height: initial;
    margin-bottom: .5em;
  `};

  ${themeMedia.maxTablet`
    position: fixed;
    z-index: 1;
    margin-bottom: 5em;
  `}
`
export const StyledFixedHeaderFillSpace = styled.div`
  width: 100%;
  height: 50px;
  ${themeMedia.tablet`
    display: none;
  `}
`

export const AccountModalContent = styled.div`
  margin: 2rem 0;
  padding: 0 1rem;

  ${themeMedia.desktop`
    padding: 0;
    margin: 3.75rem 0;
  `};

  ${({ disableMargin }) =>
    !!disableMargin &&
    css`
      margin: 0 !important;
    `}
`
