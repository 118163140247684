import { useStaticQuery, graphql } from 'gatsby'
import { getActiveItems, getItemByType } from '../../dataManipulation/cmsItems'

const locale = process.env.GATSBY_INTL_LOCALE

export const useBannersApiData = (bannerKeys) => {
  const data = graphql`
    {
      bannersApi {
        values {
          lang
          banners {
            vertical
            visibility_setting
            activeTo
            activeFrom
            background_color
            header_tag
            header_text
            sub_text
            text_color
            cta_logged_out {
              action_text
              link
              background_color
              text_color
            }
            cta_logged_in {
              action_text
              link
              background_color
              text_color
            }
            background_image {
              alt
              title
              url
              sizes {
                medium
                medium_large
                large
              }
            }
            background_image_mobile {
              alt
              title
              url
              sizes {
                medium
                medium_large
                large
              }
            }
          }
        }
      }
    }
  `
  const response = useStaticQuery(data)

  const {
    bannersApi: { values },
  } = response

  const banners = values?.[0]?.banners || []

  return bannerKeys.map((key) =>
    getActiveItems(getItemByType(banners, locale, key))
  )
}
