import { useEffect, useState } from 'react'
import { isBefore } from 'date-fns'
import {
  cashoutVault,
  getUserActiveVault,
  getUserClaimedVault,
} from '../adapters/user'
import { useTranslation } from '../context/translationProvider'
import { useCashbackData } from './useCashbackData'
import useDeviceDetect from './useDeviceDetect'
import { toast } from '../atomic-components'
import { utcToZonedTime } from 'date-fns-tz'

const now = utcToZonedTime(new Date(), 'UTC')

export const useVault = ({ isLoggedIn }) => {
  const { translate } = useTranslation()
  const { isMobile } = useDeviceDetect()
  const [activeCards, setActiveCards] = useState([])
  const [claimedCards, setClaimedCards] = useState([])
  const [hasMoreClaimedCards, setHasMoreClaimedCards] = useState([])
  const [claimedPage, setClaimedPage] = useState(0)
  const [loadingVaultData, setLoadingVaultData] = useState(false)
  const [loadingCashout, setLoadingCashout] = useState(false)
  const [vaultAmount, setVaultAmount] = useState(0)
  const [shorterDeadline, setShorterDeadline] = useState({
    date: new Date(),
    expiryTime: 48,
  })
  const { getCardTemplate } = useCashbackData()

  const filterActiveCards = (cards) => {
    return cards
      ?.filter((item) => isBefore(now, new Date(item?.expiryDate)))
      ?.filter((item) => item?.amount >= 0.01)
      ?.sort((prevItem, nextItem) => {
        return isBefore(
          new Date(prevItem?.expiryDate),
          new Date(nextItem?.expiryDate)
        )
          ? -1
          : 1
      })
  }

  const getVaultData = async ({ load = true }) => {
    if (load) {
      setLoadingVaultData(true)
    }
    try {
      const activeResponse = await getUserActiveVault()
      if (claimedPage) {
        await getClaimedData({ reset: true })
      }
      if (activeResponse?.ok) {
        const activeData = filterActiveCards(activeResponse?.data?.data)
        setActiveCards(activeData)
      }
    } catch (err) {
      console.error(err)
    }
    setLoadingVaultData(false)
  }

  const getClaimedData = async ({ reset }) => {
    const pageNumber = reset ? 0 : claimedPage
    const claimedResponse = await getUserClaimedVault({ pageNumber })

    if (claimedResponse?.ok) {
      const claimedData = claimedResponse?.data?.data?.content
      if (reset) {
        setClaimedCards(claimedData)
      } else {
        setClaimedCards((current) => [...current, ...claimedData])
      }

      const currentPage = claimedResponse?.data?.data?.pageable?.pageNumber
      const totalPages = claimedResponse?.data?.data?.totalPages

      setHasMoreClaimedCards(currentPage + 1 < totalPages)
      setClaimedPage((current) => (reset ? 1 : current + 1))
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      getVaultData({ load: true })
    }
  }, [isLoggedIn])

  const cashout = async () => {
    setLoadingCashout(true)
    const response = await cashoutVault()
    const options = { hideCloseButton: isMobile, hideIcon: isMobile }
    if (response?.ok) {
      toast.success(translate('vault.pointsClaimed'), options)
      getVaultData({ load: false })
    } else {
      toast.error(translate('common.unexpectedError'), options)
    }
    setLoadingCashout(false)
  }

  const updateActiveVaultCards = async (updatedItem) => {
    if (updatedItem?.status === 'CREATED') return
    setActiveCards((current) => {
      const newActiveCards = [...current]
      const itemIndex = newActiveCards.findIndex(
        (item) => item.id === updatedItem.id
      )
      if (itemIndex === -1) {
        return filterActiveCards([...current, updatedItem])
      } else {
        newActiveCards[itemIndex] = { ...updatedItem }
        return filterActiveCards(newActiveCards)
      }
    })
  }

  useEffect(() => {
    const sum = activeCards.reduce((acc, item) => acc + item?.amount, 0)
    setVaultAmount(sum)

    let deadline
    let type
    activeCards?.forEach((item, index) => {
      if (index === 0) {
        deadline = item?.expiryDate
        type = item?.type
      } else if (isBefore(new Date(item?.expiryDate), new Date(deadline))) {
        deadline = item?.expiryDate
        type = item?.type
      }
    })

    setShorterDeadline({
      date: deadline,
      expiryTime: getCardTemplate(type)?.expiryReminder,
    })
  }, [activeCards])

  return {
    vaultAmount,
    activeCards,
    claimedCards,
    hasMoreClaimedCards,
    loadingVaultData,
    shorterDeadline,
    loadingCashout,
    getVaultData,
    updateActiveVaultCards,
    getClaimedData,
    cashout,
  }
}
