import styled from 'styled-components'

export const StyledEyeButton = styled.img`
  margin-right: -10px;
  padding: 10px;
  width: 24px;
  cursor: pointer;
`

export const StyledPasswordWrapper = styled.div`
  position: relative;
`

export const StyledRequirementsWrapper = styled.div`
  margin-top: 0.55em;
`
