import { useState, useEffect, useCallback } from 'react'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { removeLocaleFromURL } from '../../utils/generic'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

export const useSeoPagesApiData = (seo, seoPathParams, customLocation) => {
  const routerLocation = useLocation()
  const location = customLocation ?? routerLocation
  const showReadMoreConfigEnabled = useFeatureIsOn(
    'seo_content_read_more_config'
  )

  const data = graphql`
    {
      seoPagesApi {
        values {
          lang
          seo {
            path
            content
            seo_path
            show_read_more
            href_langs {
              url
              href_lang
            }
            yoast_head {
              json {
                article_modified_time
                canonical
                description
                og_description
                og_locale
                og_site_name
                og_title
                og_type
                og_url
                robots {
                  follow
                  index
                  max_image_preview
                  max_snippet
                  max_video_preview
                }
                title
                twitter_card
                schema
              }
            }
          }
        }
      }
    }
  `
  const response = useStaticQuery(data)
  const locationPath = removeLocaleFromURL(location.pathname)

  if (seo) {
    const {
      seoPagesApi: { values },
    } = response

    const seoContent = values?.[0]?.seo || []

    const [content] = seoContent.filter(
      (c) => c.path + '/' === locationPath || c.path === locationPath
    )

    return { seoContent, content, locationPath }
  }

  const decodeHTML = (paramsStr) => {
    return paramsStr.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec)
    })
  }

  const pathParamsMatchLocation = (seoPathHasParams, pathParams) => {
    if (seoPathHasParams) {
      const pathParamsCleaned = pathParams.split('?')[0]
      if (pathParamsCleaned === locationPath) return true
      if (locationPath.indexOf(pathParamsCleaned) > -1) return true
    }
  }

  const convertParamsToObj = useCallback((paramsStr) => {
    try {
      return JSON.parse(
        '{"' + paramsStr.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        (key, value) => {
          return key === '' ? value : decodeURIComponent(value)
        }
      )
    } catch (exc) {
      return ''
    }
  }, [])

  const getContent = () => {
    const {
      seoPagesApi: { values },
    } = response

    const seoContent = values?.[0]?.seo || []

    if (!seoContent.length) return null

    let params = false

    if (seoPathParams) {
      params = {}
      const cleanedSeoPathParams = JSON.parse(JSON.stringify(seoPathParams))
      for (const key in cleanedSeoPathParams) {
        params[key] = cleanedSeoPathParams[key].toString()
      }
    } else if (location.search) {
      params = convertParamsToObj(location.search.substring(1)) // removes first "?" character and convert to object
    }

    let content
    if (params.page) {
      delete params.page
    }

    const paramsLength = params ? Object.keys(params).length : 0
    if (paramsLength <= 0) {
      ;[content] = seoContent.filter((c) => c.path === locationPath)
    } else {
      for (let i = 0; i < seoContent.length; i++) {
        const seoPathHasParams = seoContent[i].path.indexOf('?') > -1
        const { path } = seoContent[i]

        if (pathParamsMatchLocation(seoPathHasParams, path)) {
          let seoParamsStr = decodeHTML(seoContent[i].path)
            .split('?')
            .slice(1)
            .join('?') // convert external SEO path
          if (seoParamsStr.substring(seoParamsStr.length - 1) === '/') {
            seoParamsStr = seoParamsStr.substring(0, seoParamsStr.length - 1) // remove last '/' from SEO path if contains it
          }
          const cleanedString = seoParamsStr.replace(/amp;/g, '')
          const seoParamsObj = convertParamsToObj(cleanedString)

          if (seoParamsObj.page) {
            delete seoParamsObj.page
          }

          let paramsMatch = true

          for (const key in seoParamsObj) {
            if (
              seoParamsObj[key] !== params[key] ||
              Object.keys(params).length !== Object.keys(seoParamsObj).length
            ) {
              paramsMatch = false
              break
            }
          }

          if (paramsMatch) {
            content = seoContent[i]
            break
          } else {
            ;[content] = seoContent.filter((c) => {
              if (!c.seo_path) return false
              return (
                c.seo_path.replaceAll('/', '') ===
                locationPath.replaceAll('/', '')
              )
            })
          }
        }
      }

      if (!content) {
        content = null
      }
    }

    if (content) return content

    return null
  }

  const content = getContent()
  const [seoContent, setSeoContent] = useState(content?.content)
  const [showReadMore, setShowReadMore] = useState(false)

  useEffect(() => {
    const content = getContent()

    setSeoContent(content?.content)
    if (locationPath === '/') setShowReadMore(true)
    if (showReadMoreConfigEnabled) setShowReadMore(content?.show_read_more)
  }, [seoPathParams, location])

  return { seoContent, collapsable: showReadMore }
}
