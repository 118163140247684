import { useContext, useEffect, useRef } from 'react'
import Radar from 'radar-sdk-js'
import { AuthContext } from '../../../context/authProvider'
import { sendUserLocation } from '../../../adapters/user'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import storageService from '../../../services/storageService'
import { EnvConfig } from '../../../config/EnvConfig'

export const GeolocationTracker = () => {
  const user = storageService.getUser()
  const { isLoggedIn, logoutUser } = useContext(AuthContext)
  const isGeolocationEnabled = useGroowthbookLocaleFeature('fe_igp_geolocation')

  const radarIntervalRef = useRef(null)

  const handleLocationTracking = () => {
    Radar.trackOnce()
      .then(async (radarTrackResponse) => {
        await sendUserLocation(radarTrackResponse)
      })
      .catch((err) => {
        console.error('Error tracking location:', err)
      })
  }

  const initRadar = async () => {
    Radar.initialize(EnvConfig.GATSBY_RADAR_KEY, {
      logLevel: 'info',
    })
    Radar.setUserId(user.id)

    try {
      const radarTrackResponse = await Radar.trackOnce()
      await sendUserLocation(radarTrackResponse)

      radarIntervalRef.current = setInterval(handleLocationTracking, 180 * 1000)
    } catch (error) {
      console.error('Error tracking location:', error)
    }
  }

  const handleLocationPermission = (state) => {
    if (state === 'granted') {
      console.warn('[RADAR] INIT', state)
      initRadar()
    }

    if (state === 'denied') {
      logoutUser()
    }
  }

  useEffect(() => {
    if (!isLoggedIn || !isGeolocationEnabled) return

    navigator.geolocation.getCurrentPosition((result) => {
      console.warn('[RADAR] GEOLOCATION RESULT', result)
      handleLocationPermission('granted')
    })

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      const { state } = result
      handleLocationPermission(state)

      result.addEventListener('change', () => {
        handleLocationPermission(result.state)
      })
    })

    return () => {
      clearInterval(radarIntervalRef.current)
    }
  }, [isLoggedIn, isGeolocationEnabled])

  return null
}
