import React from 'react'
import PropTypes from 'prop-types'

import { StyledWithIconTextWrapper, StyledTickIcon, StyledText } from './styles'

const WithIconText = ({
  usp,
  isReducedSize,
  hasRightIcon,
  isCtaElement,
  isSmallIcon,
}) => {
  return (
    <StyledWithIconTextWrapper
      hasRightIcon={hasRightIcon}
      isCtaElement={isCtaElement}
    >
      <StyledTickIcon
        src={usp.icon}
        alt={usp.title}
        isReducedSize={isReducedSize}
        isSmallIcon={isSmallIcon}
      />
      <StyledText tagType="h3" level={3} isReducedSize={isReducedSize}>
        {usp.title}
      </StyledText>
    </StyledWithIconTextWrapper>
  )
}

const WithIconTextShape = {
  icon: PropTypes.string,
  title: PropTypes.string,
}

WithIconText.defaultProps = {
  usp: {},
  isReducedSize: false,
  hasRightIcon: false,
  isCtaElement: false,
  isSmallIcon: false,
}

WithIconText.propTypes = {
  usp: PropTypes.shape(WithIconTextShape),
  isReducedSize: PropTypes.bool,
  hasRightIcon: PropTypes.bool,
  isCtaElement: PropTypes.bool,
  isSmallIcon: PropTypes.bool,
}

export { WithIconText }
