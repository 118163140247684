/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'
import { getLinkRel } from '../../../utils/getLinkRel'

const BtnBase = css`
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: ${brandConfig.fonts.text.button.weight};
  padding: 0 1em 2px;
  text-transform: capitalize;
  border-radius: 6px;
  color: ${themeStyles.colors.white.main};
  font-size: ${brandConfig.fonts.text.button.size.mobile};
  height: 50px;
  line-height: 50px;
  line-height: 100%;
  min-width: 200px;

  ${themeMedia.tablet`
    font-size: ${brandConfig.fonts.text.button.size.desktop};
  `}

  ${(props) =>
    !props.$nav &&
    themeMedia.maxMobile`
      width: 100%;
      min-width: initial;

      ${({ $mobileExpand }) =>
        !$mobileExpand &&
        css`
          width: auto;
        `}

      &.banner {
        width: initial;
      }
    `}

  ${({ $nav }) =>
    $nav &&
    css`
      font-size: ${brandConfig.fonts.text.small.size.desktop} !important;
    `}

  ${(props) => props.$expand && 'width: 100%;'}

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth} !important;
    `}
`

const BtnDisabled = css`
  font-weight: ${brandConfig.fonts.text.button.weight};
  line-height: 100%;
  text-transform: capitalize;
  border-radius: 6px;
  border-color: transparent;
  color: ${themeStyles.colors.darkGreys[4].main};
  background-color: ${themeStyles.colors.darkGreys[2].main};
  cursor: not-allowed;

  ${(props) =>
    (props.$dark || !props.$bordered) &&
    css`
      background-color: ${themeStyles.colors.lightGreys[1].main};
      color: ${themeStyles.colors.lightGreys[3].main};
    `}
`

const ButtonOrLink = (props) => {
  // Some props (e.g. isButton) cause errors in logger
  const safeProps = { ...props }
  delete safeProps.isButton

  if (props.isButton) return <button {...safeProps} />
  return (
    <Link {...safeProps} to={props?.href} rel={getLinkRel(props?.target)} />
  )
}

export const StyledButton = styled(ButtonOrLink)`
  ${BtnBase}

  ${(props) =>
    props.$red
      ? css`
          background-color: ${themeStyles.colors.primary.main};
          color: ${themeStyles.colors.primary.contrast};
        `
      : props.$bordered
      ? css`
          border-style: solid;
          border-width: 2px;
          background-color: transparent;
          border-color: ${themeStyles.colors.white.main};
          color: ${themeStyles.colors.white.main};

          ${(props) =>
            props.$dark &&
            css`
              border-color: ${themeStyles.colors.black.main};
              color: ${themeStyles.colors.black.main};
            `}
        `
      : props.$withdraw
      ? css`
          background-color: ${themeStyles.colors.black.main};
          color: ${themeStyles.colors.black.contrast};
        `
      : css`
          background-color: ${themeStyles.colors.accentColors.primary.main};
          color: ${themeStyles.colors.accentColors.primary.contrast};
        `}


  ${({ $black }) =>
    $black &&
    css`
      background-color: ${themeStyles.colors.black.main};
    `}

  ${({ $grey }) =>
    $grey &&
    css`
      background-color: ${themeStyles.colors.dominantGrey.main};
    `}

   ${(props) =>
    props.$nav &&
    css`
      height: 36px;
      min-width: initial;
      width: initial;
      ${props.$expand && 'width: 100%;'}
    `}

  ${(props) => props.disabled && BtnDisabled}

  ${({ $size }) =>
    $size === 'small' &&
    css`
      height: 42px;
      line-height: 42px;
    `}

  ${({ $size }) =>
    $size === 'extraSmall' &&
    css`
      height: 38px;
      line-height: 38px;
      padding: 6px 16px 6px 7px;
    `}

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 100px;
    `}

  ${({ $uppercase }) =>
    !$uppercase &&
    css`
      text-transform: none;
    `}

  ${({ $bold }) =>
    !$bold &&
    css`
      font-weight: normal;
    `}

  ${({ $dark, $bordered }) =>
    $dark &&
    !$bordered &&
    css`
      background: ${themeStyles.colors.dominantGrey.main};
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}

  ${({ $textColor }) =>
    $textColor &&
    css`
      color: ${$textColor};
    `}
  
  ${({ $outlineColor }) =>
    $outlineColor &&
    css`
      border-color: ${$outlineColor};
    `}
`
