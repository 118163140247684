import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Container, Counter, Value, Label, Clock } from './styles'
import { useTimer } from '../../../hooks/useTimer'
import { Image } from '../image'
import TimerIcon from '../../../images/cashback/icon_timer.svg'
import { useTranslation } from '../../../context/translationProvider'

export const Timer = ({
  endDate,
  color,
  onFinish,
  expiryReminder,
  $small = false,
  setIsExpiring = () => {},
}) => {
  const { translate } = useTranslation()
  const { days, hours, minutes, seconds } = useTimer({
    endDate,
    onFinish,
  })

  const getTotalHours = () => {
    const totalHours = days * 24 + hours
    return totalHours
  }

  const isExpiring = expiryReminder
    ? Number(expiryReminder) > getTotalHours()
    : days < 1

  useEffect(() => {
    setIsExpiring(isExpiring)
  }, [isExpiring])

  const formatValue = (value) => {
    if (!value) return '00'

    if (value < 10 && value > 0) {
      return `0${value}`
    }

    return value === 0 ? '00' : value
  }

  return (
    <Container isExpiring={isExpiring}>
      <Counter isExpiring={isExpiring} color={color}>
        <Value $small={$small}>{formatValue(days)}</Value>
        <Label $small={$small}>{translate('timer.days')}</Label>
      </Counter>

      <Counter isExpiring={isExpiring} color={color}>
        <Value $small={$small}>{formatValue(hours)}</Value>
        <Label $small={$small}>{translate('timer.hours')}</Label>
      </Counter>

      <Counter isExpiring={isExpiring} color={color}>
        <Value $small={$small}>{formatValue(minutes)}</Value>
        <Label $small={$small}>{translate('timer.minutes')}</Label>
      </Counter>

      <Counter isExpiring={isExpiring} color={color}>
        <Value $small={$small}>{formatValue(seconds)}</Value>
        <Label $small={$small}>{translate('timer.seconds')}</Label>
      </Counter>
      <Clock isExpiring={isExpiring}>
        <Image url={TimerIcon} width={$small ? '17px' : '22px'} />
      </Clock>
    </Container>
  )
}

Timer.defaultProps = {
  color: '',
  endDate: '',
  onFinish: () => {},
  expiryReminder: null,
  setIsExpiring: () => {},
}

Timer.propTypes = {
  color: PropTypes.string,
  endDate: PropTypes.string,
  onFinish: PropTypes.func,
  expiryReminder: PropTypes.number,
  $small: PropTypes.bool,
  setIsExpiring: PropTypes.func,
}
