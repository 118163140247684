import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const FooterImageSectionTitle = styled.div`
  color: ${themeStyles.colors.white.main};
  font-weight: 700;
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  text-align: center;
  margin-bottom: 1rem;

  ${themeMedia.desktop`
    text-align: left;
  `}
`
export const FooterImageSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.25rem 2.25rem 1rem 2.25rem;
  gap: 2.25rem;

  ${themeMedia.desktop`
    margin: 0;
    gap: 1.5rem;
  `}
`
export const FooterImageSectionImages = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  width: 17rem;
  justify-content: center;

  ${themeMedia.desktop`
    margin: 0;
    justify-content: start;
  `};
`
