import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { CashbackCard } from '../cashbackCard'
import { Button } from '../../atoms/button'
import { Drawer } from '../../atoms/drawer'
import { FormatAmount } from '../../atoms/formatAmount'
import { If } from '../../atoms/if'
import { Image } from '../../atoms/image'
import { Tab, Tabs } from '../../atoms/tabs'
import { Text } from '../../atoms/text'

import {
  Content,
  CardWrapper,
  EmptyContentWrapper,
  StyledCashbackButtonWrapper,
} from './styles'
import { themeStyles } from '../../../styles/theme'
import { AuthContext } from '../../../context/authProvider'
import { useTranslation } from '../../../context/translationProvider'
import { useCashbackData } from '../../../hooks/useCashbackData'

export const CashbackDrawer = ({ isOpen, onClose }) => {
  const { getCardTemplate, cashbackContent } = useCashbackData()
  const { translate } = useTranslation()
  const [tab, setTab] = useState(0)
  const {
    user,
    vaultAmount,
    activeCards,
    loadingVaultData,
    claimedCards,
    hasMoreClaimedCards,
    getClaimedData,
    cashout,
    loadingCashout,
  } = useContext(AuthContext)

  const selectTab = (value) => {
    setTab(value)
  }

  const getData = async () => {
    await getClaimedData({ reset: true })
  }

  useEffect(() => {
    if (isOpen) {
      setTab(0)
      getData()
    }
  }, [isOpen])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={cashbackContent?.title}
      theme="dark"
    >
      <Tabs expandChildren underlineChildren hideGap>
        <Tab
          onSelect={selectTab}
          label={translate('vault.active')}
          value={0}
          active={tab}
        />
        <Tab
          onSelect={selectTab}
          label={translate('vault.claimed')}
          value={1}
          active={tab}
        />
      </Tabs>
      <Content>
        <If
          condition={tab === 0}
          render={() => (
            <If
              condition={!loadingVaultData}
              render={() => (
                <If
                  condition={activeCards?.length === 0}
                  render={() => (
                    <EmptyContentWrapper>
                      <Image
                        url={cashbackContent?.image?.url}
                        width={'150px'}
                        height={'150px'}
                      />
                      <Text
                        textAlign="center"
                        style={{ color: themeStyles.colors.white.main }}
                      >
                        {translate('vault.emptyMessage')}
                      </Text>
                      <Button expand disabled bordered>
                        {translate('vault.cashout')}
                      </Button>
                    </EmptyContentWrapper>
                  )}
                  renderElse={() => (
                    <>
                      <Text
                        textAlign="center"
                        style={{ color: themeStyles.colors.white.main }}
                      >
                        {cashbackContent?.subtitle}
                      </Text>
                      <CardWrapper>
                        {activeCards.map((item) => (
                          <CashbackCard
                            key={item.id}
                            type="large"
                            value={item?.amount}
                            endDate={item?.expiryDate}
                            currency={user?.wallet?.currency?.short_code}
                            currencySymbol={user?.wallet?.currency?.symbol}
                            {...getCardTemplate(item?.type)}
                          />
                        ))}
                      </CardWrapper>
                      <StyledCashbackButtonWrapper>
                        <Button
                          expand
                          disabled={vaultAmount === 0 || loadingCashout}
                          onClick={cashout}
                        >
                          {`${translate('vault.cashout')} `}
                          <FormatAmount
                            shortCode={user?.wallet?.currency?.short_code}
                            amount={vaultAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currencySymbol={user?.wallet?.currency?.symbol}
                          />
                        </Button>
                      </StyledCashbackButtonWrapper>
                    </>
                  )}
                />
              )}
            />
          )}
        />

        <If
          condition={tab === 1}
          render={() => (
            <>
              <Text
                textAlign="center"
                style={{ color: themeStyles.colors.white.main }}
              >
                {cashbackContent?.subtitle}
              </Text>
              <CardWrapper>
                {claimedCards.map((item) => (
                  <CashbackCard
                    key={item.id}
                    type="small"
                    value={item?.claimedAmount}
                    currency={user?.wallet?.currency?.short_code}
                    currencySymbol={user?.wallet?.currency?.symbol}
                    claimDate={item?.claimDate}
                    {...getCardTemplate(item?.type)}
                  />
                ))}
              </CardWrapper>
              <If
                condition={hasMoreClaimedCards}
                render={() => (
                  <Button
                    onClick={() => getClaimedData({ reset: false })}
                    bordered
                    expand
                  >
                    {translate('common.loadMore')}
                  </Button>
                )}
              />
            </>
          )}
        />
      </Content>
    </Drawer>
  )
}

CashbackDrawer.defaultProps = {
  isOpen: false,
  onClose: () => {},
}

CashbackDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}
