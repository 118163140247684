import React from 'react'

import { FooterMenu } from '../../molecules/footerMenu'
import { FooterImagesSections } from '../../molecules/footerImagesSections'
import { FooterCookie } from '../../molecules/footerCookie'
import { FooterLogo } from '../../atoms/footerLogo'
import { FooterLicense } from '../../atoms/footerLicense'
import { ResponsibleGambling } from '../../atoms/responsibleGaming'

import { FooterLinksImages, FooterContainer } from './styles'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinksImages>
        <FooterMenu />
        <FooterImagesSections />
      </FooterLinksImages>
      <ResponsibleGambling />
      <FooterCookie />
      <FooterLicense />
      <FooterLogo />
    </FooterContainer>
  )
}

export { Footer }
