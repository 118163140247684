import { useState } from 'react'
import useResizeListener from './useResizeListener'

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(null)

  const updateDeviceType = () => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    setMobile(mobile)
  }

  useResizeListener(updateDeviceType)

  return { isMobile }
}
