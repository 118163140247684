import { useLocation } from '@reach/router'
import { parseQueryString } from '../utils/generic'

function useQueryParams() {
  const location = useLocation()
  const params = parseQueryString(location.search)

  return params
}

export default useQueryParams
