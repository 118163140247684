import React, { useEffect, useState, useContext, lazy, Suspense } from 'react'

import { AuthContext } from '../../../context/authProvider'

import { toast } from '../../atoms/toast'
import { If } from '../../atoms/if'
import { Modal } from '../../atoms/modal'
import { NavBar } from '../../molecules/navBar'
import { StyledHeaderWrapper } from './styles'
import { themeSizes } from '../../../styles/theme'
import storageService from '../../../services/storageService'
import {
  getUserNotification,
  setVisualizeNotification,
} from '../../../adapters/user'
import { useTranslation } from '../../../context/translationProvider'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'

import { getNotificationsBage } from '../../../adapters/notifications'
import useFeatures from '../../../hooks/useFeatures'
import { useMenuApiData } from '../../../hooks/graphqlStaticQuery/useMenuApiData'
import useSlugs from '../../../hooks/useSlugs'
import useScreenWidth from '../../../hooks/useScreenWidth'
import { queryStringToObject } from '../../../helpers/stringHelper'

let activationWarning = false
const NOTIFICATION_INTERVAL = 5 * 60 * 1000

const Header = (props) => {
  const { toggleCashbackDrawer, hideMobileCTA, page } = props
  const { translate } = useTranslation()
  const menuApi = useMenuApiData()
  const menuData = menuApi?.menuData

  const { user, isLoggedIn, walletSocketFallback, isPusherAvailable } =
    useContext(AuthContext)

  const [notificationModal, setNotificationModal] = useState(false)
  const [notificationContent, setNotificationContent] = useState({})
  const [Notification, setNotification] = useState(null)
  const [, setHideMobileBottomNav] = useState(false)
  const [notifications, setNotifications] = useState(0)
  const isPlayerInboxEnabled = useFeatures('playerInbox')
  const [isLoginOrRegisterPage] = useState(false)
  const [, setHasMobileCta] = useState(false)
  const [loginSlug] = useSlugs(['login'])
  const { open: openDepositDrawer } = usePaymentDrawerContext()

  if (loginSlug && !storageService.getValue('loginSlug')) {
    storageService.setValue('loginSlug', loginSlug)
  }

  const { width } = useScreenWidth(0)
  if (menuData?.length === 0 || !menuData) return null

  const updateNotificationsBadge = async () => {
    const { ok, data } = await getNotificationsBage()
    if (ok) setNotifications(data)
  }

  useEffect(() => {
    let interval

    if (isLoggedIn && isPlayerInboxEnabled) {
      updateNotificationsBadge()

      interval = setInterval(() => {
        updateNotificationsBadge()
      }, [NOTIFICATION_INTERVAL])
    }

    return () => {
      clearInterval(interval)
    }
  }, [isLoggedIn, isPlayerInboxEnabled])

  useEffect(() => {
    const checkForNotification = () => {
      const checkedNotification = storageService.getValue('notification')
      const token = storageService.getAccessToken()
      if (!checkedNotification && user && token) {
        getUserNotification().then((response) => {
          storageService.setValue('notification', 'checked')
          if (response.ok && response.data) {
            setNotificationContent(response.data.data)
            setNotificationModal(true)
          }
        })
      }
    }

    setTimeout(() => {
      checkForNotification(true)
    }, 5000)
  }, [user])

  useEffect(() => {
    if (!isLoggedIn) return
    walletSocketFallback()
  }, [isLoggedIn, walletSocketFallback, isPusherAvailable])

  useEffect(() => {
    if (isLoggedIn) {
      const { Notification } = lazy(() => import('../../atoms/notification'))
      setNotification(Notification)
    } else {
      setNotification(null)
    }
  }, [isLoggedIn])

  useEffect(() => {
    const mobileCta = menuData?.some(
      (m) => m.className.indexOf('cta mobile') >= 0
    )
      ? menuData?.filter((m) => m.className.indexOf('cta mobile') >= 0)
      : null

    setHasMobileCta(
      !!mobileCta &&
        !isLoginOrRegisterPage &&
        width < themeSizes.navbarBreakpointMobileTablet &&
        !isLoggedIn
    )
  }, [menuData, width, isLoginOrRegisterPage, isLoggedIn])

  const handleCloseModal = () => {
    setNotificationModal(false)
    setVisualizeNotification()
  }

  if (
    user?.user_status?.name?.toLowerCase() === 'pending' &&
    !activationWarning
  ) {
    activationWarning = true

    toast.error(translate('account.not-verified'), {
      autoClose: false,
      position: 'bottom-left',
    })
  }

  useEffect(() => {
    const urlParams = queryStringToObject(window?.location?.search)
    if (urlParams?.action === 'deposit') {
      openDepositDrawer()
    }
  }, [])

  return (
    <StyledHeaderWrapper
      isLoggedIn={isLoggedIn}
      hideMobileBottomNav={hideMobileCTA}
      hideMobileCTA={hideMobileCTA}
      page={page}
    >
      <NavBar
        data={menuData}
        notifications={notifications}
        updateNotifications={updateNotificationsBadge}
        toggleCashbackDrawer={toggleCashbackDrawer}
        setHideMobileBottomNav={setHideMobileBottomNav}
        hideMobileCTA={hideMobileCTA}
      />
      <If
        condition={notificationModal && Notification}
        render={() => (
          <Suspense fallback={<div />}>
            <Modal
              title={notificationContent.title}
              isOpen
              onClose={handleCloseModal}
            >
              <Notification
                message={notificationContent.message}
                onBtnClick={handleCloseModal}
              />
            </Modal>
          </Suspense>
        )}
      />
    </StyledHeaderWrapper>
  )
}

export { Header }
