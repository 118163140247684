import React, { useEffect, useRef, useState } from 'react'
import useFeatures from '../../hooks/useFeatures'
import { EnvConfig } from '../../config/EnvConfig'
import { isBrowser } from '../../utils/generic'

const LicenseScript = () => {
  const prevScrollY = useRef()
  const [loadedXcmScript, setXcmScriptLoaded] = useState(false)
  const [loadedApgcript, setApgScriptLoaded] = useState(false)

  const isMgaLicense = useFeatures('mgaLicense')

  useEffect(() => {
    if (!isBrowser() || isMgaLicense) return

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY) {
        const scriptElement = document.getElementById('license-script')
        if (!scriptElement) {
          if (EnvConfig?.GATSBY_LICENSE_XCM_SCRIPT) {
            const scriptXcm = document.createElement('script')
            scriptXcm.src = `https://${EnvConfig.GATSBY_LICENSE_XCM_SCRIPT}.seals-xcm.certria.com/xcm-seal.js`
            scriptXcm.defer = true
            scriptXcm.id = 'license-script'
            scriptXcm.addEventListener('load', () => setXcmScriptLoaded(true))
            document.body.appendChild(scriptXcm)
          }

          if (EnvConfig?.GATSBY_LICENSE_APG_SCRIPT) {
            const scriptApg = document.createElement('script')
            scriptApg.src = `https://${EnvConfig.GATSBY_LICENSE_APG_SCRIPT}.snippet.antillephone.com/apg-seal.js`
            scriptApg.defer = true
            scriptApg.id = 'license-script'
            scriptApg.addEventListener('load', () => setApgScriptLoaded(true))
            document.body.appendChild(scriptApg)
          }
        } else {
          if (!loadedXcmScript) setXcmScriptLoaded(true)
          if (!loadedApgcript) setApgScriptLoaded(true)
        }
      }

      prevScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    window.addEventListener('mousemove', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('mousemove', handleScroll)
    }
  }, [isMgaLicense])

  useEffect(() => {
    if (!isBrowser() || isMgaLicense || !loadedXcmScript) return

    try {
      /* eslint-disable no-undef */
      const XCM_SCRIPT = `xcm_${EnvConfig.GATSBY_LICENSE_XCM_SCRIPT?.replaceAll(
        '-',
        '_'
      )}`
      window?.[XCM_SCRIPT]?.init()
    } catch (ex) {
      console.error(ex)
    }
  }, [loadedXcmScript, isMgaLicense])

  useEffect(() => {
    if (!isBrowser() || isMgaLicense || !loadedApgcript) return

    try {
      /* eslint-disable no-undef */
      const APG_SCRIPT = `apg_${EnvConfig.GATSBY_LICENSE_APG_SCRIPT?.replaceAll(
        '-',
        '_'
      )}`
      window?.[APG_SCRIPT]?.init()
    } catch (ex) {
      console.error(ex)
    }
  }, [loadedApgcript, isMgaLicense])

  return <></>
}

export default LicenseScript
