import React, { useEffect, useState } from 'react'
import { NavLink } from '../navLink'
import { useTranslation } from '../../../context/translationProvider'
import { useModalContext } from '../../../context/modalProvider'
import { ModalTypes } from '../../../utils/constants'

const notificationCta = {
  className: 'cta notification',
  name: 'Notification',
  section: 'right',
  show_icon: 'both',
  type: 'icon',
}

export const NotificationBadge = ({
  showArrow,
  notifications,
  updateNotifications,
}) => {
  const [animate, setAnimate] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const { modalStatus, close, open } = useModalContext()
  const { translate } = useTranslation()

  useEffect(() => {
    if (notifications > 0) {
      setAnimate(true)
    }
  }, [notifications])

  const onAnimationEnd = () => {
    setAnimate(false)
  }

  const togglePlayerInbox = async () => {
    setNotificationText(null)

    if (modalStatus) {
      close()
    } else {
      open({ type: ModalTypes.INBOX })
    }

    await updateNotifications()
  }

  useEffect(() => {
    setNotificationText(notifications)
  }, [notifications])

  return (
    <NavLink
      locale={process.env.GATSBY_INTL_LOCALE}
      menuItem={{
        ...notificationCta,
        animate,
        onAnimationEnd,
        onClick: togglePlayerInbox,
        text: notificationText,
        showArrow,
        title: translate('playerInbox.title'),
      }}
    />
  )
}
