import React from 'react'
import PropTypes from 'prop-types'

import { Field } from '../field'
import { Message } from '../../atoms/message'

import { validateNationalId } from '../../../helpers/validators'
import { getNationalIdFieldType } from '../../../utils/nationalID'
import { useTranslation } from '../../../context/translationProvider'

const FieldNationalId = (props) => {
  const {
    hasBeenSubmitted,
    disabled,
    formProperties,
    description,
    descriptionTheme,
    country,
    hideSymbols,
    showFieldOnlyOn,
    onFocus,
    onBlur,
  } = props
  const { translate } = useTranslation()
  const {
    register,
    getValues,
    trigger,
    formState: { errors },
    resetField,
  } = formProperties

  const key = 'national_id'

  if (showFieldOnlyOn && !showFieldOnlyOn.includes(country?.code)) return <></>

  const handleOnBlur = () => {
    trigger(key).then(() => {
      onBlur()
    })
  }

  const handleOnChange = (event) => {
    resetField(key, {
      keepDirty: true,
      keepTouched: false,
      defaultValue: event.target.value,
    }) // Reset touched state, keep dirty, set new value
  }

  return (
    <Field
      showErrorMsg
      disabled={disabled}
      description={description}
      descriptionTheme={descriptionTheme}
      autoCompleteOff
      type={getNationalIdFieldType(country?.national_id_mask)}
      label={
        country?.national_id_label ||
        translate('register.default_national_id_label')
      }
      mask={country?.national_id_mask}
      placeholder={country?.national_id_mask}
      errors={errors}
      fieldValue={getValues(key)}
      defaultValue={getValues(key)}
      hasBeenSubmitted={hasBeenSubmitted}
      onChange={handleOnChange}
      formProperties={{
        ...register(key, {
          required: true,
          validate: (value) =>
            validateNationalId(
              value,
              country,
              translate('cpf.register.invalidFormat')
            ),
        }),
        formState: formProperties.formState,
        getFieldState: formProperties.getFieldState,
      }}
      onFocus={onFocus}
      onBlur={handleOnBlur}
      hideSymbols={hideSymbols}
      renderError={
        errors.national_id?.message &&
        (() => (
          <Message
            error
            style={{ marginBottom: '1em' }}
            text={
              <span
                dangerouslySetInnerHTML={{
                  __html: errors.national_id?.message,
                }}
              ></span>
            }
          />
        ))
      }
    />
  )
}

FieldNationalId.defaultProps = {
  formProperties: undefined,
  country: {},
  hasBeenSubmitted: null,
  disabled: false,
  hideSymbols: false,
  currentValue: '',
  description: '',
  descriptionTheme: null,
  showFieldOnlyOn: null,
  onFocus: () => {},
  onBlur: () => {},
}

FieldNationalId.propTypes = {
  formProperties: PropTypes.object,
  country: PropTypes.object,
  hasBeenSubmitted: PropTypes.bool,
  hideSymbols: PropTypes.bool,
  disabled: PropTypes.bool,
  currentValue: PropTypes.string,
  description: PropTypes.string,
  descriptionTheme: PropTypes.string,
  showFieldOnlyOn: PropTypes.array,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export { FieldNationalId }
