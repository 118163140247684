const setURLParams = (url, params) => {
  return params?.forEach?.(({ param, value, disableEncode = false }) => {
    if (!value) return

    if (disableEncode) return setParamUnencoded(url, param, value)

    url.searchParams.set(param, value)
  })
}

const setParamUnencoded = (url, key, value) => {
  url.searchParams.set(key, value)
  const entries = Array.from(url.searchParams.entries())
  url.search = ''
  entries.forEach((item) => {
    if (item[0] === key) return
    url.searchParams.set(item[0], item[1])
  })
  url.search += `&${key}=${value}`
}

export const generateURL = ({ baseURL, options }) => {
  const url = new URL(baseURL)
  setURLParams(url, options)
  return url?.toString()
}

export const convertOptionsToQueryParams = (options) => {
  let queryParams = '?'

  options?.forEach(({ param, value }) => {
    if (param && value) {
      queryParams = `${queryParams}${param}=${encodeURIComponent(value)}&`
    }
  })

  if (queryParams?.endsWith('&')) {
    queryParams = queryParams.slice(0, -1)
  }

  return queryParams
}
