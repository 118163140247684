import { coreApi } from './base'

export const getRollbackWithdrawalSettings = async () => {
  try {
    const { data } = await coreApi.get('/settings')
    const { withdrawal_rollback } = data.data
    return withdrawal_rollback
  } catch (error) {
    return false
  }
}
