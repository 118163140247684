const ENV_LOCALE = process.env.GATSBY_INTL_LOCALE

export const formatCurrency = (
  amount,
  options = {},
  addCustomFormating = true
) => {
  const { shortCode, currencySymbol, locale = ENV_LOCALE } = options

  const formattingOptions = { ...options }
  if (shortCode) {
    formattingOptions.style = currencySymbol ? 'decimal' : 'currency'
  }
  if (!currencySymbol) {
    formattingOptions.currency = shortCode
    if (amount == '') return ''
  }

  let formattedAmount = new Intl.NumberFormat(locale, formattingOptions).format(
    amount
  )

  if (addCustomFormating) {
    formattedAmount = getFormattedValue(amount, formattedAmount)
  }

  if (!currencySymbol) return formattedAmount
  return `${currencySymbol} ${formattedAmount}`
}

const getFormattedValue = (amount, formattedAmount) => {
  const amountStr = amount?.toString() ?? ''
  const amountSeparatorIndex = amountStr.indexOf('.')

  if (amountSeparatorIndex <= 0) return formattedAmount

  const decimalNumbers = amountStr.slice(amountSeparatorIndex + 1)
  const decimalSeparator = getDecimalSeparator()
  const newDecimalSeparatorIndex = formattedAmount.indexOf(decimalSeparator)

  if (newDecimalSeparatorIndex <= 0) {
    return formattedAmount + decimalSeparator + decimalNumbers
  }

  return (
    formattedAmount.slice(0, newDecimalSeparatorIndex) +
    decimalSeparator +
    decimalNumbers
  )
}

export const getDecimalSeparator = (locale = ENV_LOCALE) => {
  const numberWithDecimalSeparator = 1.1
  return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2)
}

export const getFloatNumberFromInputEvent = (value) => {
  return (value ?? '')
    .replaceAll(',', '.')
    .replaceAll(/[^0-9.]/g, '')
    .replace(/(\.\d\d)\d*$/, '$1')
    .replace(/^(\d*\.\d*).*$/, '$1')
}
