import React from 'react'
import styled from 'styled-components'

import CookieSettings from './cookieSettings'
import useModal from '../../hooks/useModal'
import { Modal } from '../../atomic-components/atoms/modal'
import { Container } from '../../atomic-components/atoms/container'

import icon_carousel_right from '../../images/icon_cookie_settings.svg'
import { themeStyles } from '../../styles/theme'
import { useFooterApiData } from '../../hooks/graphqlStaticQuery/useFooterApiData'
import { useTranslation } from '../../context/translationProvider'

const CookieWrapper = styled.div`
  text-align: center;
  padding: 1rem 0;
`

const CookieButton = styled.button.attrs({ type: 'button' })`
  outline: none;
  color: ${themeStyles.colors.white.main};
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border-radius: 4px;
  background-color: ${themeStyles.colors.darkGreys[4].main};
  background-image: url('${icon_carousel_right}');
  background-repeat: no-repeat;
  background-position: 7px;
  font-weight: 300;
`

const FooterCookie = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const { cookieSettings } = useFooterApiData()
  const { translate } = useTranslation()

  return (
    <div>
      <Container>
        <CookieWrapper>
          <CookieButton onClick={openModal}>{cookieSettings.text}</CookieButton>
        </CookieWrapper>
      </Container>

      {isOpen && (
        <Modal
          title={translate('common.cookie.settings')}
          isOpen={isOpen}
          onClose={closeModal}
        >
          <CookieSettings onClose={closeModal} />
        </Modal>
      )}
    </div>
  )
}

export default FooterCookie
