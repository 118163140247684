import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const MenuWrapper = styled.div`
  display: none;
  ${themeMedia.desktop`
    display: grid;
  `}
  width: 100%;
  max-width: 1050px;
  min-width: 1px;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
`

export const MenuSection = styled.div`
  text-align: left;
  margin-bottom: 1rem;
`

export const MenuSectionTitle = styled.div`
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  margin-bottom: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
`

export const MenuSectionUl = styled.ul`
  margin: 0;
  padding: 0;
  font-size: ${brandConfig.fonts.text.small.size.desktop};
  line-height: 25px;
`
