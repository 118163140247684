import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledLogoWrapper = styled.div`
  display: flex;
  margin: 1.75rem auto;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  flex-wrap: wrap;

  picture {
    background: transparent;
  }

  img {
    height: 36px;
    width: auto;
  }
`

export const StyledResponsibleText = styled.div`
  font-size: 14px;
  margin: 1rem auto 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    display: flex;
    text-align: center;
    line-height: 1.65;
    margin: 0 auto;
  }

  ${themeMedia.desktop`
    flex-direction: row;
    * {
      margin: 0 .1rem; 
    }
  `}

  a {
    color: ${themeStyles.colors.lightGreys[4].main};
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: ${themeStyles.colors.white.main};
    }
  }
`
