import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

import flag_en from '../../../images/flag_en.svg'
import flag_pt from '../../../images/flag_pt.svg'
import flag_ca from '../../../images/flag_ca.svg'
import flag_pe from '../../../images/flag_pe.png'
import flag_cl from '../../../images/flag_cl.svg'

export const StyledLanguageWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 0 1.5rem 0;

  ${themeMedia.tablet`
        padding: 0 10% 1.5rem;
    `};

  ${themeMedia.desktop`
        padding: 0 15% 1.5rem;
    `};
`

export const StyledLang = styled.div`
  border-radius: 50%;
  width: 52px;
  height: 52px;
  box-sizing: border-box;
  padding: 0.35rem;
  margin: 0.25rem;

  &:hover,
  &.active {
    border: 2px solid ${themeStyles.colors.accentColors.secondary.main};
    padding: 0.25rem;
  }
`

export const StyledLanguageHref = styled.a`
  border-radius: 50%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;

  ${(props) => {
    switch (props.code) {
      case 'en':
      default:
        return `
            background-image: url(${flag_en});
        `
      case 'pt':
        return `
            background-image: url(${flag_pt});
        `
      case 'ca':
        return `
            background-image: url(${flag_ca});
        `
      case 'pe':
        return `
            background-image: url(${flag_pe});
        `
      case 'cl':
        return `
            background-image: url(${flag_cl});
        `
    }
  }}
`
