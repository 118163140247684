import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { DesktopFormCarousel } from '../../molecules/desktopFormCarousel'
import { ResponsiveContainer } from '../../atoms/responsiveContainer'
import { themeSizes } from '../../../styles/theme'
import { filterSlides } from '../../../dataManipulation/cmsItems'
import { AuthContext } from '../../../context/authProvider'
import useCarouselSteps from '../../../hooks/useCarouselSteps'
import { StyledFormBannerWrapper } from './styles'
import { MobileCarousel } from '../../molecules/mobileCarousel'

const FormBanner = (props) => {
  const { slides, hideMobileNextButton, mobileBannerHeight } = props
  const { isLoggedIn } = useContext(AuthContext)
  const filteredSlides = filterSlides(slides, isLoggedIn)
  const { length } = filteredSlides
  const { index, onPrevious, onNext } = useCarouselSteps(length)

  if (length === 0) return <></>

  return (
    <StyledFormBannerWrapper>
      <ResponsiveContainer
        sizeNumber={themeSizes.tablet}
        render={() => (
          <MobileCarousel
            key="MobileCarousel"
            slides={filteredSlides}
            index={index}
            onPrevious={onPrevious}
            onNext={onNext}
            hideMobileNextButton={hideMobileNextButton}
            bannerHeight={mobileBannerHeight}
          />
        )}
        renderElse={() => (
          <DesktopFormCarousel
            key="DesktopCarousel"
            slides={filteredSlides}
            index={index}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        )}
      />
    </StyledFormBannerWrapper>
  )
}

FormBanner.defaultProps = {
  slides: [],
  setHideWrapperPadding: null,
  hideMobileNextButton: false,
  mobileBannerHeight: null,
}

FormBanner.propTypes = {
  backgroundColor: PropTypes.string,
  mobileHeight: PropTypes.string,
  desktopHeight: PropTypes.string,
  setHideWrapperPadding: PropTypes.func,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      background_color: PropTypes.string,
      header_tag: PropTypes.string,
      header_text: PropTypes.string,
      sub_text: PropTypes.string,
      text_color: PropTypes.string,
      visibility_setting: PropTypes.string,
      cta_logged_out: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      cta_logged_in: PropTypes.arrayOf(
        PropTypes.shape({
          action_text: PropTypes.string,
          link: PropTypes.string,
          background_color: PropTypes.string,
          text_color: PropTypes.string,
        })
      ),
      background_image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ),
  hideMobileNextButton: PropTypes.bool,
  mobileBannerHeight: PropTypes.number,
}

export { FormBanner }
