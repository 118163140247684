import React from 'react'

import FooterMenu from '../../../templates/footer/footerMenu'
import FooterCookie from '../../../templates/footer/footerCookie'
import FooterLicense from '../../../templates/footer/footerLicense'
import FooterLogo from '../../../templates/footer/footerLogo'
import FooterSponsors from '../../../templates/footer/footerSponsors'
import SocialMediaLinks from '../../../templates/footer/socialMediaLinks'
import FooterResponsibleGambling from '../../../templates/footer/footerResponsibleGambling'

import { Language } from '../../atoms/language'
import { If } from '../../atoms/if'

import { useFooterApiData } from '../../../hooks/graphqlStaticQuery/useFooterApiData'
import { StyledFooterElement } from './styles'

const FooterV1 = () => {
  const { cookieSettings, socialMediaLinks } = useFooterApiData()

  return (
    <StyledFooterElement>
      <FooterMenu />
      <Language />
      <FooterResponsibleGambling />
      <If
        condition={cookieSettings.cookieSettingsEnabled}
        render={() => <FooterCookie />}
      />
      <FooterLicense />
      <FooterSponsors />
      <SocialMediaLinks data={socialMediaLinks} />
      <FooterLogo />
    </StyledFooterElement>
  )
}

export { FooterV1 }
