import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import Checkmark from '../../../images/icon_tick.svg'

export const StyledOuterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const StyledStepsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;

  ${themeMedia.tablet`
    width: 326px;
  `}

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 1.5px;
    top: 50%;
    z-index: 0;

    background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 4px,
      ${themeStyles.colors.lightGreys[1].main} 4px,
      ${themeStyles.colors.lightGreys[1].main} 10px
    );
  }
`

const circleSize = '30px'

export const StyledProgressBarStep = styled.span`
  width: ${circleSize};
  height: ${circleSize};
  line-height: calc(${circleSize} - 0.2em);
  position: relative;
  border-radius: 100%;
  text-align: center;
  font-size: 13px;
  background-color: ${themeStyles.colors.lightGreys[1].main};
  border: 3px solid ${themeStyles.colors.lightGreys[1].main};
  z-index: 1;
  box-sizing: border-box;
  font-weight: bold;

  ${(props) =>
    !props.useOldFlow &&
    css`
      font-size: 0;
      background-image: url('${(props) => props.icon}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;

      &:after {
        font-size: 13px;
        content: '${(props) => props.text}';
        position: absolute;
        top: 100%;
        white-space: nowrap;
        font-weight: initial;
      }
    `}

  &:first-child:after {
    left: 0;
  }

  &:last-child:after {
    left: initial;
    right: 0;
  }

  &:not(:last-child):not(:first-child):after {
    left: 50%;
    transform: translateX(-50%);
  }

  ${(props) =>
    props.isDone &&
    css`
      background-color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.lightGreys[1].main};
      background-image: url('${Checkmark}');
      font-size: 0;
    `}

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.accentColors.secondary.main};
    `}

  &:last-child {
    &:before {
      width: 0;
    }
  }
`

export const StyledBackButtonWrapper = styled.div`
  ${themeMedia.maxTablet`
    display: none;
  `}
`
