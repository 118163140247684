import styled from 'styled-components'

export const IFrameWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;

  iframe {
    display: block;
    box-sizing: border-box;
    min-height: ${({ minHeight }) => minHeight || '100%'};
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
  }
`
