import { useRef, useEffect, useCallback } from 'react'

export const useDebounce = (callback, initialDelay) => {
  const latestCallback = useRef()
  const latestTimeout = useRef()
  const delayRef = useRef(initialDelay)

  useEffect(() => {
    latestCallback.current = callback
  }, [callback])

  const debouncedFunction = useCallback((...params) => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current)
    }
    if (delayRef.current === 0) {
      latestCallback.current(...params)
    } else {
      latestTimeout.current = setTimeout(() => {
        latestCallback.current(...params)
      }, delayRef.current)
    }
  }, [])

  // Providing the debounced function and
  // the delay updater as properties on the returned function
  const debouncedCallback = (...params) => {
    debouncedFunction(...params)
  }
  debouncedCallback.setDelay = (newDelay) => {
    delayRef.current = newDelay
  }

  return debouncedCallback
}
