import styled from 'styled-components'
import IconArrowDown from '../../../images/icon_arrow_down.svg'
import IconArrowDownDisabled from '../../../images/icon_arrow_down_disabled.svg'
import { InputCSS } from '../../atoms/fieldInputArea/styles'
import { themeStyles } from '../../../styles/theme'

export const StyledSelect = styled.select`
  ${InputCSS}

  display: block;
  appearance: none;
  background-image: url(${IconArrowDown});
  background-repeat: no-repeat;
  background-position: right 0.75em center;
  background-size: 13px;

  &:disabled {
    background-image: url(${IconArrowDownDisabled});
  }

  ${(props) =>
    props.disableFirstOption &&
    props.value == '' &&
    `
    color: ${themeStyles.colors.lightGreys[3].main};

    option:not(:first-child) {
      color: black;
    }
  `}
`
