import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CafIFrame } from '../../../../molecules/cafIFrame'
import { StyledSelfieAndIdStep } from '../../styles'
import { Text, Title } from '../../../../atoms/text'
import { Image } from '../../../../atoms/image'
import icon_selfie_and_id from '../../../../../images/icon_selfie_and_id_gray.svg'
import { Button } from '../../../../atoms/button'
import { doCafIframeCheck } from '../../../../../adapters/auth'
import { FIVE_SECONDS } from '../../../../../utils/constants'
import { toast } from '../../../../atoms'
import { useTranslation } from '../../../../../context/translationProvider'

const SelfieAndIdStep = (props) => {
  const { cafData, onCompleteStep, stepIsDone } = props
  const { translate } = useTranslation()

  const [showIframe, setShowIframe] = useState(0)
  // const [loading, setLoading] = useState(false)
  const { onboardingUrl, onboardingId } = cafData ?? {}

  const onStart = () => {
    setShowIframe(true)
  }

  // const onIframeComplete = async (status) => {
  //   if (status !== CAF_MESSAGE_CODES.ONBOARDING_FINISHED) return

  //   setLoading(true)

  //   const { ok, data } = await doCafIframeCheck(onboardingId)
  //   if (ok && data?.status === 'APPROVED') {
  //     onCompleteStep()
  //     return
  //   }
  //   if (data?.status === 'REJECTED') {
  //     toast.error(translate('registration.iframe.rejected'))
  //     setLoading(false)
  //     return
  //   }

  //   setTimeout(() => onIframeComplete(), ONE_SECOND * 2)
  // }

  const poolIframeCheck = async () => {
    const { ok, data } = await doCafIframeCheck(onboardingId)
    if (ok && data?.status === 'APPROVED') {
      onCompleteStep()
    }
    if (data?.status === 'REJECTED') {
      toast.error(translate('registration.iframe.rejected'))
    }
  }

  useEffect(() => {
    const timerInterval = setInterval(poolIframeCheck, FIVE_SECONDS)
    return function () {
      clearInterval(timerInterval)
    }
  })

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '100%',
  //       }}
  //     >
  //       <Spinner
  //         color={themeStyles.colors.accentColors.primary.main}
  //         size={'1.5em'}
  //       />
  //     </div>
  //   )
  // }

  if (showIframe) {
    return (
      <StyledSelfieAndIdStep className="iframe-wrapper">
        <CafIFrame
          shouldUpdateUser={false}
          cafAltIframeUrl={onboardingUrl}
          closeIFrame={() => {}} // TODO: Utilize this function better
        />
      </StyledSelfieAndIdStep>
    )
  }

  if (stepIsDone) {
    return (
      <StyledSelfieAndIdStep>
        <Title level={2}>{translate('registration.selfieAndId.title')}</Title>

        <Image url={icon_selfie_and_id} />
        <Text textAlign="center">
          <span
            dangerouslySetInnerHTML={{
              __html: translate('registration.selfieAndId.verifiedDescription'),
            }}
          />
        </Text>
        <Button onClick={() => onCompleteStep()}>
          {translate('registration.next')}
        </Button>
      </StyledSelfieAndIdStep>
    )
  }

  return (
    <StyledSelfieAndIdStep>
      <Title level={2}>{translate('registration.selfieAndId.title')}</Title>

      <Image url={icon_selfie_and_id} />
      <Text textAlign="center">
        <span
          dangerouslySetInnerHTML={{
            __html: translate('registration.selfieAndId.normalDescription'),
          }}
        />
      </Text>
      <Button onClick={onStart}>{translate('common.start')}</Button>
    </StyledSelfieAndIdStep>
  )
}

SelfieAndIdStep.propTypes = {
  cafData: PropTypes.object,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,
}

export { SelfieAndIdStep }
