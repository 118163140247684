import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { CpfModalContent } from '../cpfModalContent'
import { Modal } from '../../atoms/modal'
import { AuthContext } from '../../../context/authProvider'
import storageService from '../../../services/storageService'
import useCookie from '../../../hooks/useCookie'
import useModal from '../../../hooks/useModal'
import { cookiePrefix } from '../../../utils/cookies'

const REMINDER_DAYS = 3
const HIDE_REMINDE_ME_LATER = true

export const CpfModal = (props) => {
  const { closeReminderModal } = props

  const { user, isLoggedIn, safeSetUser } = useContext(AuthContext)
  const { openModal, closeModal, isOpen } = useModal()
  const [cpfReminderCookie, updateCpfReminderCookie] = useCookie(
    `${cookiePrefix}cpf:reminder`,
    null
  )

  const cpfSent = storageService.getSessionValue('cpf:sent')

  useEffect(() => {
    if (!isLoggedIn) closeModal()
  }, [isLoggedIn])

  useEffect(() => {
    openModalIfSafe()
  }, [user, isOpen, cpfReminderCookie])

  const openModalIfSafe = () => {
    if (cpfSent) {
      closeModal()
      return
    }

    if (user && user?.country?.id === 31 && !user?.national_id) {
      openModal()
    }
  }

  const remindLater = () => {
    if (HIDE_REMINDE_ME_LATER) {
      closeModal()
      return
    }

    storageService.setValue('cpf:reminder', 'true')
    updateCpfReminderCookie(true, REMINDER_DAYS)
    closeModal()
    if (closeReminderModal) {
      closeReminderModal()
    }
  }

  const onSubmit = async () => {
    storageService.setSessionValue('cpf:sent', 'true')
    await safeSetUser()
    closeModal()
    window.location.reload(true)

    if (closeReminderModal) {
      closeReminderModal()
    }
  }

  if (!isOpen || cpfSent) return <></>

  return (
    <Modal theme="payments" title="CPF" isOpen onClose={remindLater} hideClose>
      <CpfModalContent
        onClose={remindLater}
        onSubmit={onSubmit}
        hideRemindMeLater={HIDE_REMINDE_ME_LATER}
      />
    </Modal>
  )
}

CpfModal.defaultProps = {
  closeReminderModal: () => {},
}

CpfModal.propTypes = {
  closeReminderModal: PropTypes.func,
}

export default CpfModal
