import { apiGET, apiPATCH, apiPOST, apiPUT } from '../utils/adapterFunctions'
import { getWebsiteUrl } from '../utils/getWebsiteUrl'
import { getCookie } from '../utils/cookies'
import { EnvConfig } from '../config/EnvConfig'

const COMPLETE_SITE_URL = getWebsiteUrl()
const LOGIN_2FA_EMAIL_LINK = `${COMPLETE_SITE_URL}verify2fa/`
const GAToken = getCookie('_ga')
const GA_SESSION_ID = getCookie(EnvConfig.GATSBY_GA_COOKIE)

export const doLogin = async (username, password, token) => {
  return await apiPOST('/auth/login', {
    username,
    password,
    token,
    emailRedirectionLink: LOGIN_2FA_EMAIL_LINK,
    gaCookieSessionValue: GA_SESSION_ID,
    gaCookieValue: GAToken,
  })
}

export const doLogout = async () => {
  return await apiPOST('/auth/logout', null, { hasNoData: true })
}

export const getWallet = async () => {
  return await apiGET('/wallet')
}

export const doForgotPassword = async (email, returnUrl, token) => {
  return await apiPOST(
    '/auth/password/forgot/',
    {
      email,
      password_reset_url: returnUrl,
      token,
    },
    { hasNoData: true }
  )
}

export const doActivate = async (token) => {
  return await apiPOST(`/auth/activate/${token}/`, null, {
    hasNestedData: false,
  })
}

export const doVerifyEmail = async (token) => {
  return await apiPUT(`/auth/email/verify/${token}/`, null)
}

export const doResetPassword = async (model) => {
  return apiPOST('/auth/password/reset/', model, { hasNoData: true })
}

export const doCpfCheck = async ({ cpf, token = null }) => {
  return apiPOST(
    '/registration/cpf/check',
    {
      cpf,
      token,
    },
    {
      hasNestedData: false,
      hasValid: true,
    }
  )
}

export const doCafIframeCheck = async (onboardingId) => {
  return apiGET(`/documents/${onboardingId}`, null, { hasNestedData: false })
}

export const doEmailCheck = async (email) => {
  return apiPOST(
    '/registration/email/check',
    { email },
    { hasNestedData: false, hasValid: true }
  )
}

export const doSendValidationEmail = async (
  email,
  national_id,
  language_code
) => {
  return apiPOST('/registration/email/send-token', {
    national_id,
    language_code,
    email,
  })
}

export const doValidateEmail = async (email, national_id, token) => {
  return apiPOST('/registration/email/validate-token', {
    national_id,
    token,
    email,
  })
}

export const doSendLoggedInValidationEmail = async () => {
  return apiPOST('/player/email/send-token', {})
}

export const doLoggedInValidateEmail = async (token) => {
  return apiPOST('/player/email/validate-token', { token })
}

export const doSendValidationSmsOnVerification = () => {
  return apiPOST('/player/mobile-number/send-collection-verification-sms')
}

export const doValidateSmsOnVerification = (verification_code) => {
  return apiPOST(
    '/player/mobile-number/check-collection-verification-sms-code',
    { verification_code }
  )
}

export const doSendValidationSms = async (mobile_prefix, mobile_number) => {
  return apiPOST('/mobile-verification/send-verification-sms', {
    mobile_prefix,
    mobile_number,
  })
}

export const doValidateSms = async (
  mobile_prefix,
  mobile_number,
  verification_code,
  national_id,
  user_id
) => {
  return apiPOST('/mobile-verification/check-verification-sms-code', {
    mobile_prefix,
    mobile_number,
    verification_code,
    national_id,
    user_id,
  })
}

export const doLoggedInValidateSms = async (verification_code) => {
  return apiPATCH('/mobile-verification/verify-verification-sms-code', {
    verification_code,
  })
}

export const doRegistration = async (model, useOldFlow) => {
  return await apiPOST(`/registration/v${useOldFlow ? 2 : 3}`, {
    ...model,
    ga_cookie_value: GAToken,
    ga_cookie_session_value: GA_SESSION_ID,
  })
}

export const doRefreshToken = async (refreshToken, accessToken) => {
  return await apiPOST('/auth/refresh-token', {
    refresh_token: refreshToken,
    auth_token: accessToken,
  })
}

export const getCurrentUser = async () => {
  return await apiGET('/user')
}

// export const getUserActiveStatus = async () => {
//   try {
//     await coreApi.get('/session')
//   } catch (err) {
//     console.log(err)
//   }
// }

export const countryCheckTranslations = async () => {
  return await apiGET('/country/check')
}

export const doBirthdayCheck = async (cpf, dateOfBirth) => {
  return await apiPOST('/dob-check', { cpf, dateOfBirth }, { hasValid: true })
}

export const doResendEmail = async (playerEmail) => {
  return await apiPOST(`/account-verification/${playerEmail}/send-email`, {
    emailRedirectionLink: LOGIN_2FA_EMAIL_LINK,
  })
}

export const getIs2faValidated = async (playerEmail) => {
  return await apiGET(`/account-verification/${playerEmail}/is-token-validated`)
}

export const doValidate2fa = async (token) => {
  return await apiPOST(`/account-verification/${token}/validate-token`)
}

export const getOnboardingState = async (token) => {
  return await apiGET(`/liveness/${token}`)
}

export const getOnboardingIdAndUrl = async (token) => {
  return await apiGET(`auth/liveness/${token}`)
}

export const doGenerateOnboarding = async (livenessType, playerId, reason) => {
  return await apiPOST('/liveness', {
    livenessType,
    playerId,
    reason,
  })
}
