import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const StyledSectionTitle = styled.h2`
  color: ${themeStyles.colors.black.main};
  font-weight: ${brandConfig.fonts.text.h2.weight};
  font-size: ${brandConfig.fonts.text.h2.size.mobile};
  font-family: ${brandConfig.fonts.text.h2.family};
  line-height: 24px;
  word-wrap: break-word;
  margin: 4px 0;
`
