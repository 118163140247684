export const triggerFormFields = async (trigger, values) => {
  for (const value of values) {
    const triggeredValue = await trigger(value)
    if (!triggeredValue) return false
  }
  return true
}

export const formSubmitIsDisabled = (keys, getValues, errors) => {
  const values = getValues(keys)

  for (let i = 0; i < keys.length; i++) {
    if (!values[i] || values[i]?.length == 0) return true
    if (errors[keys[i]] != null) return true
  }

  return false
}
