const locales = {
  en: {
    name: 'English',
    code: 'en',
  },
  pt: {
    name: 'Portuguese (Brazil)',
    code: 'pt-br',
  },
  pe: {
    name: 'Spanish (Peru)',
    code: 'es-pe',
  },
  ca: {
    name: 'English (Canada)',
    code: 'en-ca',
  },
  cl: {
    name: 'Spanish (Chile)',
    code: 'es-cl',
  },
}

const allLanguages = process.env.GATSBY_LANGUAGES

const filteredLocales = {}

allLanguages?.split(',')?.forEach((language) => {
  filteredLocales[language] = locales[language]
})

module.exports = filteredLocales
