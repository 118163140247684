import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { StyledField, StyledWrapper, StyledImg } from './styles'
import DarkCpfIcon from '../../../images/icon-cpf-black.svg'
import LightCpfIcon from '../../../images/icon-cpf-grey.svg'
import { Button } from '../../atoms/button'
import { Field } from '../field'
import { If } from '../../atoms/if'

import { getNationalIdFieldType } from '../../../utils/nationalID'
import { useRegisterData } from '../../../hooks/useRegisterData'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { useTranslation } from '../../../context/translationProvider'
import { RightArrow } from '../../atoms/RightArrow'

const FieldCpfSubmit = (props) => {
  const {
    customCTAButton,
    onFocus,
    onBlur,
    onSubmit,
    buttonBackgroundColor,
    buttonTextColor,
    onClick,
  } = props
  const [isFocused, setIsFocused] = React.useState(false)
  const { register, getValues } = useForm()
  const [country, setCountry] = useState()
  const { translate } = useTranslation()
  const { isMobile } = useDeviceDetect()
  const [isKeyboardUp, setIsKeyboardUp] = useState(false)
  const key = 'national_id'

  useEffect(() => {
    const handleMobileResize = (event) => {
      // Check if keyboard is up
      if (event.target.height + 30 < document.documentElement.clientHeight) {
        setIsKeyboardUp(true)
      } else {
        const value = getValues('national_id')
        // if keyboard was previously up & cpf matches length needed => submit
        if (isKeyboardUp && value.length == country?.national_id_mask.length) {
          onSubmit(value)
        }
        setIsKeyboardUp(false)
      }
    }
    // Add event listener for mobile keyboard resize
    if (isMobile && 'visualViewport' in window) {
      window.visualViewport.addEventListener('resize', handleMobileResize)
    }
    // Remove event listener on unmount
    return () => {
      if (isMobile && 'visualViewport' in window) {
        window.visualViewport.removeEventListener('resize', handleMobileResize)
      }
    }
  }, [country, isMobile, isKeyboardUp])

  useRegisterData({
    country,
    setCountry,
  })

  const handleFocus = ({ event, eventType }) => {
    const isFocused = eventType === 'focus'
    setIsFocused(isFocused)
    isFocused ? onFocus(event) : onBlur(event)

    if (isFocused) {
      window?.dataLayer?.push({
        event: 'click_on_cpf_field',
      })
    }
  }

  const handleSubmit = () => {
    const value = getValues('national_id')
    window?.dataLayer?.push({
      event: 'click_on_cpf_field_cta',
    })
    onSubmit(value)

    if (onClick) onClick()
  }

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  return (
    <StyledWrapper className={`${isFocused ? 'active' : ''}`}>
      <StyledImg src={isFocused ? DarkCpfIcon : LightCpfIcon} />
      <StyledField>
        <Field
          doNotValidate
          formProperties={register(key, { required: true })}
          hideSymbols
          key={key}
          mask={country?.national_id_mask}
          noBottomMargin
          onBlur={(event) => handleFocus({ event, eventType: 'blur' })}
          onFocus={(event) => handleFocus({ event, eventType: 'focus' })}
          onKeyDown={(event) => handleKeyPressed(event)}
          placeholder={translate('banner.nationalIdPlaceholder')}
          type={getNationalIdFieldType(country?.national_id_mask)}
          inputmode="numeric"
        />
      </StyledField>
      <If
        condition={customCTAButton}
        render={() => (
          <Button
            key={'cpf-register-button'}
            style={{
              backgroundColor: buttonBackgroundColor,
              color: buttonTextColor,
            }}
            onClick={handleSubmit}
          >
            {customCTAButton.action_text}
          </Button>
        )}
        renderElse={() => (
          <Button
            style={{ background: buttonBackgroundColor }}
            onClick={handleSubmit}
          >
            <RightArrow color={buttonTextColor} />
          </Button>
        )}
      />
    </StyledWrapper>
  )
}

FieldCpfSubmit.defaultProps = {
  customButton: null,
  customCTAButton: null,
  onBlur: () => {},
  onFocus: () => {},
  onSubmit: () => {},
  buttonBackgroundColor: '#000',
  buttonTextColor: '#fff',
}

FieldCpfSubmit.propTypes = {
  customCTAButton: PropTypes.element,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
}

export { FieldCpfSubmit }
