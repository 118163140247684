import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const Content = styled.div`
  padding: 2rem 1rem;

  ${({ color }) =>
    color ?
      css`
          color: ${color};
        ` :
      null};

  form {
    margin-bottom: 6rem;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 1.5rem;
`

export const BlockContent = styled.div`
  margin-bottom: 2rem;
  p {
    margin: 0.5rem;
  }
  a {
    color: ${themeStyles.colors.black.main};
  }
`
