import React from 'react'
import { StyledRecaptchaMessage } from './styles'
import useFeatures from '../../../hooks/useFeatures'
import { useTranslation } from '../../../context/translationProvider'

const RecaptchaMessage = () => {
  const isRecaptchaEnabled = useFeatures('recaptcha')
  const { translate } = useTranslation()
  if (!isRecaptchaEnabled) return <></>

  return (
    <StyledRecaptchaMessage>
      <small
        dangerouslySetInnerHTML={{
          __html: translate('login.reCaptcha'),
        }}
      />
    </StyledRecaptchaMessage>
  )
}

export { RecaptchaMessage }
