import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledCol,
  StyledRow,
  StyledSecondSection,
  StyledSectionHeader,
} from '../../styles'

import { doBirthdayCheck } from '../../../../../adapters/auth'
import { FieldDropdown } from '../../../../molecules/fieldDropdown'
import { Field } from '../../../../molecules/field'
import { FieldNationalId } from '../../../../molecules/fieldNationalId'
import { Button } from '../../../../atoms/button'
import { FieldDescription } from '../../../../atoms/fieldDescription'
import { FieldLabel } from '../../../../atoms/fieldLabel'
import { If } from '../../../../atoms/if'
import { validateDate, validateName } from '../../../../../helpers/validators'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import { useTranslation } from '../../../../../context/translationProvider'
import useFeatures from '../../../../../hooks/useFeatures'
import { getDateStr } from '../../../../../utils/datetime'
import { brandConfig } from '../../../../../config/brandConfig'

const SecondStep = (props) => {
  const {
    formProperties,
    checkNationalId,
    onCompleteStep,
    goBack,
    cafData,
    country,
    dates,
    registerTime,
    onInputFocus,
    onInputBlur,
  } = props

  const {
    register,
    formState,
    setFocus,
    getValues,
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
  } = formProperties
  const { errors } = formState
  const [isCheckingDOB, setIsCheckingDOB] = useState(false)
  const [dateError, setDateError] = useState('')
  const [stepTimer] = useState(new Date())
  const { translate } = useTranslation()
  const canCheckDobWithCPF = useFeatures('checkDobWithCPF')

  useEffect(() => {
    watch(['name', 'dob'])
  }, [])

  const onReenter = () => {
    setValue('national_id', '')
    goBack()
    setTimeout(() => setFocus('national_id'), 100)
  }

  const createReenterButton = () => (
    <Button type="button" expand bordered dark onClick={onReenter}>
      {translate('register.reentercpf')}
    </Button>
  )

  const createDropdown = (key, width) => {
    return (
      <StyledCol width={width}>
        <FieldDropdown
          key={key}
          autoCompleteOff
          errors={errors}
          fieldValue={getValues(key)}
          formProperties={register(key, { required: true })}
          defaultValue={null}
          disableFirstOption
          noBottomMargin
          emptyValue={translate(`register.dobHint.${key}`, '--')}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('dob_input_timer')}
          // Wrap with setTimeout to wait for the latest data
          onChange={() => setTimeout(() => checkDate())}
          values={dates[key + 's']?.map((row, i) => (
            <option key={`dropdown-${key}-${i}`} value={row.value}>
              {row.label}
            </option>
          ))}
        />
      </StyledCol>
    )
  }

  const createDisabledDropdown = (value, width) => {
    return (
      <StyledCol width={width}>
        <FieldDropdown disabled emptyValue={value} defaultValue={null} />
      </StyledCol>
    )
  }

  const checkDate = () => {
    const [year, month, day] = getValues(['year', 'month', 'day'])
    if (!year || !month || !day) return
    const currentDob = new Date(Date.UTC(year, month - 1, day))

    // 30.2 doesnt exist, so it overflows into 1.3, this is wrong
    const isDateOverflowing =
      currentDob.getUTCFullYear() != year ||
      currentDob.getUTCMonth() != month - 1 ||
      currentDob.getUTCDate() != day

    let errorMessage = ''
    if (isDateOverflowing) {
      errorMessage = translate('register.error.invalidDate')
    } else if (!validateDate(currentDob)) {
      errorMessage = translate('register.error.ageCheck')
    }

    if (errorMessage) {
      setDateError(errorMessage)
      setError('year', '')
      setError('month', '')
      setError('day', '')
      setValue('dob', '')
      return false
    }

    setDateError()
    clearErrors(['year', 'month', 'day', 'dob'])
    setValue('dob', currentDob.toISOString())
    trigger('dob')
    return true
  }

  const handleCompleteStep = (fields) => {
    const actualTime = new Date()

    registerTime(
      'second_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
    onCompleteStep(fields)
  }

  const onConfirmDetails = async () => {
    if (canCheckDobWithCPF) {
      setIsCheckingDOB(true)
      const [year, month, day] = getValues(['year', 'month', 'day'])
      const { ok } = await doBirthdayCheck(
        cafData.cpf,
        getDateStr({ year, month, day })
      )

      setIsCheckingDOB(false)
      if (!ok) {
        setDateError(translate('register.error.invalidDate'))
        setError('year', '')
        setError('month', '')
        setError('day', '')
        return
      }
      setDateError()
      clearErrors(['year', 'month', 'day', 'dob'])
    }

    handleCompleteStep([])
  }

  if (!cafData) {
    return (
      <StyledSecondSection>
        <StyledSectionHeader>
          {translate('userProfile.personalDetails')}
        </StyledSectionHeader>
        <FieldNationalId
          // If we checked national ID and there is no CAF, then we show msg
          description={checkNationalId ? translate('error.cpfIsDown') : ''}
          disabled={checkNationalId}
          country={country}
          formProperties={formProperties}
          hideSymbols
          descriptionTheme="warn"
          showFieldOnlyOn={['BR']}
        />

        <Field
          label={translate('register.fullName')}
          maxLength="50"
          errors={errors}
          immediatellyValidate={!!getValues('name')}
          showErrorMsg
          formProperties={register('name', {
            required: true,
            validate: (value) =>
              validateName(value) || translate('register.error.fullName'),
          })}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('fullName_input_timer')}
        />
        <FieldLabel>{translate('userProfile.dob')}</FieldLabel>
        <StyledRow>
          {createDropdown('day')}
          {createDropdown('month')}
          {createDropdown('year', '80%')}
        </StyledRow>

        <FieldDescription error={dateError} />
        <Button
          id="nextBtn2"
          expand
          type="button"
          disabled={formSubmitIsDisabled(['name', 'dob'], getValues, errors)}
          onClick={() => handleCompleteStep(['name', 'dob'])}
        >
          {translate('register.next')}
        </Button>
        <If condition={checkNationalId} render={createReenterButton} />
      </StyledSecondSection>
    )
  }

  return (
    <StyledSecondSection>
      <StyledSectionHeader>
        {translate('register.confirmDetails')}
      </StyledSectionHeader>
      <Field
        disabled
        label={
          country?.national_id_label ||
          translate('register.default_national_id_label')
        }
        defaultValue={cafData.cpf}
      />
      <Field
        disabled
        label={translate('userProfile.name')}
        defaultValue={cafData.name}
      />
      <FieldLabel>{translate('userProfile.dob')}</FieldLabel>
      <StyledRow>
        <If
          condition={canCheckDobWithCPF}
          render={() => (
            <>
              {createDropdown('day')}
              {createDropdown('month')}
              {createDropdown('year', '80%')}
            </>
          )}
          renderElse={() => (
            <>
              {createDisabledDropdown('••')}
              {createDisabledDropdown('••')}
              {createDisabledDropdown(cafData.yearOfBirth, '80%')}
            </>
          )}
        />
      </StyledRow>

      <FieldDescription error={dateError} />
      <Button
        id="nextBtn2"
        type="button"
        expand
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          isCheckingDOB ||
          (canCheckDobWithCPF &&
            formSubmitIsDisabled(['dob'], getValues, errors))
        }
        onClick={onConfirmDetails}
      >
        {translate('register.confirm')}
      </Button>

      {createReenterButton()}
    </StyledSecondSection>
  )
}

SecondStep.propTypes = {
  country: PropTypes.object,
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    trigger: PropTypes.func,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    setFocus: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  checkNationalId: PropTypes.bool,
  onCompleteStep: PropTypes.func,
  goBack: PropTypes.func,
  cafData: PropTypes.bool,
  dates: PropTypes.shape({
    days: PropTypes.array,
    months: PropTypes.array,
    years: PropTypes.array,
  }),
  registerTime: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
}

export { SecondStep }
