import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 15px;
  padding: 17px 15px;

  background: ${themeStyles.colors.info.lightInfo.main};
`
