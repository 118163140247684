import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import icon_arrow_down from '../../../images/icon_arrow_down.svg'
import { InputCSS, inputBorderSize } from '../../atoms/fieldInputArea/styles'

export const StyledInnerWrapper = styled.div`
  position: relative;
`

export const StyledInput = styled.input`
  ${InputCSS}
  appearance: none;
  ${(props) =>
    props.showDropdownIcon &&
    css`
      background: ${themeStyles.colors.lightGreys[1].main}
        url(${icon_arrow_down}) no-repeat right 0.5rem center;
      background-clip: padding-box;
    `}
`

const optionsHeight = '213px'

const baseDropdownCss = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1;
  max-height: ${optionsHeight};

  width: calc(100% - ${inputBorderSize} * 2);
  left: ${inputBorderSize};
  top: 100%;

  border: 0.1px solid ${themeStyles.colors.black.main}50;
  border-top: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 2px 2px 5px 0px ${themeStyles.colors.black.main}1a;
  background-color: ${themeStyles.colors.white.main};
`

export const StyledLoadingWrapper = styled.div`
  ${baseDropdownCss}
  align-items: center;
  justify-content: center;
  height: ${optionsHeight};
`

export const StyledDropdownOptionsWrapper = styled.div`
  ${baseDropdownCss}
`

export const StyledSuggestionsWrapper = styled.div`
  > * {
    display: block;
    background-color: transparent;
    color: ${themeStyles.colors.black.main};
    width: 100%;
    text-align: left;

    cursor: pointer;
    padding: 1em;
    border-top: 0.1px solid ${themeStyles.colors.black.main}50;
    transition: 0.2s;

    .highlighted {
      font-weight: 600;
    }

    &:hover {
      background-color: ${themeStyles.colors.lightGreys[1].main};
    }
  }
`

export const StyledSuggestionHint = styled.div`
  padding: 0.4em 1em;
  color: ${themeStyles.colors.darkGreys[2].main};

  * {
    margin: 0;
  }
`
