import React from 'react'

import allLocales from '../../../i18n/locales'
import { cookiePrefix, setCookie } from '../../../utils/cookies'
import { Container } from '../container'
import { StyledLang, StyledLanguageHref, StyledLanguageWrapper } from './styles'

const currentLocale = process.env.GATSBY_INTL_LOCALE

const Language = () => {
  const onLanguageChange = (language) => {
    setCookie(`${cookiePrefix}market`, language, 365)
    setCookie('nolangredirect', 'true', 365)
  }

  const languages = process.env.GATSBY_LANGUAGES.split(',')

  return (
    <Container>
      <StyledLanguageWrapper id="languageSwitcher">
        {languages.map((language, i) => (
          <StyledLang
            key={i}
            className={currentLocale === language ? 'active' : ''}
          >
            <StyledLanguageHref
              key={language}
              code={language}
              aria-label={allLocales?.[language]?.name}
              onClick={() => onLanguageChange(language)}
              href={`/${language}/`}
            />
          </StyledLang>
        ))}
      </StyledLanguageWrapper>
    </Container>
  )
}

export { Language }
