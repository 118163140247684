import React from 'react'
import PropTypes from 'prop-types'

import { StyledContainer } from './styles'
import { Image } from '../../atoms/image'
import { Text } from '../../atoms/text'

export const Alert = ({ icon, description }) => {
  return (
    <StyledContainer>
      <Image url={icon} width={38} />
      <Text noMargin>{description}</Text>
    </StyledContainer>
  )
}

Alert.defaultProps = {
  icon: '',
  decription: 'Add your description',
}

Alert.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
}
