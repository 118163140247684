import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FieldLabel } from '../../atoms/fieldLabel'
import { FieldDescription } from '../../atoms/fieldDescription'
import { StyledScrollTo, StyledWrapper } from './styles'
import { useTranslation } from '../../../context/translationProvider'
import { If } from '../../atoms/if'

const FieldWrapper = (props) => {
  const { translate } = useTranslation()
  const {
    label = translate('common.password'),
    description,
    descriptionTheme,
    showErrorMsg,
    scrollOnFocus,
    reduceBottomMargin,
    errors,
    formProperties,
    hasBeenSubmitted,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    renderError,
    renderInputField,
    minLength,
    fieldValue,
    noBottomMargin,
    labelButton,
    immediatellyValidate,
    doNotValidate,
  } = props

  const [isInFocus, setIsInFocus] = useState(false)
  const [shouldValidate, setShouldValidate] = useState(immediatellyValidate)
  const [data, setData] = useState('')
  // Show gray if:  Has no value (and no error)
  //                || Has value and had error for first time
  // Show green if: Has value, and is successful
  // Show red if:   Has value, had error after successful once
  //                || Has value and error and is submitted
  useEffect(() => {
    if (!errors[formProperties.name] && data.length > 2 && !shouldValidate)
      setShouldValidate(true)
  }, [errors[formProperties.name], data.length])

  const valid = useMemo(() => {
    const fieldState = formProperties?.getFieldState
      ? formProperties.getFieldState(
          formProperties.name,
          formProperties.formState
        )
      : null

    const shortValidation =
      !doNotValidate && data && shouldValidate && !errors[formProperties.name]
    // if formState && fieldState is not present return shortValidation
    return formProperties?.formState && fieldState
      ? shortValidation &&
          fieldState?.isDirty &&
          !fieldState.invalid &&
          fieldState.isTouched
      : //// Old return for backward compatibility
        shortValidation
  }, [
    doNotValidate,
    errors[formProperties.name],
    shouldValidate,
    data,
    formProperties,
  ])

  const invalid = useMemo(() => {
    return (
      !doNotValidate &&
      (hasBeenSubmitted || shouldValidate) &&
      !!errors[formProperties.name]
    )
  }, [doNotValidate, errors[formProperties.name], shouldValidate, data])

  const getError = () => {
    if (!showErrorMsg || !invalid) return
    if (renderError) return renderError()
    return errors[formProperties.name]?.message ?? description
  }
  const errorMessage = getError()

  const onChangeCombined = (e) => {
    onChange(e)
    setData(e.target.value)
  }

  const onKeyDownCombined = (e) => {
    if (onKeyDown) onKeyDown(e)
  }

  const onFocusCombined = (e) => {
    onFocus(e)
    setIsInFocus(true)
    setTimeout(() => scrollIntoView(), 100)
  }

  const scrollIntoView = () => {
    if (!scrollOnFocus) return
    document.body.classList.add('add-keyboard-padding-to-containers')
    window.scrollTo(0, 0) // ? this fixes the wrong iOS auto scrolling

    const scrollToClass = `${formProperties.name}-scroll-to`
    const scrollToElement = document.getElementsByClassName(scrollToClass)[0]
    if (scrollToElement) {
      setTimeout(
        () =>
          scrollToElement.scrollIntoView({
            block: 'start',
            inline: 'nearest',
          }),
        60
      )
    }
  }

  const onBlurCombined = (e) => {
    onBlur(e)
    // ? We want to allow enough time for the users click on a child item to happen
    setTimeout(() => setIsInFocus(false), 200)
    if (e.target.value && !shouldValidate) setShouldValidate(true)
    if (scrollOnFocus) {
      document.body.classList.remove('add-keyboard-padding-to-containers')
    }
    // ? Validate on blur only if there is no hasBeenSubmitted
    if (hasBeenSubmitted == null && data.length > 0) {
      setShouldValidate(true)
    }
  }

  if (!formProperties) return <></>

  useEffect(() => {
    if (fieldValue) setData(fieldValue || '')
  }, [formProperties])

  return (
    <StyledWrapper
      reduceBottomMargin={reduceBottomMargin}
      noBottomMargin={noBottomMargin || errorMessage}
    >
      <StyledScrollTo className={`${formProperties.name}-scroll-to`} />
      <If
        condition={label || labelButton}
        render={() => (
          <FieldLabel name={formProperties.name}>
            {label} {labelButton}
          </FieldLabel>
        )}
      />

      {renderInputField({
        onChangeCombined,
        onBlurCombined,
        onFocusCombined,
        onKeyDownCombined,
        isInFocus,
        valid,
        invalid,
        data,
      })}
      {minLength ? (
        data?.length > 0 &&
        data?.length < minLength && (
          <FieldDescription error={description} theme={descriptionTheme}>
            {description}
          </FieldDescription>
        )
      ) : (
        <FieldDescription error={errorMessage} theme={descriptionTheme}>
          {description}
        </FieldDescription>
      )}
    </StyledWrapper>
  )
}

FieldWrapper.defaultProps = {
  label: '',
  description: null,
  doNotValidate: false,
  showErrorMsg: false,
  hasBeenSubmitted: null,
  reduceBottomMargin: false,
  noBottomMargin: false,
  scrollOnFocus: false,
  immediatellyValidate: false,
  errors: {},
  formProperties: {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  renderError: null,
  renderInputField: () => <></>,
  minLength: null,
  descriptionTheme: null,
}

FieldWrapper.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  doNotValidate: PropTypes.bool,
  fieldValue: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  hasBeenSubmitted: PropTypes.bool,
  reduceBottomMargin: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  scrollOnFocus: PropTypes.bool,
  immediatellyValidate: PropTypes.bool,
  errors: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  renderError: PropTypes.func,
  renderInputField: PropTypes.func,
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  descriptionTheme: PropTypes.string,
}

export { FieldWrapper }
