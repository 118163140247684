import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { ModalHeader } from '../modalHeader'
import { If } from '../if'
import {
  StyledModalBody,
  StyledModalOverlay,
  StyledModalWrapper,
  AccountModalContent,
} from './styles'
import { isBrowser } from '../../../utils/generic'
import useResizeListener from '../../../hooks/useResizeListener'

const Modal = (props) => {
  const {
    fade,
    hideBackButton,
    hideClose,
    hideOverlay,
    isMobile,
    isTransparent,
    leftSideClose,
    onClose,
    theme,
    title,
  } = props
  const [mobileBodyHeight, setMobileBodyHeight] = useState()

  useEffect(() => {
    if (isBrowser()) {
      document.body.classList.add('overflow-hidden')
    }

    return () => document.body.classList.remove('overflow-hidden')
  }, [])

  useEffect(() => {
    if (!isBrowser()) return

    const resizeListener = () => {
      setMobileBodyHeight(window?.innerHeight)
    }

    window.addEventListener('resize', resizeListener)
    resizeListener()

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])
  useResizeListener(() => setMobileBodyHeight(window.innerHeight))

  useEffect(() => {
    if (!props.isOpen) {
      document.body.classList.remove('overflow-hidden')
    } else {
      document.body.classList.add('overflow-hidden')
    }
  }, [props.isOpen])

  if (!props.isOpen) return <></>

  return createPortal(
    props.isOpen ? (
      <StyledModalWrapper {...props} className={fade ? 'modal-fade' : ''}>
        <StyledModalOverlay />
        <StyledModalBody
          minHeight={props?.bodyMinHeight || null}
          mobileHeight={mobileBodyHeight}
          paymentIQMinHeight={props?.paymentIQMinHeight}
          {...props}
        >
          <If
            condition={theme !== 'search' && theme !== 'account'}
            render={() => (
              <ModalHeader
                theme={theme}
                closeModal={onClose}
                leftSideClose={leftSideClose}
                isPopup
                isTransparent={isTransparent}
                hideBack={hideClose || hideBackButton}
                hideClose={
                  !((!hideClose && !hideOverlay) || (hideOverlay && isMobile))
                }
                title={title}
              />
            )}
          />
          {props.children}
        </StyledModalBody>
      </StyledModalWrapper>
    ) : null,
    typeof document !== 'undefined' ? document.body : null
  )
}

Modal.defaultProps = {
  theme: 'default',
  title: '',
  id: '',
  fade: false,
  isOpen: false,
  isTransparent: false,
  hideClose: false,
  hideBackButton: false,
  leftSideClose: false,
  noPadding: false,
  isMobile: false,
  hideOverlay: false,
  onClose: null,
  bodyMinHeight: null,
  paymentIQMinHeight: null,
  fullScreen: false,
}

Modal.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf([
    'search',
    'account',
    'game-screenshot',
    'payment',
    'simple',
    'default',
  ]),
  title: PropTypes.string,
  id: PropTypes.string,
  fade: PropTypes.bool,
  hideClose: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  leftSideClose: PropTypes.bool,
  noPadding: PropTypes.bool,
  isMobile: PropTypes.bool,
  hideOverlay: PropTypes.bool,
  isOpen: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onClose: PropTypes.func,
  bodyMinHeight: PropTypes.bool,
  paymentIQMinHeight: PropTypes.number,
  fullScreen: PropTypes.bool,
}

export { Modal, AccountModalContent }
