import styled from 'styled-components'

export const StyledSectionSubtitle = styled.h3`
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.25px;
  word-wrap: break-word;
  margin-top: 0;
`
